import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryTimelinesOnStoryEditorQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  storyId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filter?: Types.InputMaybe<Types.StoryTimelineFilter>;
}>;


export type StoryTimelinesOnStoryEditorQuery = { __typename?: 'RootQueryType', storyTimelines?: { __typename?: 'StoryTimelineConnection', totalCount: number, edges?: Array<{ __typename?: 'StoryTimelineEdge', cursor?: string | null, node?: { __typename?: 'StoryTimeline', id: string, email?: string | null, isRead?: boolean | null, isSpam?: boolean | null, isArchived?: boolean | null, archivedAt?: any | null, markedAsSpamAt?: any | null, lastEventAt?: any | null, insertedAt?: any | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const StoryTimelinesOnStoryEditorDocument = gql`
    query storyTimelinesOnStoryEditor($first: Int, $after: String, $before: String, $storyId: ID, $filter: StoryTimelineFilter) {
  storyTimelines(
    first: $first
    after: $after
    before: $before
    storyId: $storyId
    filter: $filter
  ) {
    edges {
      node {
        id
        email
        isRead
        isSpam
        isArchived
        archivedAt
        markedAsSpamAt
        lastEventAt
        insertedAt
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
  }
}
    `;

/**
 * __useStoryTimelinesOnStoryEditorQuery__
 *
 * To run a query within a React component, call `useStoryTimelinesOnStoryEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryTimelinesOnStoryEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryTimelinesOnStoryEditorQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      storyId: // value for 'storyId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStoryTimelinesOnStoryEditorQuery(baseOptions?: Apollo.QueryHookOptions<StoryTimelinesOnStoryEditorQuery, StoryTimelinesOnStoryEditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryTimelinesOnStoryEditorQuery, StoryTimelinesOnStoryEditorQueryVariables>(StoryTimelinesOnStoryEditorDocument, options);
      }
export function useStoryTimelinesOnStoryEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryTimelinesOnStoryEditorQuery, StoryTimelinesOnStoryEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryTimelinesOnStoryEditorQuery, StoryTimelinesOnStoryEditorQueryVariables>(StoryTimelinesOnStoryEditorDocument, options);
        }
export type StoryTimelinesOnStoryEditorQueryHookResult = ReturnType<typeof useStoryTimelinesOnStoryEditorQuery>;
export type StoryTimelinesOnStoryEditorLazyQueryHookResult = ReturnType<typeof useStoryTimelinesOnStoryEditorLazyQuery>;
export type StoryTimelinesOnStoryEditorQueryResult = Apollo.QueryResult<StoryTimelinesOnStoryEditorQuery, StoryTimelinesOnStoryEditorQueryVariables>;
export function refetchStoryTimelinesOnStoryEditorQuery(variables?: StoryTimelinesOnStoryEditorQueryVariables) {
      return { query: StoryTimelinesOnStoryEditorDocument, variables: variables }
    }