import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LookupMeWithEmailMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type LookupMeWithEmailMutation = { __typename?: 'RootMutationType', lookupMeWithEmail?: { __typename?: 'LookupUserResult', emailVerified?: boolean | null } | null };


export const LookupMeWithEmailDocument = gql`
    mutation lookupMeWithEmail($email: String!) {
  lookupMeWithEmail(email: $email) {
    emailVerified
  }
}
    `;
export type LookupMeWithEmailMutationFn = Apollo.MutationFunction<LookupMeWithEmailMutation, LookupMeWithEmailMutationVariables>;

/**
 * __useLookupMeWithEmailMutation__
 *
 * To run a mutation, you first call `useLookupMeWithEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLookupMeWithEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lookupMeWithEmailMutation, { data, loading, error }] = useLookupMeWithEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLookupMeWithEmailMutation(baseOptions?: Apollo.MutationHookOptions<LookupMeWithEmailMutation, LookupMeWithEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LookupMeWithEmailMutation, LookupMeWithEmailMutationVariables>(LookupMeWithEmailDocument, options);
      }
export type LookupMeWithEmailMutationHookResult = ReturnType<typeof useLookupMeWithEmailMutation>;
export type LookupMeWithEmailMutationResult = Apollo.MutationResult<LookupMeWithEmailMutation>;
export type LookupMeWithEmailMutationOptions = Apollo.BaseMutationOptions<LookupMeWithEmailMutation, LookupMeWithEmailMutationVariables>;