import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { HotspotBaseFragmentDoc } from './hotspot-base-fragment.generated';
import { DestinationOnHotspotFragmentDoc } from './hotspot-destination-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHotspotMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateHotspotInput;
}>;


export type UpdateHotspotMutation = { __typename?: 'RootMutationType', updateHotspot?: { __typename?: 'HotspotMutationResult', hotspot?: { __typename?: 'Hotspot', id: string, x: number, y: number, text: string, textColor: string, bgColor: string, alwaysShowText: boolean, destinationType?: Types.NextInteractionType | null, value?: string | null, middleInteraction: { __typename?: 'MiddleInteraction', id: string, type: Types.MiddleInteractionType, question?: string | null, embed?: { __typename?: 'Embed', id: string, linkUrl: string, oembedResult?: any | null, status: Types.EmbedStatus, reason?: Types.EmbedFailureReason | null } | null }, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateHotspotDocument = gql`
    mutation updateHotspot($id: ID!, $input: UpdateHotspotInput!) {
  updateHotspot(id: $id, input: $input) {
    hotspot {
      ...HotspotBase
      ...DestinationOnHotspot
    }
    userErrors {
      field
      message
    }
  }
}
    ${HotspotBaseFragmentDoc}
${DestinationOnHotspotFragmentDoc}`;
export type UpdateHotspotMutationFn = Apollo.MutationFunction<UpdateHotspotMutation, UpdateHotspotMutationVariables>;

/**
 * __useUpdateHotspotMutation__
 *
 * To run a mutation, you first call `useUpdateHotspotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHotspotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHotspotMutation, { data, loading, error }] = useUpdateHotspotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHotspotMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHotspotMutation, UpdateHotspotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHotspotMutation, UpdateHotspotMutationVariables>(UpdateHotspotDocument, options);
      }
export type UpdateHotspotMutationHookResult = ReturnType<typeof useUpdateHotspotMutation>;
export type UpdateHotspotMutationResult = Apollo.MutationResult<UpdateHotspotMutation>;
export type UpdateHotspotMutationOptions = Apollo.BaseMutationOptions<UpdateHotspotMutation, UpdateHotspotMutationVariables>;