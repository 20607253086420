import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFileUploadMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteFileUploadMutation = { __typename?: 'RootMutationType', deleteFileUpload?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const DeleteFileUploadDocument = gql`
    mutation deleteFileUpload($id: ID!) {
  deleteFileUpload(id: $id) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteFileUploadMutationFn = Apollo.MutationFunction<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>;

/**
 * __useDeleteFileUploadMutation__
 *
 * To run a mutation, you first call `useDeleteFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileUploadMutation, { data, loading, error }] = useDeleteFileUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>(DeleteFileUploadDocument, options);
      }
export type DeleteFileUploadMutationHookResult = ReturnType<typeof useDeleteFileUploadMutation>;
export type DeleteFileUploadMutationResult = Apollo.MutationResult<DeleteFileUploadMutation>;
export type DeleteFileUploadMutationOptions = Apollo.BaseMutationOptions<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>;