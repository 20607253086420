import { CalendarIcon } from 'lucide-react';
import {
  DateRangePicker as AriaDateRangePicker,
  DateRangePickerProps as AriaDateRangePickerProps,
  DateValue,
  ValidationResult,
} from 'react-aria-components';

import { composeTailwindRenderProps } from '../../utils';
import { Button } from '../button';
import { DateInput } from '../date-field';
import { Dialog } from '../dialog';
import { Description, FieldError, FieldGroup, Label } from '../field';
import { AriaBasedPopover } from '../popover';
import { RangeCalendar } from '../range-calendar';

export interface DateRangePickerProps<T extends DateValue>
  extends AriaDateRangePickerProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

export function DateRangePicker<T extends DateValue>({
  label,
  description,
  errorMessage,
  ...props
}: DateRangePickerProps<T>) {
  return (
    <AriaDateRangePicker
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'group flex flex-col gap-1',
      )}
    >
      {label && <Label>{label}</Label>}
      <FieldGroup className="min-w-[208px] w-auto">
        <DateInput slot="start" className="px-3 py-2 text-xs text-gray-600" />
        <span
          aria-hidden="true"
          className="text-gray-600 dark:text-zinc-200 forced-colors:text-[ButtonText] group-disabled:text-gray-200 group-disabled:dark:text-zinc-600 group-disabled:forced-colors:text-[GrayText]"
        >
          –
        </span>
        <DateInput
          slot="end"
          className="flex-1 px-2 py-1.5 text-xs text-gray-600"
        />
        <Button variant="icon" className="w-6 mr-1 rounded outline-offset-0">
          <CalendarIcon aria-hidden className="w-4 h-4" />
        </Button>
      </FieldGroup>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
      <AriaBasedPopover>
        <Dialog>
          <RangeCalendar />
        </Dialog>
      </AriaBasedPopover>
    </AriaDateRangePicker>
  );
}
