import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';

interface Props {
  message: string;
  size?: 'sm' | 'md' | 'lg';
  showCloseButton?: boolean;
  handleClose?: () => void;
}

export const ErrorAlert = ({
  message,
  size = 'md',
  showCloseButton = false,
  handleClose,
}: Props) => {
  const wrapClass = classnames('rounded-lg bg-red-50', {
    'px-3 py-2': size === 'md',
    'px-2 py-1': size === 'sm',
    'px-4 py-3': size === 'lg',
  });

  const iconClass = classnames('text-red-400', {
    'h-5 w-5': size === 'md',
    'h-4 w-4': size === 'sm',
    'h-6 w-6': size === 'lg',
  });
  const handleDismiss = () => {
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <div className={wrapClass}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className={iconClass} aria-hidden="true" />
        </div>
        <div className="ml-2">
          <p className="text-sm font-medium text-red-800">{message}</p>
        </div>
        <div className="ml-auto pl-3">
          {showCloseButton && (
            <div className="-mx-1.5 -my-1.5">
              <button
                onClick={handleDismiss}
                type="button"
                className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className={iconClass} aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
