import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { ContactFormBaseFragmentFragmentDoc } from './contact-form-base-fragment.generated';
import { BaseContactFormFieldsFragmentFragmentDoc } from './contact-form-fields-base-fragment.generated';
import { ContactFormConsentFragmentFragmentDoc } from './contact-form-consent-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateContactFormFieldsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateContactFormFieldsInput;
}>;


export type UpdateContactFormFieldsMutation = { __typename?: 'RootMutationType', updateContactFormFields: { __typename?: 'ContactFormMutationResult', contactForm?: { __typename?: 'ContactForm', id: string, isActive: boolean, canSkip: boolean, prompt: string, showConsent: boolean, formFields: Array<{ __typename?: 'AudioField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename?: 'EmailField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename?: 'LongTextField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename?: 'PhoneField', defaultCountryCode?: string | null, id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename?: 'ShortTextField', max?: number | null, min?: number | null, id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename?: 'VideoField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null }>, consents: Array<{ __typename: 'Consent', id: string, deletedAt?: any | null, currentVersion: { __typename: 'ConsentVersion', id: string, text?: string | null, isRequired: boolean } }> } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } };


export const UpdateContactFormFieldsDocument = gql`
    mutation updateContactFormFields($id: ID!, $input: UpdateContactFormFieldsInput!) {
  updateContactFormFields(id: $id, input: $input) {
    contactForm {
      ...contactFormBaseFragment
      formFields {
        ...baseContactFormFieldsFragment
      }
      consents {
        __typename
        ...contactFormConsentFragment
      }
    }
    userErrors {
      field
      message
    }
  }
}
    ${ContactFormBaseFragmentFragmentDoc}
${BaseContactFormFieldsFragmentFragmentDoc}
${ContactFormConsentFragmentFragmentDoc}`;
export type UpdateContactFormFieldsMutationFn = Apollo.MutationFunction<UpdateContactFormFieldsMutation, UpdateContactFormFieldsMutationVariables>;

/**
 * __useUpdateContactFormFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateContactFormFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactFormFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactFormFieldsMutation, { data, loading, error }] = useUpdateContactFormFieldsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactFormFieldsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactFormFieldsMutation, UpdateContactFormFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactFormFieldsMutation, UpdateContactFormFieldsMutationVariables>(UpdateContactFormFieldsDocument, options);
      }
export type UpdateContactFormFieldsMutationHookResult = ReturnType<typeof useUpdateContactFormFieldsMutation>;
export type UpdateContactFormFieldsMutationResult = Apollo.MutationResult<UpdateContactFormFieldsMutation>;
export type UpdateContactFormFieldsMutationOptions = Apollo.BaseMutationOptions<UpdateContactFormFieldsMutation, UpdateContactFormFieldsMutationVariables>;