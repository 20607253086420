import { Modifiers } from '@apollo/client/cache/core/types/common';
import { Options } from '@popperjs/core';
import { useOutsideClickHandlerForRefs } from '@sharelo-lib/ui';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

interface Props {
  btnClassName: string | ((isActive: boolean) => string);
  btnInner: React.ReactNode | string;
  children: React.ReactNode;
  popperOptions?: Options;
  openWithHover?: boolean;
  tooltipWithHover?: boolean;
  tooltip?: React.ReactNode;
  usePortal?: boolean;
}

export const PopoverContainer = ({
  children,
  btnClassName,
  btnInner,
  popperOptions,
  openWithHover = false,
  tooltipWithHover = false,
  tooltip,
  usePortal = true,
}: Props) => {
  const wrapperRef = useRef(null);
  const portalRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement>();
  const { styles, attributes, update } = usePopper<Modifiers>(
    referenceElement,
    popperElement,
    popperOptions,
  );

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsHovered(false);
    setTimeout(() => setIsOpen((state) => !state), 100);
  };

  const closePortal = () => {
    setIsOpen(false);
  };

  const onMouseEnter = () => {
    if (!isOpen) {
      setIsHovered(true);
    }
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  useOutsideClickHandlerForRefs([wrapperRef, portalRef], isOpen, closePortal);

  useEffect(() => {
    if (update) {
      update();
    }
  }, [children, update]);

  return (
    <div className="relative" ref={setReferenceElement as any}>
      <button
        className={
          typeof btnClassName === 'string'
            ? btnClassName
            : btnClassName(isOpen || (openWithHover && isHovered && !tooltip))
        }
        onClick={handleButtonClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={wrapperRef}
      >
        {btnInner}
      </button>

      <div className="relative">
        {usePortal ? (
          <>
            {ReactDOM.createPortal(
              <div
                className={`transition-opacity delay-100 duration-100 ease-in ${
                  isOpen || (openWithHover && isHovered)
                    ? 'opacity-100 z-50 '
                    : 'opacity-0 -z-10 hidden'
                }`}
                ref={setPopperElement as any}
                style={styles.popper}
                {...attributes.popper}
              >
                <div ref={portalRef}>
                  {tooltipWithHover && tooltip && !isOpen ? (
                    <>{tooltip}</>
                  ) : (
                    <>{children}</>
                  )}
                </div>
              </div>,
              document.body,
            )}
          </>
        ) : (
          <div
            className={`z-50 transition-opacity delay-100 duration-100 ease-in ${
              isOpen || (openWithHover && isHovered)
                ? 'opacity-100 z-50'
                : 'opacity-0 -z-10 hidden'
            }`}
            ref={setPopperElement as any}
            style={styles.popper}
            {...attributes.popper}
          >
            <div ref={portalRef}>
              {tooltipWithHover && tooltip && !isOpen ? (
                <>{tooltip}</>
              ) : (
                <>{children}</>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
