import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageQuestionChoicesQueryVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
}>;


export type PageQuestionChoicesQuery = { __typename?: 'RootQueryType', pageQuestionChoices?: Array<{ __typename?: 'PageChoice', id: string, type?: Types.NextInteractionType | null, hasNext?: boolean | null, text?: string | null, value?: string | null, order: number, middleInteraction: { __typename?: 'MiddleInteraction', id: string, type: Types.MiddleInteractionType, question?: string | null, embed?: { __typename?: 'Embed', id: string, linkUrl: string, oembedResult?: any | null, status: Types.EmbedStatus, reason?: Types.EmbedFailureReason | null } | null }, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null } | null> | null };


export const PageQuestionChoicesDocument = gql`
    query pageQuestionChoices($questionId: ID!) {
  pageQuestionChoices(questionId: $questionId) {
    id
    type
    hasNext
    text
    value
    order
    middleInteraction {
      id
      type
      question
      embed {
        id
        linkUrl
        oembedResult
        status
        reason
      }
    }
    nextPage {
      id
      title
      type
      order
    }
  }
}
    `;

/**
 * __usePageQuestionChoicesQuery__
 *
 * To run a query within a React component, call `usePageQuestionChoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuestionChoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuestionChoicesQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function usePageQuestionChoicesQuery(baseOptions: Apollo.QueryHookOptions<PageQuestionChoicesQuery, PageQuestionChoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageQuestionChoicesQuery, PageQuestionChoicesQueryVariables>(PageQuestionChoicesDocument, options);
      }
export function usePageQuestionChoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuestionChoicesQuery, PageQuestionChoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageQuestionChoicesQuery, PageQuestionChoicesQueryVariables>(PageQuestionChoicesDocument, options);
        }
export type PageQuestionChoicesQueryHookResult = ReturnType<typeof usePageQuestionChoicesQuery>;
export type PageQuestionChoicesLazyQueryHookResult = ReturnType<typeof usePageQuestionChoicesLazyQuery>;
export type PageQuestionChoicesQueryResult = Apollo.QueryResult<PageQuestionChoicesQuery, PageQuestionChoicesQueryVariables>;
export function refetchPageQuestionChoicesQuery(variables: PageQuestionChoicesQueryVariables) {
      return { query: PageQuestionChoicesDocument, variables: variables }
    }