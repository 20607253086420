import { gql } from '@apollo/client';

import { apolloClient } from '../apolloClient';

export const getStoryCountCache = (tenantId?: string) => {
  if (!tenantId) return undefined;
  const { storyCount } =
    apolloClient.readFragment({
      id: `Tenant:${tenantId}`,
      fragment: gql`
        fragment TenantStoryCount on Tenant {
          storyCount
        }
      `,
    }) ?? {};

  return storyCount;
};

export const updateTenantStoryCountCache = (
  tenantId: string,
  storyCount: number,
  updateCount: number,
) => {
  apolloClient.writeFragment({
    id: `Tenant:${tenantId}`,
    fragment: gql`
      fragment TenantStoryCount on Tenant {
        storyCount
      }
    `,
    data: {
      storyCount: storyCount + updateCount,
    },
  });
};
