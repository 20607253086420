import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadBrandLogoMutationVariables = Types.Exact<{
  input: Types.UploadBrandLogoInput;
}>;


export type UploadBrandLogoMutation = { __typename?: 'RootMutationType', uploadBrandLogo?: { __typename?: 'BrandSettingResult', brandSetting?: { __typename?: 'BrandSetting', id: string, primaryColor?: string | null, logoUrl?: string | null, logoForDarkBgUrl?: string | null, symbolUrl?: string | null, symbolForDarkBgUrl?: string | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UploadBrandLogoDocument = gql`
    mutation uploadBrandLogo($input: UploadBrandLogoInput!) {
  uploadBrandLogo(input: $input) {
    brandSetting {
      id
      primaryColor
      logoUrl
      logoForDarkBgUrl
      symbolUrl
      symbolForDarkBgUrl
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UploadBrandLogoMutationFn = Apollo.MutationFunction<UploadBrandLogoMutation, UploadBrandLogoMutationVariables>;

/**
 * __useUploadBrandLogoMutation__
 *
 * To run a mutation, you first call `useUploadBrandLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBrandLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBrandLogoMutation, { data, loading, error }] = useUploadBrandLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBrandLogoMutation(baseOptions?: Apollo.MutationHookOptions<UploadBrandLogoMutation, UploadBrandLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBrandLogoMutation, UploadBrandLogoMutationVariables>(UploadBrandLogoDocument, options);
      }
export type UploadBrandLogoMutationHookResult = ReturnType<typeof useUploadBrandLogoMutation>;
export type UploadBrandLogoMutationResult = Apollo.MutationResult<UploadBrandLogoMutation>;
export type UploadBrandLogoMutationOptions = Apollo.BaseMutationOptions<UploadBrandLogoMutation, UploadBrandLogoMutationVariables>;