import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelsForDashboardSidebarQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ChannelsForDashboardSidebarQuery = { __typename?: 'RootQueryType', channels: { __typename?: 'ChannelConnection', edges?: Array<{ __typename?: 'ChannelEdge', node?: { __typename?: 'Channel', id: string, isPersonal: boolean, isPrivate: boolean, databaseId: any, title: string } | null } | null> | null } };

export type ChannelsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ChannelsQuery = { __typename?: 'RootQueryType', channels: { __typename?: 'ChannelConnection', edges?: Array<{ __typename?: 'ChannelEdge', node?: { __typename?: 'Channel', id: string, isPersonal: boolean, isPrivate: boolean, databaseId: any, title: string } | null } | null> | null } };


export const ChannelsForDashboardSidebarDocument = gql`
    query channelsForDashboardSidebar($first: Int) {
  channels(first: $first) {
    edges {
      node {
        id
        isPersonal
        isPrivate
        databaseId
        title
      }
    }
  }
}
    `;

/**
 * __useChannelsForDashboardSidebarQuery__
 *
 * To run a query within a React component, call `useChannelsForDashboardSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsForDashboardSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsForDashboardSidebarQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useChannelsForDashboardSidebarQuery(baseOptions?: Apollo.QueryHookOptions<ChannelsForDashboardSidebarQuery, ChannelsForDashboardSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsForDashboardSidebarQuery, ChannelsForDashboardSidebarQueryVariables>(ChannelsForDashboardSidebarDocument, options);
      }
export function useChannelsForDashboardSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsForDashboardSidebarQuery, ChannelsForDashboardSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsForDashboardSidebarQuery, ChannelsForDashboardSidebarQueryVariables>(ChannelsForDashboardSidebarDocument, options);
        }
export type ChannelsForDashboardSidebarQueryHookResult = ReturnType<typeof useChannelsForDashboardSidebarQuery>;
export type ChannelsForDashboardSidebarLazyQueryHookResult = ReturnType<typeof useChannelsForDashboardSidebarLazyQuery>;
export type ChannelsForDashboardSidebarQueryResult = Apollo.QueryResult<ChannelsForDashboardSidebarQuery, ChannelsForDashboardSidebarQueryVariables>;
export function refetchChannelsForDashboardSidebarQuery(variables?: ChannelsForDashboardSidebarQueryVariables) {
      return { query: ChannelsForDashboardSidebarDocument, variables: variables }
    }
export const ChannelsDocument = gql`
    query channels($first: Int) {
  channels(first: $first) {
    edges {
      node {
        id
        isPersonal
        isPrivate
        databaseId
        title
      }
    }
  }
}
    `;

/**
 * __useChannelsQuery__
 *
 * To run a query within a React component, call `useChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useChannelsQuery(baseOptions?: Apollo.QueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
      }
export function useChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
        }
export type ChannelsQueryHookResult = ReturnType<typeof useChannelsQuery>;
export type ChannelsLazyQueryHookResult = ReturnType<typeof useChannelsLazyQuery>;
export type ChannelsQueryResult = Apollo.QueryResult<ChannelsQuery, ChannelsQueryVariables>;
export function refetchChannelsQuery(variables?: ChannelsQueryVariables) {
      return { query: ChannelsDocument, variables: variables }
    }