export const AppLoading = () => {
  return (
    <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
      <span
        className="top-1/2 my-0 mx-auto block relative w-0 h-0"
        style={{ top: '50%' }}
      >
        <div className="animate-spin h-8 w-8 border-2 border-slate-900 border-t-transparent rounded-full" />
      </span>
    </div>
  );
};
