import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateInitiationSettingMutationVariables = Types.Exact<{
  input: Types.UpdateInitiationSettingInput;
}>;


export type UpdateInitiationSettingMutation = { __typename?: 'RootMutationType', updateInitiationSetting?: { __typename?: 'InitiationSettingResult', initiationSetting?: { __typename?: 'InitiationSetting', id: string, locale: Types.StoryLocale, canShare: boolean, showBadge: boolean, linkPolicy: Types.ContentAccessPolicy, tenantMemberPolicy: Types.ContentAccessPolicy, isInteractive: boolean, widgetType: Types.WidgetType, widgetLive: boolean, showPages: boolean, orientation: Types.PlayerOrientationType, canChangeMediaSpeed: boolean, showChoiceLetters: boolean, choiceLetterType: Types.ChoiceLetterType } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const UpdateInitiationSettingDocument = gql`
    mutation updateInitiationSetting($input: UpdateInitiationSettingInput!) {
  updateInitiationSetting(input: $input) {
    initiationSetting {
      id
      locale
      canShare
      showBadge
      linkPolicy
      tenantMemberPolicy
      isInteractive
      widgetType
      widgetLive
      showPages
      orientation
      canChangeMediaSpeed
      showChoiceLetters
      choiceLetterType
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateInitiationSettingMutationFn = Apollo.MutationFunction<UpdateInitiationSettingMutation, UpdateInitiationSettingMutationVariables>;

/**
 * __useUpdateInitiationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateInitiationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInitiationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInitiationSettingMutation, { data, loading, error }] = useUpdateInitiationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInitiationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInitiationSettingMutation, UpdateInitiationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInitiationSettingMutation, UpdateInitiationSettingMutationVariables>(UpdateInitiationSettingDocument, options);
      }
export type UpdateInitiationSettingMutationHookResult = ReturnType<typeof useUpdateInitiationSettingMutation>;
export type UpdateInitiationSettingMutationResult = Apollo.MutationResult<UpdateInitiationSettingMutation>;
export type UpdateInitiationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateInitiationSettingMutation, UpdateInitiationSettingMutationVariables>;