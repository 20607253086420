import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryNotificationSettingQueryVariables = Types.Exact<{
  storyId: Types.Scalars['ID']['input'];
}>;


export type StoryNotificationSettingQuery = { __typename?: 'RootQueryType', storyNotificationSetting?: { __typename?: 'StoryNotificationSetting', id: string, isDefault: boolean, includeAnonymous: boolean, playEmail: boolean, collectLeadEmail: boolean, reachEndEmail: boolean, responseEmail: boolean } | null };


export const StoryNotificationSettingDocument = gql`
    query storyNotificationSetting($storyId: ID!) {
  storyNotificationSetting(storyId: $storyId) {
    id
    isDefault
    includeAnonymous
    playEmail
    collectLeadEmail
    reachEndEmail
    responseEmail
  }
}
    `;

/**
 * __useStoryNotificationSettingQuery__
 *
 * To run a query within a React component, call `useStoryNotificationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryNotificationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryNotificationSettingQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useStoryNotificationSettingQuery(baseOptions: Apollo.QueryHookOptions<StoryNotificationSettingQuery, StoryNotificationSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryNotificationSettingQuery, StoryNotificationSettingQueryVariables>(StoryNotificationSettingDocument, options);
      }
export function useStoryNotificationSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryNotificationSettingQuery, StoryNotificationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryNotificationSettingQuery, StoryNotificationSettingQueryVariables>(StoryNotificationSettingDocument, options);
        }
export type StoryNotificationSettingQueryHookResult = ReturnType<typeof useStoryNotificationSettingQuery>;
export type StoryNotificationSettingLazyQueryHookResult = ReturnType<typeof useStoryNotificationSettingLazyQuery>;
export type StoryNotificationSettingQueryResult = Apollo.QueryResult<StoryNotificationSettingQuery, StoryNotificationSettingQueryVariables>;
export function refetchStoryNotificationSettingQuery(variables: StoryNotificationSettingQueryVariables) {
      return { query: StoryNotificationSettingDocument, variables: variables }
    }