import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadBrandSymbolForDarkBgMutationVariables = Types.Exact<{
  input: Types.UploadBrandSymbolForDarkBgInput;
}>;


export type UploadBrandSymbolForDarkBgMutation = { __typename?: 'RootMutationType', uploadBrandSymbolForDarkBg?: { __typename?: 'BrandSettingResult', brandSetting?: { __typename?: 'BrandSetting', id: string, primaryColor?: string | null, logoUrl?: string | null, logoForDarkBgUrl?: string | null, symbolUrl?: string | null, symbolForDarkBgUrl?: string | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UploadBrandSymbolForDarkBgDocument = gql`
    mutation uploadBrandSymbolForDarkBg($input: UploadBrandSymbolForDarkBgInput!) {
  uploadBrandSymbolForDarkBg(input: $input) {
    brandSetting {
      id
      primaryColor
      logoUrl
      logoForDarkBgUrl
      symbolUrl
      symbolForDarkBgUrl
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UploadBrandSymbolForDarkBgMutationFn = Apollo.MutationFunction<UploadBrandSymbolForDarkBgMutation, UploadBrandSymbolForDarkBgMutationVariables>;

/**
 * __useUploadBrandSymbolForDarkBgMutation__
 *
 * To run a mutation, you first call `useUploadBrandSymbolForDarkBgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBrandSymbolForDarkBgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBrandSymbolForDarkBgMutation, { data, loading, error }] = useUploadBrandSymbolForDarkBgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBrandSymbolForDarkBgMutation(baseOptions?: Apollo.MutationHookOptions<UploadBrandSymbolForDarkBgMutation, UploadBrandSymbolForDarkBgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBrandSymbolForDarkBgMutation, UploadBrandSymbolForDarkBgMutationVariables>(UploadBrandSymbolForDarkBgDocument, options);
      }
export type UploadBrandSymbolForDarkBgMutationHookResult = ReturnType<typeof useUploadBrandSymbolForDarkBgMutation>;
export type UploadBrandSymbolForDarkBgMutationResult = Apollo.MutationResult<UploadBrandSymbolForDarkBgMutation>;
export type UploadBrandSymbolForDarkBgMutationOptions = Apollo.BaseMutationOptions<UploadBrandSymbolForDarkBgMutation, UploadBrandSymbolForDarkBgMutationVariables>;