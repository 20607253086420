import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiationLikeQueryVariables = Types.Exact<{
  initiationId: Types.Scalars['ID']['input'];
}>;


export type InitiationLikeQuery = { __typename?: 'RootQueryType', initiationLike?: { __typename?: 'InitiationLike', id: string, initiationId: string } | null };


export const InitiationLikeDocument = gql`
    query initiationLike($initiationId: ID!) {
  initiationLike(initiationId: $initiationId) {
    id
    initiationId
  }
}
    `;

/**
 * __useInitiationLikeQuery__
 *
 * To run a query within a React component, call `useInitiationLikeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiationLikeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiationLikeQuery({
 *   variables: {
 *      initiationId: // value for 'initiationId'
 *   },
 * });
 */
export function useInitiationLikeQuery(baseOptions: Apollo.QueryHookOptions<InitiationLikeQuery, InitiationLikeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitiationLikeQuery, InitiationLikeQueryVariables>(InitiationLikeDocument, options);
      }
export function useInitiationLikeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitiationLikeQuery, InitiationLikeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitiationLikeQuery, InitiationLikeQueryVariables>(InitiationLikeDocument, options);
        }
export type InitiationLikeQueryHookResult = ReturnType<typeof useInitiationLikeQuery>;
export type InitiationLikeLazyQueryHookResult = ReturnType<typeof useInitiationLikeLazyQuery>;
export type InitiationLikeQueryResult = Apollo.QueryResult<InitiationLikeQuery, InitiationLikeQueryVariables>;
export function refetchInitiationLikeQuery(variables: InitiationLikeQueryVariables) {
      return { query: InitiationLikeDocument, variables: variables }
    }