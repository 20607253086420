import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendResetPasswordLinkMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type SendResetPasswordLinkMutation = { __typename?: 'RootMutationType', sendResetPasswordLink?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const SendResetPasswordLinkDocument = gql`
    mutation sendResetPasswordLink($email: String!) {
  sendResetPasswordLink(email: $email) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type SendResetPasswordLinkMutationFn = Apollo.MutationFunction<SendResetPasswordLinkMutation, SendResetPasswordLinkMutationVariables>;

/**
 * __useSendResetPasswordLinkMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordLinkMutation, { data, loading, error }] = useSendResetPasswordLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordLinkMutation, SendResetPasswordLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordLinkMutation, SendResetPasswordLinkMutationVariables>(SendResetPasswordLinkDocument, options);
      }
export type SendResetPasswordLinkMutationHookResult = ReturnType<typeof useSendResetPasswordLinkMutation>;
export type SendResetPasswordLinkMutationResult = Apollo.MutationResult<SendResetPasswordLinkMutation>;
export type SendResetPasswordLinkMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordLinkMutation, SendResetPasswordLinkMutationVariables>;