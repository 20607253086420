import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantIntegrationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TenantIntegrationsQuery = { __typename?: 'RootQueryType', tenantIntegrations: Array<{ __typename?: 'TenantIntegration', type: Types.TenantIntegrationType, canConnect: boolean, linkUrl?: string | null, guideUrl?: string | null, isConnected: boolean, connectedAccountName?: string | null, connectedAt?: any | null, expiresAt?: any | null }> };


export const TenantIntegrationsDocument = gql`
    query TenantIntegrations {
  tenantIntegrations {
    type
    canConnect
    linkUrl
    guideUrl
    isConnected
    connectedAccountName
    connectedAt
    expiresAt
  }
}
    `;

/**
 * __useTenantIntegrationsQuery__
 *
 * To run a query within a React component, call `useTenantIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<TenantIntegrationsQuery, TenantIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantIntegrationsQuery, TenantIntegrationsQueryVariables>(TenantIntegrationsDocument, options);
      }
export function useTenantIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantIntegrationsQuery, TenantIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantIntegrationsQuery, TenantIntegrationsQueryVariables>(TenantIntegrationsDocument, options);
        }
export type TenantIntegrationsQueryHookResult = ReturnType<typeof useTenantIntegrationsQuery>;
export type TenantIntegrationsLazyQueryHookResult = ReturnType<typeof useTenantIntegrationsLazyQuery>;
export type TenantIntegrationsQueryResult = Apollo.QueryResult<TenantIntegrationsQuery, TenantIntegrationsQueryVariables>;
export function refetchTenantIntegrationsQuery(variables?: TenantIntegrationsQueryVariables) {
      return { query: TenantIntegrationsDocument, variables: variables }
    }