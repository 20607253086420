import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddTenantMutationVariables = Types.Exact<{
  input: Types.AddTenantInput;
}>;


export type AddTenantMutation = { __typename?: 'RootMutationType', addTenant?: { __typename?: 'TenantResult', tenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, description?: string | null, iconUrl?: string | null, isPersonal?: boolean | null, storageUsage?: number | null, memberCount?: number | null, monthlyPlays?: number | null, monthlyImpressions?: number | null, maxSeats?: number | null, maxStorage?: number | null, maxMonthlyImpressions?: number | null, maxMonthlyPlays?: number | null, maxLifetimePlays?: number | null, features: Array<Types.PlanFeature>, canUseTrial?: boolean | null, canUseCoupon?: boolean | null, plan?: { __typename?: 'Plan', id?: string | null, isTrial?: boolean | null, isActive?: boolean | null, slug?: string | null, name?: string | null, nameI18nKey?: string | null, initialMembers?: number | null, initialPlays?: number | null, initialStorage?: number | null, maxLifetimePlays?: number | null, members?: number | null, plays?: number | null, storage?: number | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const AddTenantDocument = gql`
    mutation addTenant($input: AddTenantInput!) {
  addTenant(input: $input) {
    tenant {
      id
      databaseId
      commerceActivated
      storyCount
      name
      description
      iconUrl
      isPersonal
      storageUsage
      memberCount
      monthlyPlays
      monthlyImpressions
      maxSeats
      maxStorage
      maxMonthlyImpressions
      maxMonthlyPlays
      maxLifetimePlays
      features
      canUseTrial
      canUseCoupon
      plan {
        id
        isTrial
        isActive
        slug
        name
        nameI18nKey
        initialMembers
        initialPlays
        initialStorage
        maxLifetimePlays
        members
        plays
        storage
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type AddTenantMutationFn = Apollo.MutationFunction<AddTenantMutation, AddTenantMutationVariables>;

/**
 * __useAddTenantMutation__
 *
 * To run a mutation, you first call `useAddTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTenantMutation, { data, loading, error }] = useAddTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTenantMutation(baseOptions?: Apollo.MutationHookOptions<AddTenantMutation, AddTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTenantMutation, AddTenantMutationVariables>(AddTenantDocument, options);
      }
export type AddTenantMutationHookResult = ReturnType<typeof useAddTenantMutation>;
export type AddTenantMutationResult = Apollo.MutationResult<AddTenantMutation>;
export type AddTenantMutationOptions = Apollo.BaseMutationOptions<AddTenantMutation, AddTenantMutationVariables>;