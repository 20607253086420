import classnames from 'classnames';

interface Props {
  loading: boolean;
  message?: string;
}

export const ShareloLoader = ({ loading, message }: Props) => {
  return (
    <div className={classnames('hidden', { loading })}>
      <div className="flex flex-col items-center">
        <div className="loader" />
        {typeof message === 'string' && (
          <div className="loader-message">
            <span>{message}</span>
          </div>
        )}
      </div>
    </div>
  );
};
