import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { ContactFragmentOnContactFeedFragmentDoc } from './contact-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateContactMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateContactInput;
}>;


export type UpdateContactMutation = { __typename?: 'RootMutationType', updateContact?: { __typename?: 'ContactMutationResult', contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateContactDocument = gql`
    mutation updateContact($id: ID!, $input: UpdateContactInput!) {
  updateContact(id: $id, input: $input) {
    contact {
      ...ContactFragmentOnContactFeed
    }
    userErrors {
      field
      message
    }
  }
}
    ${ContactFragmentOnContactFeedFragmentDoc}`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;