import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { ContactFragmentOnContactFeedFragmentDoc } from './contact-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContactsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ContactFilter>;
}>;


export type ContactsQuery = { __typename?: 'RootQueryType', contacts?: { __typename?: 'ContactConnection', totalCount: number, edges: Array<{ __typename?: 'ContactEdge', node?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null }>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type ContactsForCsvExportQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.ContactFilter>;
}>;


export type ContactsForCsvExportQuery = { __typename?: 'RootQueryType', contacts?: { __typename?: 'ContactConnection', edges: Array<{ __typename?: 'ContactEdge', node?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null }> } | null };

export type ContactEdgeOnContactFeedFragment = { __typename?: 'ContactEdge', node?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null };

export type ContactsForSelectQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ContactFilter>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ContactsForSelectQuery = { __typename?: 'RootQueryType', contactsForSelect?: { __typename?: 'ContactConnection', edges: Array<{ __typename?: 'ContactEdge', node?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null }> } | null };

export const ContactEdgeOnContactFeedFragmentDoc = gql`
    fragment ContactEdgeOnContactFeed on ContactEdge {
  node {
    ...ContactFragmentOnContactFeed
  }
}
    ${ContactFragmentOnContactFeedFragmentDoc}`;
export const ContactsDocument = gql`
    query contacts($first: Int, $last: Int, $after: String, $before: String, $filter: ContactFilter) {
  contacts(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    edges {
      ...ContactEdgeOnContactFeed
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
    ${ContactEdgeOnContactFeedFragmentDoc}`;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useContactsQuery(baseOptions?: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
      }
export function useContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
        }
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<typeof useContactsLazyQuery>;
export type ContactsQueryResult = Apollo.QueryResult<ContactsQuery, ContactsQueryVariables>;
export function refetchContactsQuery(variables?: ContactsQueryVariables) {
      return { query: ContactsDocument, variables: variables }
    }
export const ContactsForCsvExportDocument = gql`
    query contactsForCsvExport($first: Int, $filter: ContactFilter) {
  contacts(filter: $filter, first: $first) {
    edges {
      node {
        ...ContactFragmentOnContactFeed
      }
    }
  }
}
    ${ContactFragmentOnContactFeedFragmentDoc}`;

/**
 * __useContactsForCsvExportQuery__
 *
 * To run a query within a React component, call `useContactsForCsvExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsForCsvExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsForCsvExportQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useContactsForCsvExportQuery(baseOptions?: Apollo.QueryHookOptions<ContactsForCsvExportQuery, ContactsForCsvExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsForCsvExportQuery, ContactsForCsvExportQueryVariables>(ContactsForCsvExportDocument, options);
      }
export function useContactsForCsvExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsForCsvExportQuery, ContactsForCsvExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsForCsvExportQuery, ContactsForCsvExportQueryVariables>(ContactsForCsvExportDocument, options);
        }
export type ContactsForCsvExportQueryHookResult = ReturnType<typeof useContactsForCsvExportQuery>;
export type ContactsForCsvExportLazyQueryHookResult = ReturnType<typeof useContactsForCsvExportLazyQuery>;
export type ContactsForCsvExportQueryResult = Apollo.QueryResult<ContactsForCsvExportQuery, ContactsForCsvExportQueryVariables>;
export function refetchContactsForCsvExportQuery(variables?: ContactsForCsvExportQueryVariables) {
      return { query: ContactsForCsvExportDocument, variables: variables }
    }
export const ContactsForSelectDocument = gql`
    query contactsForSelect($filter: ContactFilter, $first: Int, $after: String) {
  contactsForSelect(after: $after, first: $first, filter: $filter) {
    edges {
      node {
        ...ContactFragmentOnContactFeed
      }
    }
  }
}
    ${ContactFragmentOnContactFeedFragmentDoc}`;

/**
 * __useContactsForSelectQuery__
 *
 * To run a query within a React component, call `useContactsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsForSelectQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useContactsForSelectQuery(baseOptions?: Apollo.QueryHookOptions<ContactsForSelectQuery, ContactsForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsForSelectQuery, ContactsForSelectQueryVariables>(ContactsForSelectDocument, options);
      }
export function useContactsForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsForSelectQuery, ContactsForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsForSelectQuery, ContactsForSelectQueryVariables>(ContactsForSelectDocument, options);
        }
export type ContactsForSelectQueryHookResult = ReturnType<typeof useContactsForSelectQuery>;
export type ContactsForSelectLazyQueryHookResult = ReturnType<typeof useContactsForSelectLazyQuery>;
export type ContactsForSelectQueryResult = Apollo.QueryResult<ContactsForSelectQuery, ContactsForSelectQueryVariables>;
export function refetchContactsForSelectQuery(variables?: ContactsForSelectQueryVariables) {
      return { query: ContactsForSelectDocument, variables: variables }
    }