import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePageQuestionMutationVariables = Types.Exact<{
  input: Types.UpdatePageQuestionInput;
}>;


export type UpdatePageQuestionMutation = { __typename?: 'RootMutationType', updatePageQuestion?: { __typename?: 'PageQuestionResult', pageQuestion?: { __typename?: 'PageQuestion', id: string, title?: string | null, titleColor: string, showTitle: boolean, timing: number } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdatePageQuestionDocument = gql`
    mutation updatePageQuestion($input: UpdatePageQuestionInput!) {
  updatePageQuestion(input: $input) {
    pageQuestion {
      id
      title
      titleColor
      showTitle
      timing
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdatePageQuestionMutationFn = Apollo.MutationFunction<UpdatePageQuestionMutation, UpdatePageQuestionMutationVariables>;

/**
 * __useUpdatePageQuestionMutation__
 *
 * To run a mutation, you first call `useUpdatePageQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageQuestionMutation, { data, loading, error }] = useUpdatePageQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePageQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageQuestionMutation, UpdatePageQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageQuestionMutation, UpdatePageQuestionMutationVariables>(UpdatePageQuestionDocument, options);
      }
export type UpdatePageQuestionMutationHookResult = ReturnType<typeof useUpdatePageQuestionMutation>;
export type UpdatePageQuestionMutationResult = Apollo.MutationResult<UpdatePageQuestionMutation>;
export type UpdatePageQuestionMutationOptions = Apollo.BaseMutationOptions<UpdatePageQuestionMutation, UpdatePageQuestionMutationVariables>;