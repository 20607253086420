import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFileUploadMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateFileUploadInput;
}>;


export type UpdateFileUploadMutation = { __typename?: 'RootMutationType', updateFileUpload?: { __typename?: 'FileUploadResult', fileUpload?: { __typename?: 'FileUpload', id: string, fileUrl?: string | null, name?: string | null, alternativeText?: string | null, pageCount?: number | null, size?: number | null, insertedAt?: any | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateFileUploadDocument = gql`
    mutation updateFileUpload($id: ID!, $input: UpdateFileUploadInput!) {
  updateFileUpload(id: $id, input: $input) {
    fileUpload {
      id
      fileUrl
      name
      alternativeText
      pageCount
      size
      insertedAt
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateFileUploadMutationFn = Apollo.MutationFunction<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>;

/**
 * __useUpdateFileUploadMutation__
 *
 * To run a mutation, you first call `useUpdateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileUploadMutation, { data, loading, error }] = useUpdateFileUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>(UpdateFileUploadDocument, options);
      }
export type UpdateFileUploadMutationHookResult = ReturnType<typeof useUpdateFileUploadMutation>;
export type UpdateFileUploadMutationResult = Apollo.MutationResult<UpdateFileUploadMutation>;
export type UpdateFileUploadMutationOptions = Apollo.BaseMutationOptions<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>;