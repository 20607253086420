import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { CustomLogicFieldsFragmentDoc } from './custom-logic-fields-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChoiceLogicsQueryVariables = Types.Exact<{
  choiceId: Types.Scalars['ID']['input'];
}>;


export type ChoiceLogicsQuery = { __typename?: 'RootQueryType', choiceLogics?: Array<{ __typename?: 'ChoiceLogic', id: string, operator: Types.LogicOperatorType, conditionals?: Array<{ __typename?: 'ChoiceConditional', id: string, comparison: Types.ChoiceConditionalComparisonType, question?: { __typename?: 'PageQuestion', id: string, title?: string | null, titleColor: string, storyPage?: { __typename?: 'InitiationItem', id: string, title: string, order: number } | null } | null, choice?: { __typename?: 'PageChoice', id: string, order: number, text?: string | null } | null } | null> | null, actions?: Array<{ __typename?: 'ChoiceAction', id: string, type?: Types.ChoiceActionType | null, result?: Types.ChoiceActionResultType | null, value?: number | null, strValue?: string | null, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null } | null> | null } | null> | null };


export const ChoiceLogicsDocument = gql`
    query choiceLogics($choiceId: ID!) {
  choiceLogics(choiceId: $choiceId) {
    ...CustomLogicFields
  }
}
    ${CustomLogicFieldsFragmentDoc}`;

/**
 * __useChoiceLogicsQuery__
 *
 * To run a query within a React component, call `useChoiceLogicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChoiceLogicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChoiceLogicsQuery({
 *   variables: {
 *      choiceId: // value for 'choiceId'
 *   },
 * });
 */
export function useChoiceLogicsQuery(baseOptions: Apollo.QueryHookOptions<ChoiceLogicsQuery, ChoiceLogicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChoiceLogicsQuery, ChoiceLogicsQueryVariables>(ChoiceLogicsDocument, options);
      }
export function useChoiceLogicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChoiceLogicsQuery, ChoiceLogicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChoiceLogicsQuery, ChoiceLogicsQueryVariables>(ChoiceLogicsDocument, options);
        }
export type ChoiceLogicsQueryHookResult = ReturnType<typeof useChoiceLogicsQuery>;
export type ChoiceLogicsLazyQueryHookResult = ReturnType<typeof useChoiceLogicsLazyQuery>;
export type ChoiceLogicsQueryResult = Apollo.QueryResult<ChoiceLogicsQuery, ChoiceLogicsQueryVariables>;
export function refetchChoiceLogicsQuery(variables: ChoiceLogicsQueryVariables) {
      return { query: ChoiceLogicsDocument, variables: variables }
    }