import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShoppableProductQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ShoppableProductQuery = { __typename?: 'RootQueryType', shoppableProduct?: { __typename?: 'ShoppableProduct', id: string, ctaType: Types.ShoppableProductCtaType, customCtaText?: string | null, product: { __typename?: 'Product', id: string, title: string, sourceId?: string | null, sourceType?: string | null, currencyCode: string, onlineStoreUrl: string, featuredImage?: { __typename?: 'ProductImage', id: string, sourceId?: string | null, sourceType?: Types.ProductSourceType | null, src?: string | null, imageUpload?: { __typename?: 'ImageUpload', id: string } | null } | null, variants?: Array<{ __typename?: 'ProductVariant', id: string, originalPrice?: number | null, price?: number | null }> | null } } | null };


export const ShoppableProductDocument = gql`
    query ShoppableProduct($id: ID!) {
  shoppableProduct(id: $id) {
    id
    ctaType
    customCtaText
    product {
      id
      title
      sourceId
      sourceType
      currencyCode
      onlineStoreUrl
      featuredImage {
        id
        sourceId
        sourceType
        src
        imageUpload {
          id
        }
      }
      variants {
        id
        originalPrice
        price
      }
    }
  }
}
    `;

/**
 * __useShoppableProductQuery__
 *
 * To run a query within a React component, call `useShoppableProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useShoppableProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShoppableProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShoppableProductQuery(baseOptions: Apollo.QueryHookOptions<ShoppableProductQuery, ShoppableProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShoppableProductQuery, ShoppableProductQueryVariables>(ShoppableProductDocument, options);
      }
export function useShoppableProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShoppableProductQuery, ShoppableProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShoppableProductQuery, ShoppableProductQueryVariables>(ShoppableProductDocument, options);
        }
export type ShoppableProductQueryHookResult = ReturnType<typeof useShoppableProductQuery>;
export type ShoppableProductLazyQueryHookResult = ReturnType<typeof useShoppableProductLazyQuery>;
export type ShoppableProductQueryResult = Apollo.QueryResult<ShoppableProductQuery, ShoppableProductQueryVariables>;
export function refetchShoppableProductQuery(variables: ShoppableProductQueryVariables) {
      return { query: ShoppableProductDocument, variables: variables }
    }