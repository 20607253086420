interface Props {
  fill: string;
  className?: string;
}

export const UntitledUIFilterLinesIcon = ({
  className,
  fill = '#4b5563',
}: Props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <path d="M6 13h12c.55 0 1-.45 1-1 0-.56-.45-1-1-1H6c-.56 0-1 .44-1 1 0 .55.44 1 1 1ZM3 7h18c.55 0 1-.45 1-1 0-.56-.45-1-1-1H3c-.56 0-1 .44-1 1 0 .55.44 1 1 1Zm6 12h6c.55 0 1-.45 1-1 0-.56-.45-1-1-1H9c-.56 0-1 .44-1 1 0 .55.44 1 1 1Z" />
    </svg>
  );
};
