import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMemberInvitationsMutationVariables = Types.Exact<{
  input: Types.CreateMemberInvitationsInput;
}>;


export type CreateMemberInvitationsMutation = { __typename?: 'RootMutationType', createMemberInvitations?: { __typename?: 'MemberInvitationsResult', memberInvitations?: Array<{ __typename?: 'MemberInvitation', id: string, email?: string | null, invitedAt?: any | null } | null> | null, userErrors?: Array<{ __typename?: 'UserError', index?: number | null, field: string, message: string } | null> | null } | null };


export const CreateMemberInvitationsDocument = gql`
    mutation createMemberInvitations($input: CreateMemberInvitationsInput!) {
  createMemberInvitations(input: $input) {
    memberInvitations {
      id
      email
      invitedAt
    }
    userErrors {
      index
      field
      message
    }
  }
}
    `;
export type CreateMemberInvitationsMutationFn = Apollo.MutationFunction<CreateMemberInvitationsMutation, CreateMemberInvitationsMutationVariables>;

/**
 * __useCreateMemberInvitationsMutation__
 *
 * To run a mutation, you first call `useCreateMemberInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberInvitationsMutation, { data, loading, error }] = useCreateMemberInvitationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberInvitationsMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberInvitationsMutation, CreateMemberInvitationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberInvitationsMutation, CreateMemberInvitationsMutationVariables>(CreateMemberInvitationsDocument, options);
      }
export type CreateMemberInvitationsMutationHookResult = ReturnType<typeof useCreateMemberInvitationsMutation>;
export type CreateMemberInvitationsMutationResult = Apollo.MutationResult<CreateMemberInvitationsMutation>;
export type CreateMemberInvitationsMutationOptions = Apollo.BaseMutationOptions<CreateMemberInvitationsMutation, CreateMemberInvitationsMutationVariables>;