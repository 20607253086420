import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LikeInitiationMutationVariables = Types.Exact<{
  input: Types.LikeInitiationInput;
}>;


export type LikeInitiationMutation = { __typename?: 'RootMutationType', likeInitiation?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const LikeInitiationDocument = gql`
    mutation likeInitiation($input: LikeInitiationInput!) {
  likeInitiation(input: $input) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type LikeInitiationMutationFn = Apollo.MutationFunction<LikeInitiationMutation, LikeInitiationMutationVariables>;

/**
 * __useLikeInitiationMutation__
 *
 * To run a mutation, you first call `useLikeInitiationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeInitiationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeInitiationMutation, { data, loading, error }] = useLikeInitiationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikeInitiationMutation(baseOptions?: Apollo.MutationHookOptions<LikeInitiationMutation, LikeInitiationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeInitiationMutation, LikeInitiationMutationVariables>(LikeInitiationDocument, options);
      }
export type LikeInitiationMutationHookResult = ReturnType<typeof useLikeInitiationMutation>;
export type LikeInitiationMutationResult = Apollo.MutationResult<LikeInitiationMutation>;
export type LikeInitiationMutationOptions = Apollo.BaseMutationOptions<LikeInitiationMutation, LikeInitiationMutationVariables>;