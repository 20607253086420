
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FormField": [
      "AudioField",
      "EmailField",
      "LongTextField",
      "PhoneField",
      "ShortTextField",
      "VideoField"
    ],
    "InitiationResult": [
      "Initiation",
      "InvalidInviteToken",
      "InviteRequired",
      "LinkNoAccess",
      "RequireInvitation",
      "ShareDisabled",
      "UnknownError"
    ],
    "Interaction": [
      "HotspotInteraction",
      "MultipleChoiceInteraction",
      "ShoppableInteraction"
    ],
    "Node": [
      "ApiKey",
      "AudioUpload",
      "BrandSetting",
      "Channel",
      "ChoiceAction",
      "ChoiceConditional",
      "ChoiceLogic",
      "Company",
      "Consent",
      "ConsentAgreement",
      "ConsentVersion",
      "Contact",
      "ContactForm",
      "Content",
      "ContentNarration",
      "Embed",
      "EndScreenSetting",
      "FileUpload",
      "Hotspot",
      "HotspotInteraction",
      "ImageUpload",
      "Initiation",
      "InitiationInvite",
      "InitiationItem",
      "InitiationSetting",
      "Member",
      "MemberInvitation",
      "MiddleInteraction",
      "MultipleChoiceInteraction",
      "NarrationChapter",
      "NotionOauthInfo",
      "PageChoice",
      "PageQuestion",
      "PlanSubscription",
      "Product",
      "ProductImage",
      "ProductTag",
      "ProductType",
      "ShopifyProduct",
      "ShoppableInteraction",
      "ShoppableProduct",
      "StoryLink",
      "StoryNotificationReceiver",
      "StoryNotificationSetting",
      "StoryResponse",
      "StoryTimeline",
      "Tenant",
      "TextResponse",
      "Thread",
      "User",
      "VideoProcessingRequest",
      "VideoResponse",
      "VideoUpload",
      "VideoUploadRequest",
      "WidgetSetting"
    ]
  }
};
      export default result;
    