import { SVGProps } from 'react';

export const UntitledUIEyeOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M10.88 6.08c.36-.06.73-.09 1.11-.09 3.64 0 6.63 2.49 8.73 5.82.08.14.11.17.11.19-.01-.01-.01-.02-.01-.02-.01-.03-.01.02 0 0 0-.01-.01 0-.12.18-.36.56-.79 1.16-1.29 1.75-.36.42-.3 1.05.12 1.4.42.35 1.05.29 1.4-.13a20.9 20.9 0 0 0 1.44-1.98c.23-.37.3-.51.37-.81a2.1 2.1 0 0 0-.01-.9c-.03-.12-.07-.23-.12-.34-.07-.15-.12-.23-.26-.47-2.44-3.86-5.91-6.76-10.43-6.76-.48 0-.95.03-1.41.1a.995.995 0 1 0 .28 1.97Zm-4.73-.2c-1.92 1.29-3.44 3.04-4.59 4.86-.24.36-.31.5-.38.8-.07.27-.07.62-.01.89.02.11.06.22.11.33.06.14.11.22.25.46 2.43 3.85 5.9 6.75 10.42 6.75 2.1 0 4.06-.69 5.85-1.9.45-.32.57-.94.26-1.39-.32-.46-.94-.58-1.39-.27-1.48 1-3.05 1.55-4.73 1.55-3.65 0-6.64-2.5-8.74-5.83-.09-.15-.12-.18-.12-.2v.01c0 .02 0-.03 0 0-.01 0 0-.01.11-.19C4.2 10.14 5.56 8.59 7.2 7.47c.45-.32.57-.94.26-1.39-.32-.46-.94-.58-1.39-.27ZM2.28 3.69l17.99 18c.39.39 1.02.39 1.41 0 .39-.4.39-1.03 0-1.42l-18-18c-.4-.4-1.03-.4-1.42-.01-.4.39-.4 1.02-.01 1.41Zm6.87 5.46c-.75.74-1.18 1.75-1.18 2.82 0 2.2 1.79 4 3.99 4 1.07 0 2.08-.43 2.82-1.18.39-.4.39-1.03-.001-1.42a.99.99 0 0 0-1.42 0c-.38.37-.88.58-1.42.58a2 2 0 0 1-2-2c0-.54.21-1.05.58-1.42.39-.4.39-1.03-.01-1.42a.99.99 0 0 0-1.42 0Z" />
  </svg>
);
