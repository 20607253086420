/* eslint-disable react-hooks/exhaustive-deps */
import { AppLoading } from '@sharelo-lib/ui';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { useAuth } from '../auth-provider';

interface Props {
  children: React.ReactNode;
}

export const GuestOnlyRoutes = [
  '/login',
  '/login-new',
  '/signup',
  '/forgot-password',
  '/reset-password',
];

export const GuestConstraintRoute = ({ children }: Props) => {
  const { loaded, currentUser, authLoading } = useAuth();
  const router = useRouter();

  const checkAndRedirect = () => {
    if (loaded && currentUser) {
      router.push('/');
    }
  };

  useEffect(() => {
    checkAndRedirect();
  }, []);

  useEffect(() => {
    checkAndRedirect();
  }, [currentUser, loaded, router]);

  if (authLoading || !loaded || currentUser) {
    return <AppLoading />;
  }

  return <>{children}</>;
};
