import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopifyOauthInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShopifyOauthInfoQuery = { __typename?: 'RootQueryType', shopifyOauthInfo?: { __typename?: 'ShopifyOauthInfoResult', isInstalled: boolean, shopifyOauthInfo?: { __typename?: 'ShopifyOauthInfo', id: string, shop?: string | null, scope?: string | null } | null } | null };


export const ShopifyOauthInfoDocument = gql`
    query ShopifyOauthInfo {
  shopifyOauthInfo {
    isInstalled
    shopifyOauthInfo {
      id
      shop
      scope
    }
  }
}
    `;

/**
 * __useShopifyOauthInfoQuery__
 *
 * To run a query within a React component, call `useShopifyOauthInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyOauthInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyOauthInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopifyOauthInfoQuery(baseOptions?: Apollo.QueryHookOptions<ShopifyOauthInfoQuery, ShopifyOauthInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopifyOauthInfoQuery, ShopifyOauthInfoQueryVariables>(ShopifyOauthInfoDocument, options);
      }
export function useShopifyOauthInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopifyOauthInfoQuery, ShopifyOauthInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopifyOauthInfoQuery, ShopifyOauthInfoQueryVariables>(ShopifyOauthInfoDocument, options);
        }
export type ShopifyOauthInfoQueryHookResult = ReturnType<typeof useShopifyOauthInfoQuery>;
export type ShopifyOauthInfoLazyQueryHookResult = ReturnType<typeof useShopifyOauthInfoLazyQuery>;
export type ShopifyOauthInfoQueryResult = Apollo.QueryResult<ShopifyOauthInfoQuery, ShopifyOauthInfoQueryVariables>;
export function refetchShopifyOauthInfoQuery(variables?: ShopifyOauthInfoQueryVariables) {
      return { query: ShopifyOauthInfoDocument, variables: variables }
    }