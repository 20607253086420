import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInitiationInvitesMutationVariables = Types.Exact<{
  input: Types.CreateInitiationInvitesInput;
}>;


export type CreateInitiationInvitesMutation = { __typename?: 'RootMutationType', createInitiationInvites?: { __typename?: 'InitiationInvitesResult', initiationInvites?: Array<{ __typename?: 'InitiationInvite', id: string, invitedAt: any, role: Types.InitiationMemberRole, email?: string | null, member?: { __typename?: 'Member', id: string, displayName?: string | null } | null } | null> | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateInitiationInvitesDocument = gql`
    mutation createInitiationInvites($input: CreateInitiationInvitesInput!) {
  createInitiationInvites(input: $input) {
    initiationInvites {
      id
      invitedAt
      role
      email
      member {
        id
        displayName
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CreateInitiationInvitesMutationFn = Apollo.MutationFunction<CreateInitiationInvitesMutation, CreateInitiationInvitesMutationVariables>;

/**
 * __useCreateInitiationInvitesMutation__
 *
 * To run a mutation, you first call `useCreateInitiationInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInitiationInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInitiationInvitesMutation, { data, loading, error }] = useCreateInitiationInvitesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInitiationInvitesMutation(baseOptions?: Apollo.MutationHookOptions<CreateInitiationInvitesMutation, CreateInitiationInvitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInitiationInvitesMutation, CreateInitiationInvitesMutationVariables>(CreateInitiationInvitesDocument, options);
      }
export type CreateInitiationInvitesMutationHookResult = ReturnType<typeof useCreateInitiationInvitesMutation>;
export type CreateInitiationInvitesMutationResult = Apollo.MutationResult<CreateInitiationInvitesMutation>;
export type CreateInitiationInvitesMutationOptions = Apollo.BaseMutationOptions<CreateInitiationInvitesMutation, CreateInitiationInvitesMutationVariables>;