import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { ContactFormBaseFragmentFragmentDoc } from './contact-form-base-fragment.generated';
import { BaseContactFormFieldsFragmentFragmentDoc } from './contact-form-fields-base-fragment.generated';
import { ContactFormConsentFragmentFragmentDoc } from './contact-form-consent-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchContactFormByStoryIdQueryVariables = Types.Exact<{
  storyId: Types.Scalars['ID']['input'];
}>;


export type FetchContactFormByStoryIdQuery = { __typename?: 'RootQueryType', fetchContactFormByStoryId: { __typename: 'ContactForm', id: string, isActive: boolean, canSkip: boolean, prompt: string, showConsent: boolean, formFields: Array<{ __typename: 'AudioField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename: 'EmailField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename: 'LongTextField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename: 'PhoneField', defaultCountryCode?: string | null, id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename: 'ShortTextField', max?: number | null, min?: number | null, id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null } | { __typename: 'VideoField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null }>, consents: Array<{ __typename: 'Consent', id: string, deletedAt?: any | null, currentVersion: { __typename: 'ConsentVersion', id: string, text?: string | null, isRequired: boolean } }> } };


export const FetchContactFormByStoryIdDocument = gql`
    query fetchContactFormByStoryId($storyId: ID!) {
  fetchContactFormByStoryId(storyId: $storyId) {
    __typename
    ...contactFormBaseFragment
    formFields {
      __typename
      ...baseContactFormFieldsFragment
    }
    consents {
      __typename
      ...contactFormConsentFragment
    }
  }
}
    ${ContactFormBaseFragmentFragmentDoc}
${BaseContactFormFieldsFragmentFragmentDoc}
${ContactFormConsentFragmentFragmentDoc}`;

/**
 * __useFetchContactFormByStoryIdQuery__
 *
 * To run a query within a React component, call `useFetchContactFormByStoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactFormByStoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactFormByStoryIdQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useFetchContactFormByStoryIdQuery(baseOptions: Apollo.QueryHookOptions<FetchContactFormByStoryIdQuery, FetchContactFormByStoryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchContactFormByStoryIdQuery, FetchContactFormByStoryIdQueryVariables>(FetchContactFormByStoryIdDocument, options);
      }
export function useFetchContactFormByStoryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchContactFormByStoryIdQuery, FetchContactFormByStoryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchContactFormByStoryIdQuery, FetchContactFormByStoryIdQueryVariables>(FetchContactFormByStoryIdDocument, options);
        }
export type FetchContactFormByStoryIdQueryHookResult = ReturnType<typeof useFetchContactFormByStoryIdQuery>;
export type FetchContactFormByStoryIdLazyQueryHookResult = ReturnType<typeof useFetchContactFormByStoryIdLazyQuery>;
export type FetchContactFormByStoryIdQueryResult = Apollo.QueryResult<FetchContactFormByStoryIdQuery, FetchContactFormByStoryIdQueryVariables>;
export function refetchFetchContactFormByStoryIdQuery(variables: FetchContactFormByStoryIdQueryVariables) {
      return { query: FetchContactFormByStoryIdDocument, variables: variables }
    }