import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MemberInvitationsOnWorkspaceSettingsMembersPageQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
}>;


export type MemberInvitationsOnWorkspaceSettingsMembersPageQuery = { __typename?: 'RootQueryType', memberInvitations?: { __typename?: 'MemberInvitationConnection', totalCount: number, edges?: Array<{ __typename?: 'MemberInvitationEdge', node?: { __typename?: 'MemberInvitation', id: string, email?: string | null, invitedAt?: any | null, isExpired?: boolean | null, role?: string | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const MemberInvitationsOnWorkspaceSettingsMembersPageDocument = gql`
    query memberInvitationsOnWorkspaceSettingsMembersPage($first: Int!) {
  memberInvitations(first: $first) {
    edges {
      node {
        id
        email
        invitedAt
        isExpired
        role
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useMemberInvitationsOnWorkspaceSettingsMembersPageQuery__
 *
 * To run a query within a React component, call `useMemberInvitationsOnWorkspaceSettingsMembersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberInvitationsOnWorkspaceSettingsMembersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberInvitationsOnWorkspaceSettingsMembersPageQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMemberInvitationsOnWorkspaceSettingsMembersPageQuery(baseOptions: Apollo.QueryHookOptions<MemberInvitationsOnWorkspaceSettingsMembersPageQuery, MemberInvitationsOnWorkspaceSettingsMembersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberInvitationsOnWorkspaceSettingsMembersPageQuery, MemberInvitationsOnWorkspaceSettingsMembersPageQueryVariables>(MemberInvitationsOnWorkspaceSettingsMembersPageDocument, options);
      }
export function useMemberInvitationsOnWorkspaceSettingsMembersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberInvitationsOnWorkspaceSettingsMembersPageQuery, MemberInvitationsOnWorkspaceSettingsMembersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberInvitationsOnWorkspaceSettingsMembersPageQuery, MemberInvitationsOnWorkspaceSettingsMembersPageQueryVariables>(MemberInvitationsOnWorkspaceSettingsMembersPageDocument, options);
        }
export type MemberInvitationsOnWorkspaceSettingsMembersPageQueryHookResult = ReturnType<typeof useMemberInvitationsOnWorkspaceSettingsMembersPageQuery>;
export type MemberInvitationsOnWorkspaceSettingsMembersPageLazyQueryHookResult = ReturnType<typeof useMemberInvitationsOnWorkspaceSettingsMembersPageLazyQuery>;
export type MemberInvitationsOnWorkspaceSettingsMembersPageQueryResult = Apollo.QueryResult<MemberInvitationsOnWorkspaceSettingsMembersPageQuery, MemberInvitationsOnWorkspaceSettingsMembersPageQueryVariables>;
export function refetchMemberInvitationsOnWorkspaceSettingsMembersPageQuery(variables: MemberInvitationsOnWorkspaceSettingsMembersPageQueryVariables) {
      return { query: MemberInvitationsOnWorkspaceSettingsMembersPageDocument, variables: variables }
    }