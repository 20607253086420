interface Props {
  fill: string;
  className?: string;
}

export const UntitledUIMail01Icon = ({
  className,
  fill = '#4b5563',
}: Props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <path d="M1.42 7.81l8.16 5.71c.94.66 1.19.81 1.68.93 .47.11.97.11 1.45-.01 .48-.13.73-.28 1.68-.94l8.16-5.72c.45-.32.56-.95.24-1.4 -.32-.46-.95-.57-1.4-.25l-8.17 5.71c-.71.49-.88.59-1.02.63 -.16.03-.33.03-.49 0 -.15-.04-.32-.14-1.02-.64L2.52 6.11c-.46-.32-1.08-.21-1.4.24 -.32.45-.21 1.07.24 1.39Zm5.37 13.18h10.4c2.3 0 2.84-.05 3.61-.44 .75-.39 1.36-1 1.74-1.75 .39-.77.43-1.32.43-3.62v-6.4c0-2.31-.05-2.85-.44-3.62 -.39-.76-1-1.37-1.75-1.75 -.77-.4-1.32-.44-3.62-.44H6.76c-2.31 0-2.85.04-3.62.43 -.76.38-1.37.99-1.75 1.74 -.4.76-.44 1.31-.44 3.61v6.4c0 2.3.04 2.84.43 3.61 .38.75.99 1.36 1.74 1.74 .76.39 1.31.43 3.61.43Zm0-2c-1.9 0-2.36-.04-2.71-.22 -.38-.2-.69-.5-.88-.88 -.19-.36-.22-.81-.22-2.71v-6.4c0-1.9.03-2.36.21-2.71 .19-.38.49-.69.87-.88 .35-.19.8-.22 2.7-.22h10.4c1.89 0 2.35.03 2.7.21 .37.19.68.49.87.87 .18.35.21.8.21 2.7v6.4c0 1.89-.04 2.35-.22 2.7 -.2.37-.5.68-.88.87 -.36.18-.81.21-2.71.21H6.73Z" />
    </svg>
  );
};
