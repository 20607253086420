import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
export type CustomLogicFieldsFragment = { __typename?: 'ChoiceLogic', id: string, operator: Types.LogicOperatorType, conditionals?: Array<{ __typename?: 'ChoiceConditional', id: string, comparison: Types.ChoiceConditionalComparisonType, question?: { __typename?: 'PageQuestion', id: string, title?: string | null, titleColor: string, storyPage?: { __typename?: 'InitiationItem', id: string, title: string, order: number } | null } | null, choice?: { __typename?: 'PageChoice', id: string, order: number, text?: string | null } | null } | null> | null, actions?: Array<{ __typename?: 'ChoiceAction', id: string, type?: Types.ChoiceActionType | null, result?: Types.ChoiceActionResultType | null, value?: number | null, strValue?: string | null, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null } | null> | null };

export const CustomLogicFieldsFragmentDoc = gql`
    fragment CustomLogicFields on ChoiceLogic {
  id
  operator
  conditionals {
    id
    comparison
    question {
      id
      title
      titleColor
      storyPage {
        id
        title
        order
      }
    }
    choice {
      id
      order
      text
    }
  }
  actions {
    id
    type
    result
    value
    strValue
    nextPage {
      id
      title
      type
      order
    }
  }
}
    `;