import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendConfirmationTokenMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type ResendConfirmationTokenMutation = { __typename?: 'RootMutationType', resendConfirmationToken?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const ResendConfirmationTokenDocument = gql`
    mutation resendConfirmationToken($email: String!) {
  resendConfirmationToken(email: $email) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type ResendConfirmationTokenMutationFn = Apollo.MutationFunction<ResendConfirmationTokenMutation, ResendConfirmationTokenMutationVariables>;

/**
 * __useResendConfirmationTokenMutation__
 *
 * To run a mutation, you first call `useResendConfirmationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationTokenMutation, { data, loading, error }] = useResendConfirmationTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendConfirmationTokenMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmationTokenMutation, ResendConfirmationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmationTokenMutation, ResendConfirmationTokenMutationVariables>(ResendConfirmationTokenDocument, options);
      }
export type ResendConfirmationTokenMutationHookResult = ReturnType<typeof useResendConfirmationTokenMutation>;
export type ResendConfirmationTokenMutationResult = Apollo.MutationResult<ResendConfirmationTokenMutation>;
export type ResendConfirmationTokenMutationOptions = Apollo.BaseMutationOptions<ResendConfirmationTokenMutation, ResendConfirmationTokenMutationVariables>;