/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';

import { useAuth } from '../auth-provider';

interface Props {
  children: React.ReactNode;
}

export const AuthConstraintRoute = ({ children }: Props) => {
  const { loaded, currentUser } = useAuth();
  const router = useRouter();

  const checkAndRedirect = useCallback(() => {
    if (loaded && !currentUser) {
      router.replace('/login');
    }
  }, [loaded, currentUser]);

  useEffect(() => {
    checkAndRedirect();
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
