import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateStoryLinkMutationVariables = Types.Exact<{
  input: Types.UpdateStoryLinkInput;
}>;


export type UpdateStoryLinkMutation = { __typename?: 'RootMutationType', updateStoryLink?: { __typename?: 'StoryLinkMutationResult', storyLink?: { __typename?: 'StoryLink', id: string, isLive: boolean, customTitle?: string | null, expiresAt?: any | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateStoryLinkDocument = gql`
    mutation updateStoryLink($input: UpdateStoryLinkInput!) {
  updateStoryLink(input: $input) {
    storyLink {
      id
      isLive
      customTitle
      expiresAt
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateStoryLinkMutationFn = Apollo.MutationFunction<UpdateStoryLinkMutation, UpdateStoryLinkMutationVariables>;

/**
 * __useUpdateStoryLinkMutation__
 *
 * To run a mutation, you first call `useUpdateStoryLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryLinkMutation, { data, loading, error }] = useUpdateStoryLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStoryLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryLinkMutation, UpdateStoryLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryLinkMutation, UpdateStoryLinkMutationVariables>(UpdateStoryLinkDocument, options);
      }
export type UpdateStoryLinkMutationHookResult = ReturnType<typeof useUpdateStoryLinkMutation>;
export type UpdateStoryLinkMutationResult = Apollo.MutationResult<UpdateStoryLinkMutation>;
export type UpdateStoryLinkMutationOptions = Apollo.BaseMutationOptions<UpdateStoryLinkMutation, UpdateStoryLinkMutationVariables>;