import { NextInteractionType, PageChoice } from '@sharelo-lib/graphql-types';
import classnames from 'classnames';
import React from 'react';

import { ChoiceItemMain } from './choice-item-main';
import { PageChoiceType } from './types';

interface Props {
  choice: PageChoice;
  handlePageChange: (pageId: string) => void;
  type?: PageChoiceType;
  editorPreview?: boolean;
  viewType: 'Desktop' | 'Mobile';
}

export const ChoiceItem = ({
  choice,
  handlePageChange,
  type = 'regular',
  viewType,
}: Props) => {
  // const { storyMetadata } = useStoryPlayerState();
  const interceptPageChange = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handlePageChange(choice.nextPage?.id as string);
  };

  const interceptClickLink = () => {
    if (choice.nextPage?.id) {
      handlePageChange(choice.nextPage.id as string);
    }
  };
  const wrapClass = classnames('z-80');

  if (choice.type === NextInteractionType.Page) {
    if (choice.nextPage && choice.nextPage.id) {
      return (
        <div className={wrapClass} role="button" onClick={interceptPageChange}>
          <ChoiceItemMain choice={choice} type={type} viewType={viewType} />
        </div>
      );
    }
    return (
      <div className={wrapClass} role="button" onClick={() => false}>
        <ChoiceItemMain choice={choice} type={type} viewType={viewType} />
      </div>
    );
  }
  return (
    <a
      className={wrapClass}
      href={choice.value as string}
      target="_blank"
      rel="noreferrer"
      onClick={interceptClickLink}
    >
      <ChoiceItemMain choice={choice} type={type} viewType={viewType} />
    </a>
  );
};
