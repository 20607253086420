interface Props {
  className?: string;
}

export const SenjaSettingIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <g>
        <path d="M12 16c2.2 0 4-1.8 4-4 0-2.21-1.8-4-4-4 -2.21 0-4.01 1.79-4.01 4 0 2.2 1.79 4 4 4Zm0-2c-1.11 0-2.01-.9-2.01-2 0-1.11.89-2 2-2 1.1 0 2 .89 2 2 0 1.1-.9 2-2 2Z"></path>
        <path d="M8.37 19.77l.58 1.31c.25.56.66 1.05 1.18 1.38 .52.33 1.12.51 1.75.51 .62 0 1.22-.19 1.75-.52 .52-.34.93-.83 1.18-1.39l.58-1.32c.12-.28.32-.51.58-.66 .26-.16.56-.22.86-.19l1.42.15c.61.06 1.24-.05 1.79-.34 .55-.29 1.01-.72 1.32-1.26 .31-.54.45-1.16.42-1.78 -.04-.63-.25-1.22-.62-1.73l-.85-1.17c-.18-.25-.28-.54-.27-.85 -.01-.31.09-.61.27-.85l.84-1.17c.36-.51.57-1.11.61-1.73 .03-.63-.12-1.24-.43-1.78 -.32-.54-.78-.98-1.33-1.26 -.56-.29-1.18-.4-1.8-.34l-1.44.15c-.3.03-.61-.04-.87-.19s-.47-.38-.59-.66l-.6-1.32c-.26-.57-.67-1.05-1.19-1.39 -.53-.34-1.13-.52-1.76-.52 -.63-.01-1.23.18-1.76.51s-.94.82-1.19 1.38l-.59 1.31c-.13.27-.33.5-.59.65 -.26.14-.57.21-.87.18l-1.44-.16c-.62-.07-1.25.04-1.8.33 -.56.28-1.02.71-1.33 1.25 -.32.53-.46 1.15-.43 1.77 .03.62.24 1.21.61 1.72l.84 1.16c.17.24.27.53.27.84 0 .3-.1.59-.28.84l-.85 1.16c-.37.5-.58 1.1-.62 1.72 -.04.62.11 1.23.42 1.77 .31.53.77.97 1.32 1.25s1.17.39 1.79.33l1.43-.16c.29-.04.6.03.86.18s.46.38.59.65Zm1.82-.82c-.3-.67-.8-1.22-1.42-1.58 -.63-.37-1.36-.52-2.08-.45l-1.44.15c-.24.02-.47-.02-.68-.13 -.21-.11-.39-.28-.51-.48 -.12-.21-.18-.44-.17-.67 .01-.24.09-.46.23-.65l.84-1.17c.42-.59.65-1.3.65-2.02 0-.73-.23-1.44-.66-2.02L4.1 8.76c-.14-.19-.22-.42-.24-.65 -.02-.24.04-.47.16-.67 .11-.21.29-.37.5-.48 .2-.11.44-.15.67-.13l1.43.15c.72.07 1.44-.08 2.07-.45 .62-.37 1.12-.92 1.41-1.58l.58-1.32c.09-.22.25-.4.44-.53s.42-.2.66-.2c.23-.01.46.06.66.19 .19.12.35.31.44.52l.59 1.31c.29.65.78 1.2 1.41 1.57 .63.36 1.35.51 2.07.44l1.43-.16c.23-.03.46.01.67.12 .2.1.38.27.49.47 .11.2.17.43.16.67 -.02.23-.1.45-.24.64l-.85 1.16c-.43.58-.66 1.29-.66 2.01 -.01.72.22 1.43.64 2.01l.84 1.16c.13.18.21.41.23.64 .01.23-.05.46-.17.66 -.12.2-.3.36-.51.47 -.21.1-.45.15-.68.12l-1.43-.16c-.73-.08-1.45.07-2.08.44 -.63.36-1.12.91-1.42 1.56l-.59 1.31c-.1.21-.26.39-.45.52 -.2.12-.43.19-.67.19 -.24 0-.47-.07-.67-.2 -.2-.13-.36-.31-.45-.53l-.59-1.32Z"></path>
      </g>
    </svg>
  );
};
