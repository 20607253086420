import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteChoiceActionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  choiceLogicId: Types.Scalars['ID']['input'];
}>;


export type DeleteChoiceActionMutation = { __typename?: 'RootMutationType', deleteChoiceAction?: { __typename?: 'ChoiceLogicMutationResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const DeleteChoiceActionDocument = gql`
    mutation deleteChoiceAction($id: ID!, $choiceLogicId: ID!) {
  deleteChoiceAction(id: $id, choiceLogicId: $choiceLogicId) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteChoiceActionMutationFn = Apollo.MutationFunction<DeleteChoiceActionMutation, DeleteChoiceActionMutationVariables>;

/**
 * __useDeleteChoiceActionMutation__
 *
 * To run a mutation, you first call `useDeleteChoiceActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChoiceActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChoiceActionMutation, { data, loading, error }] = useDeleteChoiceActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      choiceLogicId: // value for 'choiceLogicId'
 *   },
 * });
 */
export function useDeleteChoiceActionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChoiceActionMutation, DeleteChoiceActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChoiceActionMutation, DeleteChoiceActionMutationVariables>(DeleteChoiceActionDocument, options);
      }
export type DeleteChoiceActionMutationHookResult = ReturnType<typeof useDeleteChoiceActionMutation>;
export type DeleteChoiceActionMutationResult = Apollo.MutationResult<DeleteChoiceActionMutation>;
export type DeleteChoiceActionMutationOptions = Apollo.BaseMutationOptions<DeleteChoiceActionMutation, DeleteChoiceActionMutationVariables>;