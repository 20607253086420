// https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-669083882
// Fix issue with react-beautiful-dnd components nested inside transform
import { useEffect, useRef } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

// Define the type for the render function argument
type DraggableRenderFunc = (
  provided: DraggableProvided,
  snapshot: DraggableStateSnapshot,
) => JSX.Element;

export const useReactBeautifulDndInPortal = () => {
  const self = useRef<{ elt: HTMLDivElement | null }>({ elt: null });

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    self.current.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, []);

  return (render: DraggableRenderFunc) =>
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
      const element: JSX.Element = render(provided, snapshot);
      if (self.current.elt) {
        const style = provided.draggableProps.style as React.CSSProperties;
        if (style && style.position === 'fixed') {
          return ReactDOM.createPortal(element, self.current.elt);
        }
      }
      return element;
    };
};
