import {
  apolloClient,
  checkAuthState,
  logoutUser,
  useViewerOnAuthQuery,
  ViewerOnAuthDocument,
} from '@sharelo-lib/data-access';
import { OnboardingStatus, User } from '@sharelo-lib/graphql-types';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { isLoginNeededRoute } from '../lib/auth-utils';
import { AuthContext } from './auth-context';
import { GuestOnlyRoutes } from './route-constraints';
import { MockAuthProvider } from './testing/mock-auth-provider';

interface MockProps {
  isLoggedIn: boolean;
  currentUser: any;
  loaded: boolean;
  authError: any;
  authLoading: boolean;
  currentRole: string | null;
}

const defaultMockUser: any = {
  id: '6ae753b4-fe46-46d7-8df2-ecc24e34efd6',
  displayName: 'Hiro',
  onboardingStatus: OnboardingStatus.Completed,
};

const defaultMockAuthProps = {
  isCheckingAuthState: false,
  checkedAuthState: true,
  isLoggedIn: true,
  currentUser: defaultMockUser,
  currentRole: 'User',
  authError: null,
  authLoading: false,
  loaded: true,
  refetchCurrentUser: () => {
    return false;
  },
};

interface Props {
  isMock?: boolean;
  mockProps?: MockProps;
  children: React.ReactNode;
}

interface MainProps {
  children: React.ReactNode;
}

const Main = ({ children }: MainProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies(['NEXT_LOCALE']);
  const router = useRouter();
  const [state, setState] = useState<any>({
    isCheckingAuthState: false,
    checkedAuthState: false,
    isLoggedIn: false,
    currentUser: null,
    currentRole: null,
    authError: null,
    loaded: false,
  });

  const afterLoggedIn = (passedViewer?: User) => {
    let viewer: any;
    const dashly = (window as any)?.dashly as any;
    if (passedViewer) {
      viewer = passedViewer;
    } else {
      const { viewer: readViewer } = apolloClient.readQuery({
        query: ViewerOnAuthDocument,
      });
      viewer = readViewer;
    }
    if (viewer.locale) {
      setCookie('NEXT_LOCALE', viewer.locale.toLowerCase());
    }
    if (
      !state.currentUser ||
      state.currentUser.id !== viewer.id ||
      JSON.stringify(state.currentUser) !== JSON.stringify(viewer)
    ) {
      setState({
        ...state,
        checkAuthState: true,
        loaded: true,
        isLoggedIn: true,
        currentUser: viewer,
        currentRole: viewer.role,
      });

      if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
        dashly.onReady(() => {
          dashly.identify([
            {
              op: 'update_or_create',
              key: 'user_id',
              value: viewer?.databaseId,
            },
            {
              op: 'update_or_create',
              key: '$email',
              value: viewer?.email,
            },
            {
              op: 'update_or_create',
              key: '$name',
              value: viewer?.displayName,
            },
          ]);
        });
      }
    }
    if (viewer.onboardingStatus === OnboardingStatus.Completed) {
      if (viewer.tenantCount === 0) {
        router.replace('/workspace/new');
      }
      if (
        router.pathname === '/' ||
        GuestOnlyRoutes.includes(router.pathname)
      ) {
        router.replace('/my-stories');
      } else {
        router.replace(router.pathname, router.asPath, {
          locale: viewer.locale,
        });
      }

      return;
    }
    if (viewer.onboardingStatus === OnboardingStatus.VerifyEmail) {
      router.push('/thankyou');
      return;
    }
    router.push('/onboarding');
  };

  const handleLogout = async () => {
    await logoutUser();
    await apolloClient.cache.reset();
    setState({
      ...state,
      loaded: true,
      isLoggedIn: false,
      currentUser: null,
      currentRole: null,
    });
    router.push('/login');
  };

  const handleGuest = useCallback(() => {
    setState({
      ...state,
      loaded: true,
      isLoggedIn: false,
      currentUser: null,
      currentRole: null,
    });
    setCookie('NEXT_LOCALE', router.locale?.toLowerCase());
    if (isLoginNeededRoute(router)) {
      router.push('/login');
    }
    apolloClient.resetStore();
  }, [router, setCookie, state]);

  const { loading, refetch } = useViewerOnAuthQuery({
    errorPolicy: 'all',
    skip: !state.checkedAuthState,
    onCompleted: ({ viewer }) => {
      if (viewer) {
        afterLoggedIn(viewer as any);
      } else {
        handleGuest();
      }
    },
  });

  const handleRefresh = async () => {
    const result = await refetch();
    if (result.data.viewer) {
      afterLoggedIn(result.data.viewer as any);
    }
  };

  const handleCheckAuthState = useCallback(async () => {
    const result = await checkAuthState();
    if (result) {
      setState({
        ...state,
        isCheckingAuthState: false,
        checkedAuthState: true,
        isLoggedIn: true,
      });
    } else {
      setState({
        ...state,
        isCheckingAuthState: false,
        checkedAuthState: true,
        isLoggedIn: false,
      });
    }
  }, [afterLoggedIn, handleGuest, state]);

  useEffect(() => {
    if (!state.checkedAuthState && !state.isCheckingAuthState) {
      handleCheckAuthState();
      setState({
        ...state,
        isCheckingAuthState: true,
      });
    }
  }, [handleCheckAuthState, state.checkedAuthState]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        authLoading: loading,
        afterLoggedIn,
        logout: handleLogout,
        refetchCurrentUser: handleRefresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function AuthProvider({
  children,
  isMock = false,
  mockProps = defaultMockAuthProps,
}: Props) {
  if (isMock) {
    return <MockAuthProvider {...mockProps}>{children}</MockAuthProvider>;
  }
  return <Main>{children}</Main>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
