interface Props {
  className?: string;
}

export const SenjaRecordIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <g>
        <path d="M12 23c6.07 0 11-4.93 11-11 0-6.08-4.93-11-11-11C5.92 1 1 5.92 1 12c0 6.07 4.92 11 11 11Zm0-2c-4.98 0-9-4.03-9-9 0-4.98 4.02-9 9-9 4.97 0 9 4.02 9 9 0 4.97-4.03 9-9 9Z"></path>
        <path d="M12 16c2.2 0 4-1.8 4-4 0-2.21-1.8-4-4-4 -2.21 0-4 1.79-4 4 0 2.2 1.79 4 4 4Zm0-2c-1.11 0-2-.9-2-2 0-1.11.89-2 2-2 1.1 0 2 .89 2 2 0 1.1-.9 2-2 2Z"></path>
      </g>
    </svg>
  );
};
