import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiationInvitableMembersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  initiationId: Types.Scalars['ID']['input'];
  filter?: Types.InputMaybe<Types.MemberFilter>;
}>;


export type InitiationInvitableMembersQuery = { __typename?: 'RootQueryType', initiationInvitableMembers?: { __typename?: 'MemberConnection', totalCount: number, edges?: Array<{ __typename?: 'MemberEdge', node?: { __typename?: 'Member', id: string, displayName?: string | null, avatarUrl?: string | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const InitiationInvitableMembersDocument = gql`
    query initiationInvitableMembers($first: Int, $initiationId: ID!, $filter: MemberFilter) {
  initiationInvitableMembers(
    first: $first
    initiationId: $initiationId
    filter: $filter
  ) {
    edges {
      node {
        id
        displayName
        avatarUrl
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useInitiationInvitableMembersQuery__
 *
 * To run a query within a React component, call `useInitiationInvitableMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiationInvitableMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiationInvitableMembersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      initiationId: // value for 'initiationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInitiationInvitableMembersQuery(baseOptions: Apollo.QueryHookOptions<InitiationInvitableMembersQuery, InitiationInvitableMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitiationInvitableMembersQuery, InitiationInvitableMembersQueryVariables>(InitiationInvitableMembersDocument, options);
      }
export function useInitiationInvitableMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitiationInvitableMembersQuery, InitiationInvitableMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitiationInvitableMembersQuery, InitiationInvitableMembersQueryVariables>(InitiationInvitableMembersDocument, options);
        }
export type InitiationInvitableMembersQueryHookResult = ReturnType<typeof useInitiationInvitableMembersQuery>;
export type InitiationInvitableMembersLazyQueryHookResult = ReturnType<typeof useInitiationInvitableMembersLazyQuery>;
export type InitiationInvitableMembersQueryResult = Apollo.QueryResult<InitiationInvitableMembersQuery, InitiationInvitableMembersQueryVariables>;
export function refetchInitiationInvitableMembersQuery(variables: InitiationInvitableMembersQueryVariables) {
      return { query: InitiationInvitableMembersDocument, variables: variables }
    }