interface Props {
  fill: string;
  className?: string;
}

export const LoomIcon = ({ fill, className }: Props) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon
          id="Shape"
          fill={fill}
          fillRule="nonzero"
          points="30 13.43 20.8610463 13.43 28.7754082 8.86 27.2059314 6.14 19.2905698 10.71 23.859047 2.795 21.1399533 1.225 16.5704765 9.138 16.5704765 0 13.4295235 0 13.4295235 9.139 8.86004665 1.225 6.13995335 2.795 10.7084305 10.708 2.79506831 6.14 1.2245918 8.86 9.13895368 13.43 0 13.43 0 16.571 9.13795402 16.571 1.2245918 21.141 2.79406864 23.861 10.7094302 19.291 6.13995335 27.206 8.85904698 28.776 13.4295235 20.862 13.4295235 30 16.5704765 30 16.5704765 20.666 21.2239254 28.726 23.7740753 27.254 19.1196268 19.192 27.203932 23.86 28.7744085 21.14 20.8610463 16.57 29.9990003 16.57 29.9990003 13.43 30 13.43"
        ></polygon>
      </g>
    </svg>
  );
};
