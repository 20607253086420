import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { StoryNotificationReceiverOnStoryEditorFragmentDoc } from './story-notification-receiver-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryNotificationReceiversQueryVariables = Types.Exact<{
  storyId: Types.Scalars['ID']['input'];
}>;


export type StoryNotificationReceiversQuery = { __typename?: 'RootQueryType', storyNotificationReceivers: Array<{ __typename?: 'StoryNotificationReceiver', id: string, member: { __typename?: 'Member', id: string, displayName?: string | null, avatarUrl?: string | null } }> };


export const StoryNotificationReceiversDocument = gql`
    query StoryNotificationReceivers($storyId: ID!) {
  storyNotificationReceivers(storyId: $storyId) {
    ...StoryNotificationReceiverOnStoryEditor
  }
}
    ${StoryNotificationReceiverOnStoryEditorFragmentDoc}`;

/**
 * __useStoryNotificationReceiversQuery__
 *
 * To run a query within a React component, call `useStoryNotificationReceiversQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryNotificationReceiversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryNotificationReceiversQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useStoryNotificationReceiversQuery(baseOptions: Apollo.QueryHookOptions<StoryNotificationReceiversQuery, StoryNotificationReceiversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryNotificationReceiversQuery, StoryNotificationReceiversQueryVariables>(StoryNotificationReceiversDocument, options);
      }
export function useStoryNotificationReceiversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryNotificationReceiversQuery, StoryNotificationReceiversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryNotificationReceiversQuery, StoryNotificationReceiversQueryVariables>(StoryNotificationReceiversDocument, options);
        }
export type StoryNotificationReceiversQueryHookResult = ReturnType<typeof useStoryNotificationReceiversQuery>;
export type StoryNotificationReceiversLazyQueryHookResult = ReturnType<typeof useStoryNotificationReceiversLazyQuery>;
export type StoryNotificationReceiversQueryResult = Apollo.QueryResult<StoryNotificationReceiversQuery, StoryNotificationReceiversQueryVariables>;
export function refetchStoryNotificationReceiversQuery(variables: StoryNotificationReceiversQueryVariables) {
      return { query: StoryNotificationReceiversDocument, variables: variables }
    }