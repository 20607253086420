interface Props {
  fill: string;
  className?: string;
}

export const CanvaIcon = ({ fill, className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      className={className}
      viewBox="0 0 70 70"
    >
      <circle cx="35" cy="35" r="35" fill={fill}></circle>
      <path
        fill="#FFF"
        d="M58.4 36c-.4 0-1.1-.1-1.1-.5s.9-.2.8-1.1c-.1-.6-.7-.8-1.3-.8-2 0-3.3 2.6-3 4.7.1.8.5 1.7 1.3 1.7.6 0 1.5-.9 1.9-2.2.2-.8 1-1.5 1.7-1.5.3 0 .5.1.6.5 0 .2 0 .6-.4 1.9-.5.9-.5 1.4-.4 1.8.1.8.5 1.4.9 1.7l.2.3c0 .2-.1.4-.4.4l-.3-.1c-1.5-.6-2.1-1.6-2.3-2.6-.6.7-1.3 1.1-2.1 1.1-1.3 0-2.6-1.2-2.8-2.7 0-.6 0-1.3.2-1.9-.6.4-1.2.6-1.8.6h-.5a13.3 13.3 0 0 1-3.7 3.8l-1.1.3c-.2 0-.5-.1-.6-.3a5.3 5.3 0 0 1-.6-2 7 7 0 0 1-4.2 2.4c-1 0-1.6-.6-1.7-1.6v-1.1c.3-2.2 1.1-3.5 1.1-3.9l-.2-.2c-.7 0-3 2.4-3.4 4l-.3 1.3c-.2.9-1.1 1.5-1.7 1.5-.3 0-.5-.1-.6-.5v-.4l.1-.7a7.4 7.4 0 0 1-3.1 1.5c-.9 0-1.4-.5-1.5-1.2-.6.8-1.3 1.2-2.2 1.2a2.9 2.9 0 0 1-2.5-1.7c-.7.8-1.5 1.6-2.5 2.2a9 9 0 0 1-4.9 1.6 6 6 0 0 1-4-1.7 6.7 6.7 0 0 1-2-4.4 15.7 15.7 0 0 1 7-14 7 7 0 0 1 3.3-1 4 4 0 0 1 4.1 3.5c.2 1.7-.5 3.2-2.4 4.1-1 .5-1.4.5-1.6.2 0-.2 0-.4.2-.6 1.8-1.5 1.8-2.7 1.6-4.4 0-1.1-.9-1.8-1.7-1.8-3.5 0-8.5 7.8-7.8 13.5.3 2.2 1.6 4.8 4.4 4.8 1 0 2-.3 2.8-.7a10.5 10.5 0 0 0 3.2-2.3c-.2-2.6 2-6 5.4-6 1.5 0 2.7.6 2.8 1.7.2 1.5-1 1.7-1.5 1.7-.4 0-1-.1-1-.5-.2-.4.8-.2.7-1.1 0-.6-.7-.8-1.3-.8-2 0-3.3 2.9-3 4.7.1.8.5 1.6 1.3 1.6.6 0 1.5-.9 1.8-2.2.2-.9 1-1.5 1.7-1.5.3 0 .5.1.6.5v.4c0 .4-.4 1.6-.3 1.9 0 .2.1.5.5.5.3 0 1.3-.5 2.3-1.3l.7-4c.1-.7.4-1.4 1.8-1.4.3 0 .5.1.6.5v.4l-.4 1.8c1.3-1.7 3.2-2.9 4.4-2.9.5 0 1 .3 1 .8 0 .3 0 .8-.3 1.3-.4 1.1-.9 2.8-1.2 4.3 0 .4.1.8.6.8s2-.6 3.2-2.2v-.6c0-1.2.1-2.2.3-2.9.2-.8 1.2-1.5 1.8-1.5.3 0 .6.2.6.5v.4c-.5 1.3-.8 2.5-.8 3.7 0 .7.1 1.7.3 2.3l.2.2c.2 0 1.6-1.3 2.6-3a3.2 3.2 0 0 1-1.4-2.8c0-2.1 1.3-3.2 2.5-3.2 1 0 1.8.7 1.8 2.1a6 6 0 0 1-.8 2.8h.3c.8 0 1.3-.3 1.7-.7a.8.8 0 0 1 .4-.3 5.3 5.3 0 0 1 4.1-2.2c1.4 0 2.7.6 2.8 1.7.2 1.5-1.1 1.8-1.5 1.8z"
      ></path>
      <path
        fill={fill}
        d="M48.8 32c-.3 0-.5.5-.5 1 0 .8.4 1.7.9 2.2a5 5 0 0 0 .3-1.7c0-1-.4-1.5-.7-1.5"
      ></path>
    </svg>
  );
};
