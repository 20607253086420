import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteChoiceConditionalMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  choiceLogicId: Types.Scalars['ID']['input'];
}>;


export type DeleteChoiceConditionalMutation = { __typename?: 'RootMutationType', deleteChoiceConditional?: { __typename?: 'ChoiceLogicMutationResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const DeleteChoiceConditionalDocument = gql`
    mutation deleteChoiceConditional($id: ID!, $choiceLogicId: ID!) {
  deleteChoiceConditional(id: $id, choiceLogicId: $choiceLogicId) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteChoiceConditionalMutationFn = Apollo.MutationFunction<DeleteChoiceConditionalMutation, DeleteChoiceConditionalMutationVariables>;

/**
 * __useDeleteChoiceConditionalMutation__
 *
 * To run a mutation, you first call `useDeleteChoiceConditionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChoiceConditionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChoiceConditionalMutation, { data, loading, error }] = useDeleteChoiceConditionalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      choiceLogicId: // value for 'choiceLogicId'
 *   },
 * });
 */
export function useDeleteChoiceConditionalMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChoiceConditionalMutation, DeleteChoiceConditionalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChoiceConditionalMutation, DeleteChoiceConditionalMutationVariables>(DeleteChoiceConditionalDocument, options);
      }
export type DeleteChoiceConditionalMutationHookResult = ReturnType<typeof useDeleteChoiceConditionalMutation>;
export type DeleteChoiceConditionalMutationResult = Apollo.MutationResult<DeleteChoiceConditionalMutation>;
export type DeleteChoiceConditionalMutationOptions = Apollo.BaseMutationOptions<DeleteChoiceConditionalMutation, DeleteChoiceConditionalMutationVariables>;