import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivePlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActivePlansQuery = { __typename?: 'RootQueryType', activePlans?: Array<{ __typename?: 'Plan', id?: string | null, stripeId?: string | null, isTrial?: boolean | null, isYearly?: boolean | null, slug?: string | null, name?: string | null, nameI18nKey?: string | null, initialMembers?: number | null, initialPlays?: number | null, initialStorage?: number | null } | null> | null };


export const ActivePlansDocument = gql`
    query activePlans {
  activePlans {
    id
    stripeId
    isTrial
    isYearly
    slug
    name
    nameI18nKey
    initialMembers
    initialPlays
    initialStorage
  }
}
    `;

/**
 * __useActivePlansQuery__
 *
 * To run a query within a React component, call `useActivePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivePlansQuery(baseOptions?: Apollo.QueryHookOptions<ActivePlansQuery, ActivePlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivePlansQuery, ActivePlansQueryVariables>(ActivePlansDocument, options);
      }
export function useActivePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivePlansQuery, ActivePlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivePlansQuery, ActivePlansQueryVariables>(ActivePlansDocument, options);
        }
export type ActivePlansQueryHookResult = ReturnType<typeof useActivePlansQuery>;
export type ActivePlansLazyQueryHookResult = ReturnType<typeof useActivePlansLazyQuery>;
export type ActivePlansQueryResult = Apollo.QueryResult<ActivePlansQuery, ActivePlansQueryVariables>;
export function refetchActivePlansQuery(variables?: ActivePlansQueryVariables) {
      return { query: ActivePlansDocument, variables: variables }
    }