import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopifyProductsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ShopifyProductsQuery = { __typename?: 'RootQueryType', shopifyProducts?: { __typename?: 'ShopifyProductConnection', edges?: Array<{ __typename?: 'ShopifyProductEdge', node?: { __typename?: 'ShopifyProduct', id: string, shopifyId?: string | null, title?: string | null, handle?: string | null, onlineStoreUrl?: string | null, productType: string, vendor: string, createdAt?: string | null, updatedAt: string, publishedAt?: string | null, tags: Array<string>, featuredImage?: { __typename?: 'ShopifyProductFeatureImage', id: string, url?: string | null, height?: number | null, width?: number | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const ShopifyProductsDocument = gql`
    query ShopifyProducts($first: Int, $last: Int, $after: String, $before: String) {
  shopifyProducts(first: $first, last: $last, after: $after, before: $before) {
    edges {
      node {
        id
        shopifyId
        title
        handle
        onlineStoreUrl
        productType
        vendor
        createdAt
        updatedAt
        publishedAt
        tags
        featuredImage {
          id
          url
          height
          width
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useShopifyProductsQuery__
 *
 * To run a query within a React component, call `useShopifyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyProductsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useShopifyProductsQuery(baseOptions?: Apollo.QueryHookOptions<ShopifyProductsQuery, ShopifyProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopifyProductsQuery, ShopifyProductsQueryVariables>(ShopifyProductsDocument, options);
      }
export function useShopifyProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopifyProductsQuery, ShopifyProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopifyProductsQuery, ShopifyProductsQueryVariables>(ShopifyProductsDocument, options);
        }
export type ShopifyProductsQueryHookResult = ReturnType<typeof useShopifyProductsQuery>;
export type ShopifyProductsLazyQueryHookResult = ReturnType<typeof useShopifyProductsLazyQuery>;
export type ShopifyProductsQueryResult = Apollo.QueryResult<ShopifyProductsQuery, ShopifyProductsQueryVariables>;
export function refetchShopifyProductsQuery(variables?: ShopifyProductsQueryVariables) {
      return { query: ShopifyProductsDocument, variables: variables }
    }