import Head from 'next/head';
import React from 'react';

interface Props {
  url: string;
  locale: string;
  type: string;
  title: string;
  desc: string;
  image: string;
}

const Facebook = ({ url, type, title, desc, image, locale }: Props) => (
  <Head>
    <meta key="og-locale" property="og:locale" content={locale} />
    <meta key="og-url" property="og:url" content={url} />
    <meta key="og-type" property="og:type" content={type} />
    <meta key="og-title" property="og:title" content={title} />
    <meta key="og-des" property="og:description" content={desc} />
    <meta key="og-image" property="og:image" content={image} />
    <meta key="og-image-alt" property="og:image:alt" content={desc} />
  </Head>
);

export default Facebook;
