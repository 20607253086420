import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DuplicateInitiationItemMutationVariables = Types.Exact<{
  input: Types.DuplicateInitiationItemInput;
}>;


export type DuplicateInitiationItemMutation = { __typename?: 'RootMutationType', duplicateInitiationItem?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const DuplicateInitiationItemDocument = gql`
    mutation duplicateInitiationItem($input: DuplicateInitiationItemInput!) {
  duplicateInitiationItem(input: $input) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DuplicateInitiationItemMutationFn = Apollo.MutationFunction<DuplicateInitiationItemMutation, DuplicateInitiationItemMutationVariables>;

/**
 * __useDuplicateInitiationItemMutation__
 *
 * To run a mutation, you first call `useDuplicateInitiationItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateInitiationItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateInitiationItemMutation, { data, loading, error }] = useDuplicateInitiationItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateInitiationItemMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateInitiationItemMutation, DuplicateInitiationItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateInitiationItemMutation, DuplicateInitiationItemMutationVariables>(DuplicateInitiationItemDocument, options);
      }
export type DuplicateInitiationItemMutationHookResult = ReturnType<typeof useDuplicateInitiationItemMutation>;
export type DuplicateInitiationItemMutationResult = Apollo.MutationResult<DuplicateInitiationItemMutation>;
export type DuplicateInitiationItemMutationOptions = Apollo.BaseMutationOptions<DuplicateInitiationItemMutation, DuplicateInitiationItemMutationVariables>;