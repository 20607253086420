import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BrandSettingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BrandSettingQuery = { __typename?: 'RootQueryType', brandSetting?: { __typename?: 'BrandSetting', id: string, primaryColor?: string | null, logoUrl?: string | null, logoForDarkBgUrl?: string | null, symbolUrl?: string | null, symbolForDarkBgUrl?: string | null } | null };


export const BrandSettingDocument = gql`
    query brandSetting {
  brandSetting {
    id
    primaryColor
    logoUrl
    logoForDarkBgUrl
    symbolUrl
    symbolForDarkBgUrl
  }
}
    `;

/**
 * __useBrandSettingQuery__
 *
 * To run a query within a React component, call `useBrandSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandSettingQuery(baseOptions?: Apollo.QueryHookOptions<BrandSettingQuery, BrandSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandSettingQuery, BrandSettingQueryVariables>(BrandSettingDocument, options);
      }
export function useBrandSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandSettingQuery, BrandSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandSettingQuery, BrandSettingQueryVariables>(BrandSettingDocument, options);
        }
export type BrandSettingQueryHookResult = ReturnType<typeof useBrandSettingQuery>;
export type BrandSettingLazyQueryHookResult = ReturnType<typeof useBrandSettingLazyQuery>;
export type BrandSettingQueryResult = Apollo.QueryResult<BrandSettingQuery, BrandSettingQueryVariables>;
export function refetchBrandSettingQuery(variables?: BrandSettingQueryVariables) {
      return { query: BrandSettingDocument, variables: variables }
    }