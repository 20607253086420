import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadTenantIconMutationVariables = Types.Exact<{
  input: Types.UploadTenantIconInput;
}>;


export type UploadTenantIconMutation = { __typename?: 'RootMutationType', uploadTenantIcon?: { __typename?: 'TenantResult', tenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, description?: string | null, iconUrl?: string | null, isPersonal?: boolean | null, features: Array<Types.PlanFeature> } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UploadTenantIconDocument = gql`
    mutation uploadTenantIcon($input: UploadTenantIconInput!) {
  uploadTenantIcon(input: $input) {
    tenant {
      id
      databaseId
      commerceActivated
      storyCount
      name
      description
      iconUrl
      isPersonal
      features
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UploadTenantIconMutationFn = Apollo.MutationFunction<UploadTenantIconMutation, UploadTenantIconMutationVariables>;

/**
 * __useUploadTenantIconMutation__
 *
 * To run a mutation, you first call `useUploadTenantIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTenantIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTenantIconMutation, { data, loading, error }] = useUploadTenantIconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadTenantIconMutation(baseOptions?: Apollo.MutationHookOptions<UploadTenantIconMutation, UploadTenantIconMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadTenantIconMutation, UploadTenantIconMutationVariables>(UploadTenantIconDocument, options);
      }
export type UploadTenantIconMutationHookResult = ReturnType<typeof useUploadTenantIconMutation>;
export type UploadTenantIconMutationResult = Apollo.MutationResult<UploadTenantIconMutation>;
export type UploadTenantIconMutationOptions = Apollo.BaseMutationOptions<UploadTenantIconMutation, UploadTenantIconMutationVariables>;