import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePageChoiceMutationVariables = Types.Exact<{
  input: Types.UpdatePageChoiceInput;
}>;


export type UpdatePageChoiceMutation = { __typename?: 'RootMutationType', updatePageChoice?: { __typename?: 'PageChoiceResult', pageChoice?: { __typename?: 'PageChoice', id: string, type?: Types.NextInteractionType | null, hasNext?: boolean | null, text?: string | null, value?: string | null, order: number, middleInteraction: { __typename?: 'MiddleInteraction', id: string, type: Types.MiddleInteractionType, question?: string | null, embed?: { __typename?: 'Embed', id: string, linkUrl: string, oembedResult?: any | null, status: Types.EmbedStatus, reason?: Types.EmbedFailureReason | null } | null }, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdatePageChoiceDocument = gql`
    mutation updatePageChoice($input: UpdatePageChoiceInput!) {
  updatePageChoice(input: $input) {
    pageChoice {
      id
      type
      hasNext
      text
      value
      order
      middleInteraction {
        id
        type
        question
        embed {
          id
          linkUrl
          oembedResult
          status
          reason
        }
      }
      nextPage {
        id
        title
        type
        order
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdatePageChoiceMutationFn = Apollo.MutationFunction<UpdatePageChoiceMutation, UpdatePageChoiceMutationVariables>;

/**
 * __useUpdatePageChoiceMutation__
 *
 * To run a mutation, you first call `useUpdatePageChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageChoiceMutation, { data, loading, error }] = useUpdatePageChoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePageChoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageChoiceMutation, UpdatePageChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageChoiceMutation, UpdatePageChoiceMutationVariables>(UpdatePageChoiceDocument, options);
      }
export type UpdatePageChoiceMutationHookResult = ReturnType<typeof useUpdatePageChoiceMutation>;
export type UpdatePageChoiceMutationResult = Apollo.MutationResult<UpdatePageChoiceMutation>;
export type UpdatePageChoiceMutationOptions = Apollo.BaseMutationOptions<UpdatePageChoiceMutation, UpdatePageChoiceMutationVariables>;