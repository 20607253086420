import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveTenantOnWorkspaceSettingsBasicsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveTenantOnWorkspaceSettingsBasicsQuery = { __typename?: 'RootQueryType', activeTenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, description?: string | null, iconUrl?: string | null, isPersonal?: boolean | null, features: Array<Types.PlanFeature> } | null };

export type ActiveTenantOnWorkspaceSettingsBillingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveTenantOnWorkspaceSettingsBillingQuery = { __typename?: 'RootQueryType', activeTenant?: { __typename?: 'Tenant', storageUsage?: number | null, memberCount?: number | null, monthlyPlays?: number | null, monthlyImpressions?: number | null, maxSeats?: number | null, maxStorage?: number | null, maxMonthlyPlays?: number | null, maxMonthlyImpressions?: number | null, maxLifetimePlays?: number | null, canUseTrial?: boolean | null, canUseCoupon?: boolean | null, features: Array<Types.PlanFeature>, id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, description?: string | null, iconUrl?: string | null, isPersonal?: boolean | null, plan?: { __typename?: 'Plan', id?: string | null, isTrial?: boolean | null, isActive?: boolean | null, slug?: string | null, name?: string | null, nameI18nKey?: string | null, initialMembers?: number | null, initialPlays?: number | null, initialStorage?: number | null, maxLifetimePlays?: number | null, members?: number | null, plays?: number | null, storage?: number | null } | null } | null };

export type ActiveTenantBaseFragment = { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, description?: string | null, iconUrl?: string | null, isPersonal?: boolean | null, features: Array<Types.PlanFeature> };

export const ActiveTenantBaseFragmentDoc = gql`
    fragment activeTenantBase on Tenant {
  id
  databaseId
  commerceActivated
  storyCount
  name
  description
  iconUrl
  isPersonal
  features
}
    `;
export const ActiveTenantOnWorkspaceSettingsBasicsDocument = gql`
    query activeTenantOnWorkspaceSettingsBasics {
  activeTenant {
    ...activeTenantBase
  }
}
    ${ActiveTenantBaseFragmentDoc}`;

/**
 * __useActiveTenantOnWorkspaceSettingsBasicsQuery__
 *
 * To run a query within a React component, call `useActiveTenantOnWorkspaceSettingsBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveTenantOnWorkspaceSettingsBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveTenantOnWorkspaceSettingsBasicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveTenantOnWorkspaceSettingsBasicsQuery(baseOptions?: Apollo.QueryHookOptions<ActiveTenantOnWorkspaceSettingsBasicsQuery, ActiveTenantOnWorkspaceSettingsBasicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveTenantOnWorkspaceSettingsBasicsQuery, ActiveTenantOnWorkspaceSettingsBasicsQueryVariables>(ActiveTenantOnWorkspaceSettingsBasicsDocument, options);
      }
export function useActiveTenantOnWorkspaceSettingsBasicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveTenantOnWorkspaceSettingsBasicsQuery, ActiveTenantOnWorkspaceSettingsBasicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveTenantOnWorkspaceSettingsBasicsQuery, ActiveTenantOnWorkspaceSettingsBasicsQueryVariables>(ActiveTenantOnWorkspaceSettingsBasicsDocument, options);
        }
export type ActiveTenantOnWorkspaceSettingsBasicsQueryHookResult = ReturnType<typeof useActiveTenantOnWorkspaceSettingsBasicsQuery>;
export type ActiveTenantOnWorkspaceSettingsBasicsLazyQueryHookResult = ReturnType<typeof useActiveTenantOnWorkspaceSettingsBasicsLazyQuery>;
export type ActiveTenantOnWorkspaceSettingsBasicsQueryResult = Apollo.QueryResult<ActiveTenantOnWorkspaceSettingsBasicsQuery, ActiveTenantOnWorkspaceSettingsBasicsQueryVariables>;
export function refetchActiveTenantOnWorkspaceSettingsBasicsQuery(variables?: ActiveTenantOnWorkspaceSettingsBasicsQueryVariables) {
      return { query: ActiveTenantOnWorkspaceSettingsBasicsDocument, variables: variables }
    }
export const ActiveTenantOnWorkspaceSettingsBillingDocument = gql`
    query activeTenantOnWorkspaceSettingsBilling {
  activeTenant {
    ...activeTenantBase
    storageUsage
    memberCount
    monthlyPlays
    monthlyImpressions
    maxSeats
    maxStorage
    maxMonthlyPlays
    maxMonthlyImpressions
    maxLifetimePlays
    canUseTrial
    canUseCoupon
    plan {
      id
      isTrial
      isActive
      slug
      name
      nameI18nKey
      initialMembers
      initialPlays
      initialStorage
      maxLifetimePlays
      members
      plays
      storage
    }
    features
  }
}
    ${ActiveTenantBaseFragmentDoc}`;

/**
 * __useActiveTenantOnWorkspaceSettingsBillingQuery__
 *
 * To run a query within a React component, call `useActiveTenantOnWorkspaceSettingsBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveTenantOnWorkspaceSettingsBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveTenantOnWorkspaceSettingsBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveTenantOnWorkspaceSettingsBillingQuery(baseOptions?: Apollo.QueryHookOptions<ActiveTenantOnWorkspaceSettingsBillingQuery, ActiveTenantOnWorkspaceSettingsBillingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveTenantOnWorkspaceSettingsBillingQuery, ActiveTenantOnWorkspaceSettingsBillingQueryVariables>(ActiveTenantOnWorkspaceSettingsBillingDocument, options);
      }
export function useActiveTenantOnWorkspaceSettingsBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveTenantOnWorkspaceSettingsBillingQuery, ActiveTenantOnWorkspaceSettingsBillingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveTenantOnWorkspaceSettingsBillingQuery, ActiveTenantOnWorkspaceSettingsBillingQueryVariables>(ActiveTenantOnWorkspaceSettingsBillingDocument, options);
        }
export type ActiveTenantOnWorkspaceSettingsBillingQueryHookResult = ReturnType<typeof useActiveTenantOnWorkspaceSettingsBillingQuery>;
export type ActiveTenantOnWorkspaceSettingsBillingLazyQueryHookResult = ReturnType<typeof useActiveTenantOnWorkspaceSettingsBillingLazyQuery>;
export type ActiveTenantOnWorkspaceSettingsBillingQueryResult = Apollo.QueryResult<ActiveTenantOnWorkspaceSettingsBillingQuery, ActiveTenantOnWorkspaceSettingsBillingQueryVariables>;
export function refetchActiveTenantOnWorkspaceSettingsBillingQuery(variables?: ActiveTenantOnWorkspaceSettingsBillingQueryVariables) {
      return { query: ActiveTenantOnWorkspaceSettingsBillingDocument, variables: variables }
    }