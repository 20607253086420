import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
export type HotspotBaseFragment = { __typename?: 'Hotspot', id: string, x: number, y: number, text: string, textColor: string, bgColor: string, alwaysShowText: boolean };

export const HotspotBaseFragmentDoc = gql`
    fragment HotspotBase on Hotspot {
  id
  x
  y
  text
  textColor
  bgColor
  alwaysShowText
}
    `;