import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadBrandLogoForDarkBgMutationVariables = Types.Exact<{
  input: Types.UploadBrandLogoForDarkBgInput;
}>;


export type UploadBrandLogoForDarkBgMutation = { __typename?: 'RootMutationType', uploadBrandLogoForDarkBg?: { __typename?: 'BrandSettingResult', brandSetting?: { __typename?: 'BrandSetting', id: string, primaryColor?: string | null, logoUrl?: string | null, logoForDarkBgUrl?: string | null, symbolUrl?: string | null, symbolForDarkBgUrl?: string | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UploadBrandLogoForDarkBgDocument = gql`
    mutation uploadBrandLogoForDarkBg($input: UploadBrandLogoForDarkBgInput!) {
  uploadBrandLogoForDarkBg(input: $input) {
    brandSetting {
      id
      primaryColor
      logoUrl
      logoForDarkBgUrl
      symbolUrl
      symbolForDarkBgUrl
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UploadBrandLogoForDarkBgMutationFn = Apollo.MutationFunction<UploadBrandLogoForDarkBgMutation, UploadBrandLogoForDarkBgMutationVariables>;

/**
 * __useUploadBrandLogoForDarkBgMutation__
 *
 * To run a mutation, you first call `useUploadBrandLogoForDarkBgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBrandLogoForDarkBgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBrandLogoForDarkBgMutation, { data, loading, error }] = useUploadBrandLogoForDarkBgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBrandLogoForDarkBgMutation(baseOptions?: Apollo.MutationHookOptions<UploadBrandLogoForDarkBgMutation, UploadBrandLogoForDarkBgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBrandLogoForDarkBgMutation, UploadBrandLogoForDarkBgMutationVariables>(UploadBrandLogoForDarkBgDocument, options);
      }
export type UploadBrandLogoForDarkBgMutationHookResult = ReturnType<typeof useUploadBrandLogoForDarkBgMutation>;
export type UploadBrandLogoForDarkBgMutationResult = Apollo.MutationResult<UploadBrandLogoForDarkBgMutation>;
export type UploadBrandLogoForDarkBgMutationOptions = Apollo.BaseMutationOptions<UploadBrandLogoForDarkBgMutation, UploadBrandLogoForDarkBgMutationVariables>;