import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DefaultStoryNotificationSettingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DefaultStoryNotificationSettingQuery = { __typename?: 'RootQueryType', defaultStoryNotificationSetting?: { __typename?: 'StoryNotificationSetting', id: string, isDefault: boolean, includeAnonymous: boolean, playEmail: boolean, collectLeadEmail: boolean, reachEndEmail: boolean, responseEmail: boolean } | null };


export const DefaultStoryNotificationSettingDocument = gql`
    query defaultStoryNotificationSetting {
  defaultStoryNotificationSetting {
    id
    isDefault
    includeAnonymous
    playEmail
    collectLeadEmail
    reachEndEmail
    responseEmail
  }
}
    `;

/**
 * __useDefaultStoryNotificationSettingQuery__
 *
 * To run a query within a React component, call `useDefaultStoryNotificationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultStoryNotificationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultStoryNotificationSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultStoryNotificationSettingQuery(baseOptions?: Apollo.QueryHookOptions<DefaultStoryNotificationSettingQuery, DefaultStoryNotificationSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultStoryNotificationSettingQuery, DefaultStoryNotificationSettingQueryVariables>(DefaultStoryNotificationSettingDocument, options);
      }
export function useDefaultStoryNotificationSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultStoryNotificationSettingQuery, DefaultStoryNotificationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultStoryNotificationSettingQuery, DefaultStoryNotificationSettingQueryVariables>(DefaultStoryNotificationSettingDocument, options);
        }
export type DefaultStoryNotificationSettingQueryHookResult = ReturnType<typeof useDefaultStoryNotificationSettingQuery>;
export type DefaultStoryNotificationSettingLazyQueryHookResult = ReturnType<typeof useDefaultStoryNotificationSettingLazyQuery>;
export type DefaultStoryNotificationSettingQueryResult = Apollo.QueryResult<DefaultStoryNotificationSettingQuery, DefaultStoryNotificationSettingQueryVariables>;
export function refetchDefaultStoryNotificationSettingQuery(variables?: DefaultStoryNotificationSettingQueryVariables) {
      return { query: DefaultStoryNotificationSettingDocument, variables: variables }
    }