import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetFileUploadToContentMutationVariables = Types.Exact<{
  input: Types.SetFileUploadToContentInput;
}>;


export type SetFileUploadToContentMutation = { __typename?: 'RootMutationType', setFileUploadToContent?: { __typename?: 'ContentResult', content?: { __typename?: 'Content', id: string, type: Types.ContentType, groupType: Types.ContentGroupType, body?: any | null, label?: string | null, plainDescription?: string | null, embedLink?: string | null, providerName?: string | null, videoContain?: boolean | null, file?: { __typename?: 'FileUpload', id: string, name?: string | null, size?: number | null, pageCount?: number | null, mimeType?: string | null, fileUrl?: string | null, insertedAt?: any | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const SetFileUploadToContentDocument = gql`
    mutation setFileUploadToContent($input: SetFileUploadToContentInput!) {
  setFileUploadToContent(input: $input) {
    content {
      id
      type
      groupType
      body
      label
      plainDescription
      embedLink
      providerName
      videoContain
      file {
        id
        name
        size
        pageCount
        mimeType
        fileUrl
        insertedAt
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type SetFileUploadToContentMutationFn = Apollo.MutationFunction<SetFileUploadToContentMutation, SetFileUploadToContentMutationVariables>;

/**
 * __useSetFileUploadToContentMutation__
 *
 * To run a mutation, you first call `useSetFileUploadToContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFileUploadToContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFileUploadToContentMutation, { data, loading, error }] = useSetFileUploadToContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFileUploadToContentMutation(baseOptions?: Apollo.MutationHookOptions<SetFileUploadToContentMutation, SetFileUploadToContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFileUploadToContentMutation, SetFileUploadToContentMutationVariables>(SetFileUploadToContentDocument, options);
      }
export type SetFileUploadToContentMutationHookResult = ReturnType<typeof useSetFileUploadToContentMutation>;
export type SetFileUploadToContentMutationResult = Apollo.MutationResult<SetFileUploadToContentMutation>;
export type SetFileUploadToContentMutationOptions = Apollo.BaseMutationOptions<SetFileUploadToContentMutation, SetFileUploadToContentMutationVariables>;