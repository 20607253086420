import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
export type BaseContactFormFieldsFragment_AudioField_Fragment = { __typename?: 'AudioField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null };

export type BaseContactFormFieldsFragment_EmailField_Fragment = { __typename?: 'EmailField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null };

export type BaseContactFormFieldsFragment_LongTextField_Fragment = { __typename?: 'LongTextField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null };

export type BaseContactFormFieldsFragment_PhoneField_Fragment = { __typename?: 'PhoneField', defaultCountryCode?: string | null, id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null };

export type BaseContactFormFieldsFragment_ShortTextField_Fragment = { __typename?: 'ShortTextField', max?: number | null, min?: number | null, id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null };

export type BaseContactFormFieldsFragment_VideoField_Fragment = { __typename?: 'VideoField', id: string, label: string, key?: string | null, i18nKey?: string | null, isCustomField: boolean, isRequired: boolean, isHidden: boolean, contactProperty?: string | null };

export type BaseContactFormFieldsFragmentFragment = BaseContactFormFieldsFragment_AudioField_Fragment | BaseContactFormFieldsFragment_EmailField_Fragment | BaseContactFormFieldsFragment_LongTextField_Fragment | BaseContactFormFieldsFragment_PhoneField_Fragment | BaseContactFormFieldsFragment_ShortTextField_Fragment | BaseContactFormFieldsFragment_VideoField_Fragment;

export const BaseContactFormFieldsFragmentFragmentDoc = gql`
    fragment baseContactFormFieldsFragment on FormField {
  id
  label
  key
  i18nKey
  isCustomField
  isRequired
  isHidden
  contactProperty
  ... on ShortTextField {
    max
    min
  }
  ... on PhoneField {
    defaultCountryCode
  }
}
    `;