import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
export type ContactFormBaseFragmentFragment = { __typename?: 'ContactForm', id: string, isActive: boolean, canSkip: boolean, prompt: string, showConsent: boolean };

export const ContactFormBaseFragmentFragmentDoc = gql`
    fragment contactFormBaseFragment on ContactForm {
  id
  isActive
  canSkip
  prompt
  showConsent
}
    `;