import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyInitiationsOnMyStoriesPageQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first: Types.Scalars['Int']['input'];
}>;


export type MyInitiationsOnMyStoriesPageQuery = { __typename?: 'RootQueryType', myInitiations?: { __typename?: 'InitiationConnection', totalCount: number, edges?: Array<{ __typename?: 'InitiationEdge', node?: { __typename?: 'Initiation', id: string, sid: string, title: string, thumbUrl?: string | null, updatedAt: any, setting: { __typename?: 'InitiationSetting', id: string, locale: Types.StoryLocale, isInteractive: boolean }, channel?: { __typename?: 'Channel', id: string, title: string } | null, owner?: { __typename?: 'User', id: string, displayName?: string | null, avatarUrl?: string | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const MyInitiationsOnMyStoriesPageDocument = gql`
    query myInitiationsOnMyStoriesPage($after: String, $before: String, $first: Int!) {
  myInitiations(after: $after, before: $before, first: $first) {
    edges {
      node {
        id
        sid
        title
        thumbUrl
        setting {
          id
          locale
          isInteractive
        }
        channel {
          id
          title
        }
        owner {
          id
          displayName
          avatarUrl
        }
        updatedAt
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useMyInitiationsOnMyStoriesPageQuery__
 *
 * To run a query within a React component, call `useMyInitiationsOnMyStoriesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyInitiationsOnMyStoriesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyInitiationsOnMyStoriesPageQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMyInitiationsOnMyStoriesPageQuery(baseOptions: Apollo.QueryHookOptions<MyInitiationsOnMyStoriesPageQuery, MyInitiationsOnMyStoriesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyInitiationsOnMyStoriesPageQuery, MyInitiationsOnMyStoriesPageQueryVariables>(MyInitiationsOnMyStoriesPageDocument, options);
      }
export function useMyInitiationsOnMyStoriesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyInitiationsOnMyStoriesPageQuery, MyInitiationsOnMyStoriesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyInitiationsOnMyStoriesPageQuery, MyInitiationsOnMyStoriesPageQueryVariables>(MyInitiationsOnMyStoriesPageDocument, options);
        }
export type MyInitiationsOnMyStoriesPageQueryHookResult = ReturnType<typeof useMyInitiationsOnMyStoriesPageQuery>;
export type MyInitiationsOnMyStoriesPageLazyQueryHookResult = ReturnType<typeof useMyInitiationsOnMyStoriesPageLazyQuery>;
export type MyInitiationsOnMyStoriesPageQueryResult = Apollo.QueryResult<MyInitiationsOnMyStoriesPageQuery, MyInitiationsOnMyStoriesPageQueryVariables>;
export function refetchMyInitiationsOnMyStoriesPageQuery(variables: MyInitiationsOnMyStoriesPageQueryVariables) {
      return { query: MyInitiationsOnMyStoriesPageDocument, variables: variables }
    }