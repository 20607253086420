import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { ContactFragmentOnContactFeedFragmentDoc } from '../../../crm/contacts/__generated__/contact-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideoUploadsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.VideoUploadFilter>;
}>;


export type VideoUploadsQuery = { __typename?: 'RootQueryType', videoUploads?: { __typename?: 'VideoUploadConnection', totalCount: number, edges?: Array<{ __typename?: 'VideoUploadEdge', node?: { __typename?: 'VideoUpload', id: string, name?: string | null, rawUrl?: string | null, alternativeText?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, duration?: number | null, size?: number | null, insertedAt?: any | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type VideoUploadQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type VideoUploadQuery = { __typename?: 'RootQueryType', videoUpload?: { __typename?: 'VideoUpload', id: string, name?: string | null, rawUrl?: string | null, alternativeText?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, duration?: number | null, size?: number | null, insertedAt?: any | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null } | null };

export type VideoUploadEdgeOnVideoUploadFeedFragment = { __typename?: 'VideoUploadEdge', node?: { __typename?: 'VideoUpload', id: string, name?: string | null, rawUrl?: string | null, alternativeText?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, duration?: number | null, size?: number | null, insertedAt?: any | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null } | null };

export type VideoUploadOnFeedFragment = { __typename?: 'VideoUpload', id: string, name?: string | null, rawUrl?: string | null, alternativeText?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, duration?: number | null, size?: number | null, insertedAt?: any | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null };

export const VideoUploadOnFeedFragmentDoc = gql`
    fragment VideoUploadOnFeed on VideoUpload {
  id
  name
  rawUrl
  alternativeText
  transcodedUrl
  previewUrl
  streamUrl
  thumbUrl
  gifUrl
  gifWithIconUrl
  duration
  size
  insertedAt
  contact {
    ...ContactFragmentOnContactFeed
  }
}
    ${ContactFragmentOnContactFeedFragmentDoc}`;
export const VideoUploadEdgeOnVideoUploadFeedFragmentDoc = gql`
    fragment VideoUploadEdgeOnVideoUploadFeed on VideoUploadEdge {
  node {
    ...VideoUploadOnFeed
  }
}
    ${VideoUploadOnFeedFragmentDoc}`;
export const VideoUploadsDocument = gql`
    query videoUploads($after: String, $before: String, $first: Int, $filter: VideoUploadFilter) {
  videoUploads(after: $after, before: $before, first: $first, filter: $filter) {
    edges {
      node {
        ...VideoUploadOnFeed
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    ${VideoUploadOnFeedFragmentDoc}`;

/**
 * __useVideoUploadsQuery__
 *
 * To run a query within a React component, call `useVideoUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoUploadsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVideoUploadsQuery(baseOptions?: Apollo.QueryHookOptions<VideoUploadsQuery, VideoUploadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoUploadsQuery, VideoUploadsQueryVariables>(VideoUploadsDocument, options);
      }
export function useVideoUploadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoUploadsQuery, VideoUploadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoUploadsQuery, VideoUploadsQueryVariables>(VideoUploadsDocument, options);
        }
export type VideoUploadsQueryHookResult = ReturnType<typeof useVideoUploadsQuery>;
export type VideoUploadsLazyQueryHookResult = ReturnType<typeof useVideoUploadsLazyQuery>;
export type VideoUploadsQueryResult = Apollo.QueryResult<VideoUploadsQuery, VideoUploadsQueryVariables>;
export function refetchVideoUploadsQuery(variables?: VideoUploadsQueryVariables) {
      return { query: VideoUploadsDocument, variables: variables }
    }
export const VideoUploadDocument = gql`
    query videoUpload($id: ID!) {
  videoUpload(id: $id) {
    ...VideoUploadOnFeed
  }
}
    ${VideoUploadOnFeedFragmentDoc}`;

/**
 * __useVideoUploadQuery__
 *
 * To run a query within a React component, call `useVideoUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoUploadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoUploadQuery(baseOptions: Apollo.QueryHookOptions<VideoUploadQuery, VideoUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoUploadQuery, VideoUploadQueryVariables>(VideoUploadDocument, options);
      }
export function useVideoUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoUploadQuery, VideoUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoUploadQuery, VideoUploadQueryVariables>(VideoUploadDocument, options);
        }
export type VideoUploadQueryHookResult = ReturnType<typeof useVideoUploadQuery>;
export type VideoUploadLazyQueryHookResult = ReturnType<typeof useVideoUploadLazyQuery>;
export type VideoUploadQueryResult = Apollo.QueryResult<VideoUploadQuery, VideoUploadQueryVariables>;
export function refetchVideoUploadQuery(variables: VideoUploadQueryVariables) {
      return { query: VideoUploadDocument, variables: variables }
    }