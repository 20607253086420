import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentSubscriptionOnWorkspaceSettingsBillingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentSubscriptionOnWorkspaceSettingsBillingQuery = { __typename?: 'RootQueryType', currentSubscription?: { __typename?: 'PlanSubscription', id: string, status?: Types.SubscriptionStatus | null, currency?: string | null, yearly?: boolean | null, quantity?: number | null, unitAmount?: number | null, currentPeriodEnd?: any | null, cancelAtPeriodEnd?: boolean | null, discountApplied?: boolean | null, discountDurationInMonths?: number | null, discountPercentOff?: number | null, discountEnd?: any | null, discountStart?: any | null, plan: { __typename?: 'Plan', id?: string | null, stripeId?: string | null, isTrial?: boolean | null, isYearly?: boolean | null, slug?: string | null, name?: string | null, nameI18nKey?: string | null, initialMembers?: number | null, initialPlays?: number | null, initialStorage?: number | null } } | null };


export const CurrentSubscriptionOnWorkspaceSettingsBillingDocument = gql`
    query currentSubscriptionOnWorkspaceSettingsBilling {
  currentSubscription {
    id
    status
    currency
    yearly
    quantity
    unitAmount
    currentPeriodEnd
    cancelAtPeriodEnd
    discountApplied
    discountDurationInMonths
    discountPercentOff
    discountEnd
    discountStart
    plan {
      id
      stripeId
      isTrial
      isYearly
      slug
      name
      nameI18nKey
      initialMembers
      initialPlays
      initialStorage
    }
  }
}
    `;

/**
 * __useCurrentSubscriptionOnWorkspaceSettingsBillingQuery__
 *
 * To run a query within a React component, call `useCurrentSubscriptionOnWorkspaceSettingsBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSubscriptionOnWorkspaceSettingsBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSubscriptionOnWorkspaceSettingsBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentSubscriptionOnWorkspaceSettingsBillingQuery(baseOptions?: Apollo.QueryHookOptions<CurrentSubscriptionOnWorkspaceSettingsBillingQuery, CurrentSubscriptionOnWorkspaceSettingsBillingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentSubscriptionOnWorkspaceSettingsBillingQuery, CurrentSubscriptionOnWorkspaceSettingsBillingQueryVariables>(CurrentSubscriptionOnWorkspaceSettingsBillingDocument, options);
      }
export function useCurrentSubscriptionOnWorkspaceSettingsBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentSubscriptionOnWorkspaceSettingsBillingQuery, CurrentSubscriptionOnWorkspaceSettingsBillingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentSubscriptionOnWorkspaceSettingsBillingQuery, CurrentSubscriptionOnWorkspaceSettingsBillingQueryVariables>(CurrentSubscriptionOnWorkspaceSettingsBillingDocument, options);
        }
export type CurrentSubscriptionOnWorkspaceSettingsBillingQueryHookResult = ReturnType<typeof useCurrentSubscriptionOnWorkspaceSettingsBillingQuery>;
export type CurrentSubscriptionOnWorkspaceSettingsBillingLazyQueryHookResult = ReturnType<typeof useCurrentSubscriptionOnWorkspaceSettingsBillingLazyQuery>;
export type CurrentSubscriptionOnWorkspaceSettingsBillingQueryResult = Apollo.QueryResult<CurrentSubscriptionOnWorkspaceSettingsBillingQuery, CurrentSubscriptionOnWorkspaceSettingsBillingQueryVariables>;
export function refetchCurrentSubscriptionOnWorkspaceSettingsBillingQuery(variables?: CurrentSubscriptionOnWorkspaceSettingsBillingQueryVariables) {
      return { query: CurrentSubscriptionOnWorkspaceSettingsBillingDocument, variables: variables }
    }