import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteVideoUploadMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteVideoUploadMutation = { __typename?: 'RootMutationType', deleteVideoUpload?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const DeleteVideoUploadDocument = gql`
    mutation deleteVideoUpload($id: ID!) {
  deleteVideoUpload(id: $id) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteVideoUploadMutationFn = Apollo.MutationFunction<DeleteVideoUploadMutation, DeleteVideoUploadMutationVariables>;

/**
 * __useDeleteVideoUploadMutation__
 *
 * To run a mutation, you first call `useDeleteVideoUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoUploadMutation, { data, loading, error }] = useDeleteVideoUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVideoUploadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVideoUploadMutation, DeleteVideoUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVideoUploadMutation, DeleteVideoUploadMutationVariables>(DeleteVideoUploadDocument, options);
      }
export type DeleteVideoUploadMutationHookResult = ReturnType<typeof useDeleteVideoUploadMutation>;
export type DeleteVideoUploadMutationResult = Apollo.MutationResult<DeleteVideoUploadMutation>;
export type DeleteVideoUploadMutationOptions = Apollo.BaseMutationOptions<DeleteVideoUploadMutation, DeleteVideoUploadMutationVariables>;