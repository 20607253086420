import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyChannelQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyChannelQuery = { __typename?: 'RootQueryType', myChannel?: { __typename?: 'Channel', id: string, title: string } | null };


export const MyChannelDocument = gql`
    query myChannel {
  myChannel {
    id
    title
  }
}
    `;

/**
 * __useMyChannelQuery__
 *
 * To run a query within a React component, call `useMyChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyChannelQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyChannelQuery(baseOptions?: Apollo.QueryHookOptions<MyChannelQuery, MyChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyChannelQuery, MyChannelQueryVariables>(MyChannelDocument, options);
      }
export function useMyChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyChannelQuery, MyChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyChannelQuery, MyChannelQueryVariables>(MyChannelDocument, options);
        }
export type MyChannelQueryHookResult = ReturnType<typeof useMyChannelQuery>;
export type MyChannelLazyQueryHookResult = ReturnType<typeof useMyChannelLazyQuery>;
export type MyChannelQueryResult = Apollo.QueryResult<MyChannelQuery, MyChannelQueryVariables>;
export function refetchMyChannelQuery(variables?: MyChannelQueryVariables) {
      return { query: MyChannelDocument, variables: variables }
    }