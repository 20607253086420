interface Props {
  fill: string;
  className?: string;
}

export const UntitledUIEdit04Icon = ({
  className,
  fill = '#4b5563',
}: Props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <path d="M20.26 17.32l-1.01 1.09c-.35.37-.8.58-1.27.58s-.92-.21-1.27-.59c-.72-.79-1.71-1.23-2.74-1.23 -1.04 0-2.02.44-2.74 1.22 -.38.4-.35 1.03.06 1.41 .4.37 1.03.34 1.41-.07 .34-.38.79-.58 1.26-.58 .46 0 .91.2 1.26.57 .71.78 1.7 1.22 2.73 1.22s2.02-.45 2.73-1.24l1-1.1c.37-.41.34-1.05-.07-1.42 -.41-.38-1.05-.35-1.42.06ZM2.99 20.99h1.67c.7 0 .88-.01 1.19-.09 .3-.08.59-.2.86-.36 .04-.03.09-.07.14-.1 .21-.16.33-.27.76-.7L20.17 7.17c1.21-1.22 1.21-3.2 0-4.42 -1.22-1.22-3.2-1.22-4.42-.01L3.18 15.3c-.5.49-.63.63-.79.9 -.17.26-.29.56-.36.86 -.08.31-.09.49-.09 1.19v1.67c0 .55.44 1 1 1Zm-.001-2l1 1v-1.68c0-.52 0-.64.02-.74 .02-.11.06-.2.11-.29 .04-.09.12-.17.49-.54L17.16 4.17c.43-.44 1.14-.44 1.58 0 .43.43.43 1.14-.01 1.58L6.16 18.31c-.34.33-.43.42-.51.47 -.02 0-.02.01-.03.01 -.09.05-.19.09-.29.11 -.1.02-.22.02-.74.02H2.91Z" />
    </svg>
  );
};
