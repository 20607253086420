import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateImageUploadMutationVariables = Types.Exact<{
  input: Types.CreateImageUploadInput;
}>;


export type CreateImageUploadMutation = { __typename?: 'RootMutationType', createImageUpload?: { __typename?: 'ImageUploadResult', imageUpload?: { __typename?: 'ImageUpload', id: string, name?: string | null, imageUrl?: string | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateImageUploadDocument = gql`
    mutation createImageUpload($input: CreateImageUploadInput!) {
  createImageUpload(input: $input) {
    imageUpload {
      id
      name
      imageUrl
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CreateImageUploadMutationFn = Apollo.MutationFunction<CreateImageUploadMutation, CreateImageUploadMutationVariables>;

/**
 * __useCreateImageUploadMutation__
 *
 * To run a mutation, you first call `useCreateImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageUploadMutation, { data, loading, error }] = useCreateImageUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImageUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateImageUploadMutation, CreateImageUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImageUploadMutation, CreateImageUploadMutationVariables>(CreateImageUploadDocument, options);
      }
export type CreateImageUploadMutationHookResult = ReturnType<typeof useCreateImageUploadMutation>;
export type CreateImageUploadMutationResult = Apollo.MutationResult<CreateImageUploadMutation>;
export type CreateImageUploadMutationOptions = Apollo.BaseMutationOptions<CreateImageUploadMutation, CreateImageUploadMutationVariables>;