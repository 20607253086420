import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateShoppableProductMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateShoppableProductInput;
}>;


export type UpdateShoppableProductMutation = { __typename?: 'RootMutationType', updateShoppableProduct?: { __typename?: 'ShoppableProductMutationResult', shoppableProduct?: { __typename?: 'ShoppableProduct', id: string, ctaType: Types.ShoppableProductCtaType, customCtaText?: string | null, product: { __typename?: 'Product', id: string, currencyCode: string, onlineStoreUrl: string, sourceType?: string | null, sourceId?: string | null, title: string, images?: Array<{ __typename?: 'ProductImage', id: string, src?: string | null }> | null, variants?: Array<{ __typename?: 'ProductVariant', id: string, originalPrice?: number | null, price?: number | null }> | null } } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateShoppableProductDocument = gql`
    mutation updateShoppableProduct($id: ID!, $input: UpdateShoppableProductInput!) {
  updateShoppableProduct(id: $id, input: $input) {
    shoppableProduct {
      id
      ctaType
      customCtaText
      product {
        id
        currencyCode
        onlineStoreUrl
        sourceType
        sourceId
        title
        images {
          id
          src
        }
        variants {
          id
          originalPrice
          price
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateShoppableProductMutationFn = Apollo.MutationFunction<UpdateShoppableProductMutation, UpdateShoppableProductMutationVariables>;

/**
 * __useUpdateShoppableProductMutation__
 *
 * To run a mutation, you first call `useUpdateShoppableProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShoppableProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShoppableProductMutation, { data, loading, error }] = useUpdateShoppableProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShoppableProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShoppableProductMutation, UpdateShoppableProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShoppableProductMutation, UpdateShoppableProductMutationVariables>(UpdateShoppableProductDocument, options);
      }
export type UpdateShoppableProductMutationHookResult = ReturnType<typeof useUpdateShoppableProductMutation>;
export type UpdateShoppableProductMutationResult = Apollo.MutationResult<UpdateShoppableProductMutation>;
export type UpdateShoppableProductMutationOptions = Apollo.BaseMutationOptions<UpdateShoppableProductMutation, UpdateShoppableProductMutationVariables>;