import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyChannelUnreadCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyChannelUnreadCountQuery = { __typename?: 'RootQueryType', fetchMyChannelUnreadCount: { __typename?: 'ChannelUnreadCount', channelId: string, channelDatabaseId: any, unreadCount: number } };


export const MyChannelUnreadCountDocument = gql`
    query myChannelUnreadCount {
  fetchMyChannelUnreadCount {
    channelId
    channelDatabaseId
    unreadCount
  }
}
    `;

/**
 * __useMyChannelUnreadCountQuery__
 *
 * To run a query within a React component, call `useMyChannelUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyChannelUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyChannelUnreadCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyChannelUnreadCountQuery(baseOptions?: Apollo.QueryHookOptions<MyChannelUnreadCountQuery, MyChannelUnreadCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyChannelUnreadCountQuery, MyChannelUnreadCountQueryVariables>(MyChannelUnreadCountDocument, options);
      }
export function useMyChannelUnreadCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyChannelUnreadCountQuery, MyChannelUnreadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyChannelUnreadCountQuery, MyChannelUnreadCountQueryVariables>(MyChannelUnreadCountDocument, options);
        }
export type MyChannelUnreadCountQueryHookResult = ReturnType<typeof useMyChannelUnreadCountQuery>;
export type MyChannelUnreadCountLazyQueryHookResult = ReturnType<typeof useMyChannelUnreadCountLazyQuery>;
export type MyChannelUnreadCountQueryResult = Apollo.QueryResult<MyChannelUnreadCountQuery, MyChannelUnreadCountQueryVariables>;
export function refetchMyChannelUnreadCountQuery(variables?: MyChannelUnreadCountQueryVariables) {
      return { query: MyChannelUnreadCountDocument, variables: variables }
    }