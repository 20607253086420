import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNarrationChapterVideoUploadMutationVariables = Types.Exact<{
  input: Types.CreateNarrationChapterVideoUploadInput;
}>;


export type CreateNarrationChapterVideoUploadMutation = { __typename?: 'RootMutationType', createNarrationChapterVideoUpload?: { __typename?: 'VideoUploadResult', videoUpload?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null } | null, content?: { __typename?: 'Content', id: string, type: Types.ContentType, groupType: Types.ContentGroupType, body?: any | null, label?: string | null, plainDescription?: string | null, embedLink?: string | null, providerName?: string | null, videoContain?: boolean | null, file?: { __typename?: 'FileUpload', id: string, name?: string | null, size?: number | null, pageCount?: number | null, mimeType?: string | null, fileUrl?: string | null } | null, narration?: { __typename?: 'ContentNarration', id: string, chapters?: Array<{ __typename?: 'NarrationChapter', id: string, slidePageNumber?: number | null, video?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null } | null }> | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateNarrationChapterVideoUploadDocument = gql`
    mutation createNarrationChapterVideoUpload($input: CreateNarrationChapterVideoUploadInput!) {
  createNarrationChapterVideoUpload(input: $input) {
    videoUpload {
      id
      rawUrl
      transcodedUrl
      previewUrl
      streamUrl
      gifUrl
      gifWithIconUrl
    }
    content {
      id
      type
      groupType
      body
      label
      plainDescription
      embedLink
      providerName
      videoContain
      file {
        id
        name
        size
        pageCount
        mimeType
        fileUrl
      }
      narration {
        id
        chapters {
          id
          slidePageNumber
          video {
            id
            rawUrl
            transcodedUrl
            previewUrl
            streamUrl
            thumbUrl
            gifUrl
            gifWithIconUrl
          }
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CreateNarrationChapterVideoUploadMutationFn = Apollo.MutationFunction<CreateNarrationChapterVideoUploadMutation, CreateNarrationChapterVideoUploadMutationVariables>;

/**
 * __useCreateNarrationChapterVideoUploadMutation__
 *
 * To run a mutation, you first call `useCreateNarrationChapterVideoUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNarrationChapterVideoUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNarrationChapterVideoUploadMutation, { data, loading, error }] = useCreateNarrationChapterVideoUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNarrationChapterVideoUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateNarrationChapterVideoUploadMutation, CreateNarrationChapterVideoUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNarrationChapterVideoUploadMutation, CreateNarrationChapterVideoUploadMutationVariables>(CreateNarrationChapterVideoUploadDocument, options);
      }
export type CreateNarrationChapterVideoUploadMutationHookResult = ReturnType<typeof useCreateNarrationChapterVideoUploadMutation>;
export type CreateNarrationChapterVideoUploadMutationResult = Apollo.MutationResult<CreateNarrationChapterVideoUploadMutation>;
export type CreateNarrationChapterVideoUploadMutationOptions = Apollo.BaseMutationOptions<CreateNarrationChapterVideoUploadMutation, CreateNarrationChapterVideoUploadMutationVariables>;