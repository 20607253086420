interface Props {
  fill: string;
  className?: string;
}

export const UntitledUIUserPlus01Icon = ({
  className,
  fill = '#4b5563',
}: Props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <path d="M12 14.5H7.5c-1.88 0-2.32.02-2.96.21 -1.6.48-2.85 1.73-3.34 3.33 -.2.63-.22 1.08-.22 2.95 0 .55.44 1 1 1 .55 0 1-.45 1-1 0-1.62.02-2.02.12-2.38 .29-.96 1.04-1.71 1.99-2.01 .35-.11.75-.13 2.37-.13h4.5c.55 0 1-.45 1-1 0-.56-.45-1-1-1Zm8 6.5v-6c0-.56-.45-1-1-1 -.56 0-1 .44-1 1v6c0 .55.44 1 1 1 .55 0 1-.45 1-1Zm-4-2h6c.55 0 1-.45 1-1 0-.56-.45-1-1-1h-6c-.56 0-1 .44-1 1 0 .55.44 1 1 1ZM13.5 7.5c0 1.93-1.57 3.5-3.5 3.5 -1.94 0-3.5-1.57-3.5-3.5C6.5 5.56 8.06 4 10 4c1.93 0 3.5 1.56 3.5 3.5Zm2 0C15.5 4.46 13.03 2 10 2 6.96 2 4.5 4.46 4.5 7.5c0 3.03 2.46 5.5 5.5 5.5 3.03 0 5.5-2.47 5.5-5.5Z" />
    </svg>
  );
};
