import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { CustomLogicFieldsFragmentDoc } from './custom-logic-fields-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateChoiceLogicMutationVariables = Types.Exact<{
  choiceId: Types.Scalars['ID']['input'];
}>;


export type CreateChoiceLogicMutation = { __typename?: 'RootMutationType', createChoiceLogic?: { __typename?: 'ChoiceLogicMutationResult', choiceLogic?: { __typename?: 'ChoiceLogic', id: string, operator: Types.LogicOperatorType, conditionals?: Array<{ __typename?: 'ChoiceConditional', id: string, comparison: Types.ChoiceConditionalComparisonType, question?: { __typename?: 'PageQuestion', id: string, title?: string | null, titleColor: string, storyPage?: { __typename?: 'InitiationItem', id: string, title: string, order: number } | null } | null, choice?: { __typename?: 'PageChoice', id: string, order: number, text?: string | null } | null } | null> | null, actions?: Array<{ __typename?: 'ChoiceAction', id: string, type?: Types.ChoiceActionType | null, result?: Types.ChoiceActionResultType | null, value?: number | null, strValue?: string | null, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null } | null> | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateChoiceLogicDocument = gql`
    mutation createChoiceLogic($choiceId: ID!) {
  createChoiceLogic(choiceId: $choiceId) {
    choiceLogic {
      ...CustomLogicFields
    }
    userErrors {
      field
      message
    }
  }
}
    ${CustomLogicFieldsFragmentDoc}`;
export type CreateChoiceLogicMutationFn = Apollo.MutationFunction<CreateChoiceLogicMutation, CreateChoiceLogicMutationVariables>;

/**
 * __useCreateChoiceLogicMutation__
 *
 * To run a mutation, you first call `useCreateChoiceLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChoiceLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChoiceLogicMutation, { data, loading, error }] = useCreateChoiceLogicMutation({
 *   variables: {
 *      choiceId: // value for 'choiceId'
 *   },
 * });
 */
export function useCreateChoiceLogicMutation(baseOptions?: Apollo.MutationHookOptions<CreateChoiceLogicMutation, CreateChoiceLogicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChoiceLogicMutation, CreateChoiceLogicMutationVariables>(CreateChoiceLogicDocument, options);
      }
export type CreateChoiceLogicMutationHookResult = ReturnType<typeof useCreateChoiceLogicMutation>;
export type CreateChoiceLogicMutationResult = Apollo.MutationResult<CreateChoiceLogicMutation>;
export type CreateChoiceLogicMutationOptions = Apollo.BaseMutationOptions<CreateChoiceLogicMutation, CreateChoiceLogicMutationVariables>;