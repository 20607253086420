import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoriesStatsQueryVariables = Types.Exact<{
  filter: Types.StoriesStatsFilter;
  page: Types.Scalars['Int']['input'];
}>;


export type StoriesStatsQuery = { __typename?: 'RootQueryType', storiesStats?: { __typename?: 'StoriesStatsResult', storiesStats: Array<{ __typename?: 'StoriesStatsItem', story: { __typename?: 'Initiation', id: string, title: string, sid: string, databaseId: any }, stats: { __typename?: 'BasicStoryStats', impressions: number, plays: number, pageviews: number, visitDuration: number, choiceClicks: number, ctaClicks: number, productLinkClicks: number, videoCollected: number, textCollected: number } }> } | null };


export const StoriesStatsDocument = gql`
    query storiesStats($filter: StoriesStatsFilter!, $page: Int!) {
  storiesStats(filter: $filter, page: $page) {
    storiesStats {
      story {
        id
        title
        sid
        databaseId
      }
      stats {
        impressions
        plays
        pageviews
        visitDuration
        choiceClicks
        ctaClicks
        productLinkClicks
        videoCollected
        textCollected
      }
    }
  }
}
    `;

/**
 * __useStoriesStatsQuery__
 *
 * To run a query within a React component, call `useStoriesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useStoriesStatsQuery(baseOptions: Apollo.QueryHookOptions<StoriesStatsQuery, StoriesStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoriesStatsQuery, StoriesStatsQueryVariables>(StoriesStatsDocument, options);
      }
export function useStoriesStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoriesStatsQuery, StoriesStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoriesStatsQuery, StoriesStatsQueryVariables>(StoriesStatsDocument, options);
        }
export type StoriesStatsQueryHookResult = ReturnType<typeof useStoriesStatsQuery>;
export type StoriesStatsLazyQueryHookResult = ReturnType<typeof useStoriesStatsLazyQuery>;
export type StoriesStatsQueryResult = Apollo.QueryResult<StoriesStatsQuery, StoriesStatsQueryVariables>;
export function refetchStoriesStatsQuery(variables: StoriesStatsQueryVariables) {
      return { query: StoriesStatsDocument, variables: variables }
    }