import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
export type ContactFormConsentFragmentFragment = { __typename?: 'Consent', id: string, deletedAt?: any | null, currentVersion: { __typename: 'ConsentVersion', id: string, text?: string | null, isRequired: boolean } };

export const ContactFormConsentFragmentFragmentDoc = gql`
    fragment contactFormConsentFragment on Consent {
  id
  deletedAt
  currentVersion {
    __typename
    id
    text
    isRequired
  }
}
    `;