import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { CustomLogicFieldsFragmentDoc } from '../../choice-logics/__generated__/custom-logic-fields-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddChoiceConditionalMutationVariables = Types.Exact<{
  choiceLogicId: Types.Scalars['ID']['input'];
}>;


export type AddChoiceConditionalMutation = { __typename?: 'RootMutationType', addChoiceConditional?: { __typename?: 'ChoiceLogicMutationResult', choiceLogic?: { __typename?: 'ChoiceLogic', id: string, operator: Types.LogicOperatorType, conditionals?: Array<{ __typename?: 'ChoiceConditional', id: string, comparison: Types.ChoiceConditionalComparisonType, question?: { __typename?: 'PageQuestion', id: string, title?: string | null, titleColor: string, storyPage?: { __typename?: 'InitiationItem', id: string, title: string, order: number } | null } | null, choice?: { __typename?: 'PageChoice', id: string, order: number, text?: string | null } | null } | null> | null, actions?: Array<{ __typename?: 'ChoiceAction', id: string, type?: Types.ChoiceActionType | null, result?: Types.ChoiceActionResultType | null, value?: number | null, strValue?: string | null, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null } | null> | null } | null } | null };


export const AddChoiceConditionalDocument = gql`
    mutation addChoiceConditional($choiceLogicId: ID!) {
  addChoiceConditional(choiceLogicId: $choiceLogicId) {
    choiceLogic {
      ...CustomLogicFields
    }
  }
}
    ${CustomLogicFieldsFragmentDoc}`;
export type AddChoiceConditionalMutationFn = Apollo.MutationFunction<AddChoiceConditionalMutation, AddChoiceConditionalMutationVariables>;

/**
 * __useAddChoiceConditionalMutation__
 *
 * To run a mutation, you first call `useAddChoiceConditionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChoiceConditionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChoiceConditionalMutation, { data, loading, error }] = useAddChoiceConditionalMutation({
 *   variables: {
 *      choiceLogicId: // value for 'choiceLogicId'
 *   },
 * });
 */
export function useAddChoiceConditionalMutation(baseOptions?: Apollo.MutationHookOptions<AddChoiceConditionalMutation, AddChoiceConditionalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddChoiceConditionalMutation, AddChoiceConditionalMutationVariables>(AddChoiceConditionalDocument, options);
      }
export type AddChoiceConditionalMutationHookResult = ReturnType<typeof useAddChoiceConditionalMutation>;
export type AddChoiceConditionalMutationResult = Apollo.MutationResult<AddChoiceConditionalMutation>;
export type AddChoiceConditionalMutationOptions = Apollo.BaseMutationOptions<AddChoiceConditionalMutation, AddChoiceConditionalMutationVariables>;