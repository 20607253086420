import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadAvatarOnAccoutSettingsMutationVariables = Types.Exact<{
  input: Types.UploadAvatarInput;
}>;


export type UploadAvatarOnAccoutSettingsMutation = { __typename?: 'RootMutationType', uploadAvatar?: { __typename?: 'ViewerResult', viewer?: { __typename?: 'User', id: string, databaseId: any, avatarUrl?: string | null, displayName?: string | null, email?: string | null, emailVerified?: boolean | null, onboardingStatus: Types.OnboardingStatus, role: string, locale: Types.Locale, tenantCount: number, activeTenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null } | null, member?: { __typename?: 'Member', id: string, role: string, displayName?: string | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UploadAvatarOnAccoutSettingsDocument = gql`
    mutation uploadAvatarOnAccoutSettings($input: UploadAvatarInput!) {
  uploadAvatar(input: $input) {
    viewer {
      id
      databaseId
      avatarUrl
      displayName
      email
      emailVerified
      onboardingStatus
      role
      locale
      tenantCount
      activeTenant {
        id
        databaseId
        commerceActivated
        storyCount
        name
      }
      member {
        id
        role
        displayName
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UploadAvatarOnAccoutSettingsMutationFn = Apollo.MutationFunction<UploadAvatarOnAccoutSettingsMutation, UploadAvatarOnAccoutSettingsMutationVariables>;

/**
 * __useUploadAvatarOnAccoutSettingsMutation__
 *
 * To run a mutation, you first call `useUploadAvatarOnAccoutSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarOnAccoutSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarOnAccoutSettingsMutation, { data, loading, error }] = useUploadAvatarOnAccoutSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadAvatarOnAccoutSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UploadAvatarOnAccoutSettingsMutation, UploadAvatarOnAccoutSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAvatarOnAccoutSettingsMutation, UploadAvatarOnAccoutSettingsMutationVariables>(UploadAvatarOnAccoutSettingsDocument, options);
      }
export type UploadAvatarOnAccoutSettingsMutationHookResult = ReturnType<typeof useUploadAvatarOnAccoutSettingsMutation>;
export type UploadAvatarOnAccoutSettingsMutationResult = Apollo.MutationResult<UploadAvatarOnAccoutSettingsMutation>;
export type UploadAvatarOnAccoutSettingsMutationOptions = Apollo.BaseMutationOptions<UploadAvatarOnAccoutSettingsMutation, UploadAvatarOnAccoutSettingsMutationVariables>;