import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { CompanyFragmentOnCompanyFeedFragmentDoc } from './company-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompaniesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.CompanyFilter>;
}>;


export type CompaniesQuery = { __typename?: 'RootQueryType', companies?: { __typename?: 'CompanyConnection', totalCount: number, edges: Array<{ __typename?: 'CompanyEdge', node?: { __typename?: 'Company', id: string, name: string, url?: string | null, iconUrl?: string | null, thumbUrl?: string | null, insertedAt?: any | null } | null }>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type CompanyEdgeOnCompanyFeedFragment = { __typename?: 'CompanyEdge', node?: { __typename?: 'Company', id: string, name: string, url?: string | null, iconUrl?: string | null, thumbUrl?: string | null, insertedAt?: any | null } | null };

export type CompaniesForSelectQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.CompanyFilter>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CompaniesForSelectQuery = { __typename?: 'RootQueryType', companiesForSelect?: { __typename?: 'CompanyConnection', edges: Array<{ __typename?: 'CompanyEdge', node?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null }> } | null };

export type CompanyEdgeForSelectOnCompanyFeedFragment = { __typename?: 'CompanyEdge', node?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null };

export type CompanyForSelectOnCompanyFeedFragment = { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null };

export const CompanyEdgeOnCompanyFeedFragmentDoc = gql`
    fragment CompanyEdgeOnCompanyFeed on CompanyEdge {
  node {
    ...CompanyFragmentOnCompanyFeed
  }
}
    ${CompanyFragmentOnCompanyFeedFragmentDoc}`;
export const CompanyForSelectOnCompanyFeedFragmentDoc = gql`
    fragment CompanyForSelectOnCompanyFeed on Company {
  id
  name
  iconUrl
  thumbUrl
}
    `;
export const CompanyEdgeForSelectOnCompanyFeedFragmentDoc = gql`
    fragment CompanyEdgeForSelectOnCompanyFeed on CompanyEdge {
  node {
    ...CompanyForSelectOnCompanyFeed
  }
}
    ${CompanyForSelectOnCompanyFeedFragmentDoc}`;
export const CompaniesDocument = gql`
    query companies($first: Int, $last: Int, $after: String, $before: String, $filter: CompanyFilter) {
  companies(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    edges {
      ...CompanyEdgeOnCompanyFeed
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
    ${CompanyEdgeOnCompanyFeedFragmentDoc}`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export function refetchCompaniesQuery(variables?: CompaniesQueryVariables) {
      return { query: CompaniesDocument, variables: variables }
    }
export const CompaniesForSelectDocument = gql`
    query companiesForSelect($filter: CompanyFilter, $first: Int, $after: String) {
  companiesForSelect(after: $after, first: $first, filter: $filter) {
    edges {
      ...CompanyEdgeForSelectOnCompanyFeed
    }
  }
}
    ${CompanyEdgeForSelectOnCompanyFeedFragmentDoc}`;

/**
 * __useCompaniesForSelectQuery__
 *
 * To run a query within a React component, call `useCompaniesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesForSelectQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCompaniesForSelectQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesForSelectQuery, CompaniesForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesForSelectQuery, CompaniesForSelectQueryVariables>(CompaniesForSelectDocument, options);
      }
export function useCompaniesForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesForSelectQuery, CompaniesForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesForSelectQuery, CompaniesForSelectQueryVariables>(CompaniesForSelectDocument, options);
        }
export type CompaniesForSelectQueryHookResult = ReturnType<typeof useCompaniesForSelectQuery>;
export type CompaniesForSelectLazyQueryHookResult = ReturnType<typeof useCompaniesForSelectLazyQuery>;
export type CompaniesForSelectQueryResult = Apollo.QueryResult<CompaniesForSelectQuery, CompaniesForSelectQueryVariables>;
export function refetchCompaniesForSelectQuery(variables?: CompaniesForSelectQueryVariables) {
      return { query: CompaniesForSelectDocument, variables: variables }
    }