/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;
const LocalStorageBackend = require('i18next-localstorage-backend').default;

const isBrowser = typeof window !== 'undefined';
const isDev = process.env.NODE_ENV === 'development';

module.exports = {
  debug: isDev,
  // backend: {
  //   backendOptions: [{ expirationTime: 60 * 60 * 1000 }, {}],
  //   backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
  // },
  backend: {
    backendOptions: [
      { expirationTime: 60 * 60 * 1000 },
      {
        loadPath: '/static/locales/{{lng}}/{{ns}}.json',
      },
    ],
    backends:
      typeof window !== 'undefined' ? [LocalStorageBackend, HttpBackend] : [],
  },
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'ja'],
  },
  interpolation: {
    escapeValue: false,
  },
  defaultNS: 'common',
  fallbackLng: {
    default: ['en'],
  },
  localePath: isDev
    ? require('path').resolve('./apps/sharelo/public/static/locales')
    : require('path').resolve('./public/static/locales'),
  localeExtension: 'json',
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
  partialBundledLanguages: isBrowser && true,
  reloadOnPrerender: isDev,
};
