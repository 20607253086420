import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDefaultStoryNotificationSettingMutationVariables = Types.Exact<{
  input: Types.UpdateStoryNotificationSettingInput;
}>;


export type UpdateDefaultStoryNotificationSettingMutation = { __typename?: 'RootMutationType', updateStoryNotificationSetting?: { __typename?: 'StoryNotificationSettingResult', storyNotificationSetting?: { __typename?: 'StoryNotificationSetting', id: string, isDefault: boolean, includeAnonymous: boolean, playEmail: boolean, collectLeadEmail: boolean, reachEndEmail: boolean, responseEmail: boolean } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };

export type UpdateStoryNotificationSettingMutationVariables = Types.Exact<{
  input: Types.UpdateStoryNotificationSettingInput;
}>;


export type UpdateStoryNotificationSettingMutation = { __typename?: 'RootMutationType', updateStoryNotificationSetting?: { __typename?: 'StoryNotificationSettingResult', storyNotificationSetting?: { __typename?: 'StoryNotificationSetting', id: string, isDefault: boolean, includeAnonymous: boolean, playEmail: boolean, collectLeadEmail: boolean, reachEndEmail: boolean, responseEmail: boolean } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateDefaultStoryNotificationSettingDocument = gql`
    mutation updateDefaultStoryNotificationSetting($input: UpdateStoryNotificationSettingInput!) {
  updateStoryNotificationSetting(input: $input) {
    storyNotificationSetting {
      id
      isDefault
      includeAnonymous
      playEmail
      collectLeadEmail
      reachEndEmail
      responseEmail
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateDefaultStoryNotificationSettingMutationFn = Apollo.MutationFunction<UpdateDefaultStoryNotificationSettingMutation, UpdateDefaultStoryNotificationSettingMutationVariables>;

/**
 * __useUpdateDefaultStoryNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultStoryNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultStoryNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultStoryNotificationSettingMutation, { data, loading, error }] = useUpdateDefaultStoryNotificationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultStoryNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultStoryNotificationSettingMutation, UpdateDefaultStoryNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultStoryNotificationSettingMutation, UpdateDefaultStoryNotificationSettingMutationVariables>(UpdateDefaultStoryNotificationSettingDocument, options);
      }
export type UpdateDefaultStoryNotificationSettingMutationHookResult = ReturnType<typeof useUpdateDefaultStoryNotificationSettingMutation>;
export type UpdateDefaultStoryNotificationSettingMutationResult = Apollo.MutationResult<UpdateDefaultStoryNotificationSettingMutation>;
export type UpdateDefaultStoryNotificationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultStoryNotificationSettingMutation, UpdateDefaultStoryNotificationSettingMutationVariables>;
export const UpdateStoryNotificationSettingDocument = gql`
    mutation updateStoryNotificationSetting($input: UpdateStoryNotificationSettingInput!) {
  updateStoryNotificationSetting(input: $input) {
    storyNotificationSetting {
      id
      isDefault
      includeAnonymous
      playEmail
      collectLeadEmail
      reachEndEmail
      responseEmail
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateStoryNotificationSettingMutationFn = Apollo.MutationFunction<UpdateStoryNotificationSettingMutation, UpdateStoryNotificationSettingMutationVariables>;

/**
 * __useUpdateStoryNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateStoryNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryNotificationSettingMutation, { data, loading, error }] = useUpdateStoryNotificationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStoryNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryNotificationSettingMutation, UpdateStoryNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryNotificationSettingMutation, UpdateStoryNotificationSettingMutationVariables>(UpdateStoryNotificationSettingDocument, options);
      }
export type UpdateStoryNotificationSettingMutationHookResult = ReturnType<typeof useUpdateStoryNotificationSettingMutation>;
export type UpdateStoryNotificationSettingMutationResult = Apollo.MutationResult<UpdateStoryNotificationSettingMutation>;
export type UpdateStoryNotificationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateStoryNotificationSettingMutation, UpdateStoryNotificationSettingMutationVariables>;