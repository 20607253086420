import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaidInvoicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PaidInvoicesQuery = { __typename?: 'RootQueryType', paidInvoices?: Array<{ __typename?: 'Invoice', stripeId?: string | null, status?: string | null, paid?: boolean | null, paidAt?: any | null, total?: number | null, currency?: string | null, hostedInvoiceUrl?: string | null, invoicePdf?: string | null } | null> | null };


export const PaidInvoicesDocument = gql`
    query paidInvoices {
  paidInvoices {
    stripeId
    status
    paid
    paidAt
    total
    currency
    hostedInvoiceUrl
    invoicePdf
  }
}
    `;

/**
 * __usePaidInvoicesQuery__
 *
 * To run a query within a React component, call `usePaidInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaidInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaidInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaidInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<PaidInvoicesQuery, PaidInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaidInvoicesQuery, PaidInvoicesQueryVariables>(PaidInvoicesDocument, options);
      }
export function usePaidInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaidInvoicesQuery, PaidInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaidInvoicesQuery, PaidInvoicesQueryVariables>(PaidInvoicesDocument, options);
        }
export type PaidInvoicesQueryHookResult = ReturnType<typeof usePaidInvoicesQuery>;
export type PaidInvoicesLazyQueryHookResult = ReturnType<typeof usePaidInvoicesLazyQuery>;
export type PaidInvoicesQueryResult = Apollo.QueryResult<PaidInvoicesQuery, PaidInvoicesQueryVariables>;
export function refetchPaidInvoicesQuery(variables?: PaidInvoicesQueryVariables) {
      return { query: PaidInvoicesDocument, variables: variables }
    }