import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetBgImageUploadToEndScreenSettingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.SetBgImageUploadToEndScreenSettingInput;
}>;


export type SetBgImageUploadToEndScreenSettingMutation = { __typename?: 'RootMutationType', setBgImageUploadToEndScreenSetting?: { __typename?: 'EndScreenSettingResult', endScreenSetting?: { __typename?: 'EndScreenSetting', id: string, databaseId: any, title?: string | null, content?: string | null, textColor: string, blurEffect: number, bgType: Types.BackgroundType, bgGradientType: Types.GradientType, bgGradientAngle: number, showCta: boolean, ctaText?: string | null, ctaUrl?: string | null, ctaBgColor?: string | null, ctaTextColor?: string | null, bgImageUpload?: { __typename?: 'ImageUpload', id: string, imageUrl?: string | null } | null, colors: Array<{ __typename?: 'Color', r: number, g: number, b: number, a: number, hex: string }> } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const SetBgImageUploadToEndScreenSettingDocument = gql`
    mutation setBgImageUploadToEndScreenSetting($id: ID!, $input: SetBgImageUploadToEndScreenSettingInput!) {
  setBgImageUploadToEndScreenSetting(id: $id, input: $input) {
    endScreenSetting {
      id
      databaseId
      title
      content
      textColor
      blurEffect
      bgType
      bgGradientType
      bgGradientAngle
      bgImageUpload {
        id
        imageUrl
      }
      colors {
        r
        g
        b
        a
        hex
      }
      showCta
      ctaText
      ctaUrl
      ctaBgColor
      ctaTextColor
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type SetBgImageUploadToEndScreenSettingMutationFn = Apollo.MutationFunction<SetBgImageUploadToEndScreenSettingMutation, SetBgImageUploadToEndScreenSettingMutationVariables>;

/**
 * __useSetBgImageUploadToEndScreenSettingMutation__
 *
 * To run a mutation, you first call `useSetBgImageUploadToEndScreenSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBgImageUploadToEndScreenSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBgImageUploadToEndScreenSettingMutation, { data, loading, error }] = useSetBgImageUploadToEndScreenSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBgImageUploadToEndScreenSettingMutation(baseOptions?: Apollo.MutationHookOptions<SetBgImageUploadToEndScreenSettingMutation, SetBgImageUploadToEndScreenSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBgImageUploadToEndScreenSettingMutation, SetBgImageUploadToEndScreenSettingMutationVariables>(SetBgImageUploadToEndScreenSettingDocument, options);
      }
export type SetBgImageUploadToEndScreenSettingMutationHookResult = ReturnType<typeof useSetBgImageUploadToEndScreenSettingMutation>;
export type SetBgImageUploadToEndScreenSettingMutationResult = Apollo.MutationResult<SetBgImageUploadToEndScreenSettingMutation>;
export type SetBgImageUploadToEndScreenSettingMutationOptions = Apollo.BaseMutationOptions<SetBgImageUploadToEndScreenSettingMutation, SetBgImageUploadToEndScreenSettingMutationVariables>;