import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoriesUnreadCountQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type StoriesUnreadCountQuery = { __typename?: 'RootQueryType', fetchStoriesUnreadCount: Array<{ __typename?: 'StoryUnreadCount', storyId: string, storySid: string, storyDatabaseId: any, unreadCount: number }> };


export const StoriesUnreadCountDocument = gql`
    query storiesUnreadCount($ids: [ID!]) {
  fetchStoriesUnreadCount(storyIds: $ids) {
    storyId
    storySid
    storyDatabaseId
    unreadCount
  }
}
    `;

/**
 * __useStoriesUnreadCountQuery__
 *
 * To run a query within a React component, call `useStoriesUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesUnreadCountQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useStoriesUnreadCountQuery(baseOptions?: Apollo.QueryHookOptions<StoriesUnreadCountQuery, StoriesUnreadCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoriesUnreadCountQuery, StoriesUnreadCountQueryVariables>(StoriesUnreadCountDocument, options);
      }
export function useStoriesUnreadCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoriesUnreadCountQuery, StoriesUnreadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoriesUnreadCountQuery, StoriesUnreadCountQueryVariables>(StoriesUnreadCountDocument, options);
        }
export type StoriesUnreadCountQueryHookResult = ReturnType<typeof useStoriesUnreadCountQuery>;
export type StoriesUnreadCountLazyQueryHookResult = ReturnType<typeof useStoriesUnreadCountLazyQuery>;
export type StoriesUnreadCountQueryResult = Apollo.QueryResult<StoriesUnreadCountQuery, StoriesUnreadCountQueryVariables>;
export function refetchStoriesUnreadCountQuery(variables?: StoriesUnreadCountQueryVariables) {
      return { query: StoriesUnreadCountDocument, variables: variables }
    }