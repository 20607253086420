import { FetchAccessToken } from 'apollo-link-token-refresh';
import dayjs from 'dayjs';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { authTokenStore } from '../zustand/auth-token-store';

export const setRefreshToken = (refreshToken: string) => {
  const { setState } = authTokenStore;
  setState({ refreshToken });
};

export const getRefreshToken = () => {
  return authTokenStore.getState().refreshToken;
};

export const setAccessToken = (accessToken: string) => {
  const { setState } = authTokenStore;
  setState({ accessToken });
};

export const getAccessToken = () => {
  return authTokenStore.getState().accessToken;
};

export const isTokenExpired = () => {
  const accessToken = getAccessToken();
  try {
    const accessTokenDecrypted = jwtDecode<JwtPayload>(accessToken as string);
    const { exp } = accessTokenDecrypted as any;
    if (typeof exp === 'undefined') {
      return false;
    }
    const expObj = dayjs.unix(exp);
    return dayjs().isAfter(expObj);
  } catch (error) {
    return false;
  }
};

export const checkAuthState = async () => {
  const url = `/auth/state`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Origin: process.env.NEXT_PUBLIC_APP_URL,
      } as HeadersInit,
    });
    if (response.status === 401) {
      return false;
    }
    const json = await response.json();
    setAccessToken(json.accessToken);
    setRefreshToken(json.refreshToken);
  } catch (error) {}
};

export const fetchAccessToken: FetchAccessToken = async () => {
  const url = `/auth/refresh`;
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      // "Content-Type": "application/json",
      Accept: 'application/json',
      authorization: `Bearer ${getAccessToken()}`,
      Origin: process.env.NEXT_PUBLIC_APP_URL,
      'refresh-token': getRefreshToken(),
    } as HeadersInit,
  });
  return response.json();
};

export const isTokenValidOrUndefined = async () => {
  return !isTokenExpired() || typeof getAccessToken() !== 'string';
};

export const handleFetch = (tokens: {
  accessToken: string | null;
  refreshToken: string | null;
}) => {
  const { accessToken, refreshToken } = tokens;
  const { setState } = authTokenStore;
  setState({ accessToken, refreshToken });
};

export const handleResponse =
  (_operation: any, _accessTokenField: any) => (response: any) => {
    if (!response) return { accessToken: null, refreshToken: null };
    return { tokens: response };
  };

export const logoutUser = async () => {
  const url = `/auth/logout`;
  try {
    await fetch(url, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Origin: process.env.NEXT_PUBLIC_APP_URL,
      } as HeadersInit,
    });
    const { setState } = authTokenStore;
    setState({ accessToken: null, refreshToken: null });
  } catch (e) {
    console.error(e);
  }
};
