import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyShopifyInstallationRequestMutationVariables = Types.Exact<{
  input: Types.VerifyShopifyInstallationRequestInput;
}>;


export type VerifyShopifyInstallationRequestMutation = { __typename?: 'RootMutationType', verifyShopifyInstallationRequest?: { __typename?: 'VerifyShopifyInstallationRequestResult', success?: boolean | null, nonce?: string | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const VerifyShopifyInstallationRequestDocument = gql`
    mutation verifyShopifyInstallationRequest($input: VerifyShopifyInstallationRequestInput!) {
  verifyShopifyInstallationRequest(input: $input) {
    success
    nonce
    userErrors {
      field
      message
    }
  }
}
    `;
export type VerifyShopifyInstallationRequestMutationFn = Apollo.MutationFunction<VerifyShopifyInstallationRequestMutation, VerifyShopifyInstallationRequestMutationVariables>;

/**
 * __useVerifyShopifyInstallationRequestMutation__
 *
 * To run a mutation, you first call `useVerifyShopifyInstallationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyShopifyInstallationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyShopifyInstallationRequestMutation, { data, loading, error }] = useVerifyShopifyInstallationRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyShopifyInstallationRequestMutation(baseOptions?: Apollo.MutationHookOptions<VerifyShopifyInstallationRequestMutation, VerifyShopifyInstallationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyShopifyInstallationRequestMutation, VerifyShopifyInstallationRequestMutationVariables>(VerifyShopifyInstallationRequestDocument, options);
      }
export type VerifyShopifyInstallationRequestMutationHookResult = ReturnType<typeof useVerifyShopifyInstallationRequestMutation>;
export type VerifyShopifyInstallationRequestMutationResult = Apollo.MutationResult<VerifyShopifyInstallationRequestMutation>;
export type VerifyShopifyInstallationRequestMutationOptions = Apollo.BaseMutationOptions<VerifyShopifyInstallationRequestMutation, VerifyShopifyInstallationRequestMutationVariables>;