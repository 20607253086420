import React, { ReactElement } from 'react';
import { createPortal } from 'react-dom';

// Define a generic type for the component props
type ComponentProps = Record<string, any>;

// Explicitly type the HOC with a generic to handle props correctly
export const Overlay =
  <P extends ComponentProps>(Component: React.FC<P>) =>
  (props: P): ReactElement | null => {
    const overlayRootEl = document.getElementById('overlay-root');
    if (!overlayRootEl) {
      return null;
    }
    return createPortal(<Component {...props} />, overlayRootEl);
  };
