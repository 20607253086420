import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReorderPageChoicesMutationVariables = Types.Exact<{
  input: Types.ReorderPageChoicesInput;
}>;


export type ReorderPageChoicesMutation = { __typename?: 'RootMutationType', reorderPageChoices?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const ReorderPageChoicesDocument = gql`
    mutation reorderPageChoices($input: ReorderPageChoicesInput!) {
  reorderPageChoices(input: $input) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type ReorderPageChoicesMutationFn = Apollo.MutationFunction<ReorderPageChoicesMutation, ReorderPageChoicesMutationVariables>;

/**
 * __useReorderPageChoicesMutation__
 *
 * To run a mutation, you first call `useReorderPageChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderPageChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderPageChoicesMutation, { data, loading, error }] = useReorderPageChoicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderPageChoicesMutation(baseOptions?: Apollo.MutationHookOptions<ReorderPageChoicesMutation, ReorderPageChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderPageChoicesMutation, ReorderPageChoicesMutationVariables>(ReorderPageChoicesDocument, options);
      }
export type ReorderPageChoicesMutationHookResult = ReturnType<typeof useReorderPageChoicesMutation>;
export type ReorderPageChoicesMutationResult = Apollo.MutationResult<ReorderPageChoicesMutation>;
export type ReorderPageChoicesMutationOptions = Apollo.BaseMutationOptions<ReorderPageChoicesMutation, ReorderPageChoicesMutationVariables>;