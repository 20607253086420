import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadChunkMutationVariables = Types.Exact<{
  input: Types.UploadChunkInput;
}>;


export type UploadChunkMutation = { __typename?: 'RootMutationType', uploadChunk?: { __typename?: 'UploadState', uploadId: string, tempId: string, uploadPath: string, uploaded: boolean, uploadParts: Array<{ __typename?: 'UploadPart', sequence: number, etag: string } | null> } | null };


export const UploadChunkDocument = gql`
    mutation uploadChunk($input: UploadChunkInput!) {
  uploadChunk(input: $input) {
    uploadId
    tempId
    uploadPath
    uploaded
    uploadParts {
      sequence
      etag
    }
  }
}
    `;
export type UploadChunkMutationFn = Apollo.MutationFunction<UploadChunkMutation, UploadChunkMutationVariables>;

/**
 * __useUploadChunkMutation__
 *
 * To run a mutation, you first call `useUploadChunkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadChunkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadChunkMutation, { data, loading, error }] = useUploadChunkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadChunkMutation(baseOptions?: Apollo.MutationHookOptions<UploadChunkMutation, UploadChunkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadChunkMutation, UploadChunkMutationVariables>(UploadChunkDocument, options);
      }
export type UploadChunkMutationHookResult = ReturnType<typeof useUploadChunkMutation>;
export type UploadChunkMutationResult = Apollo.MutationResult<UploadChunkMutation>;
export type UploadChunkMutationOptions = Apollo.BaseMutationOptions<UploadChunkMutation, UploadChunkMutationVariables>;