import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWidgetSettingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateWidgetSettingInput;
}>;


export type UpdateWidgetSettingMutation = { __typename?: 'RootMutationType', updateWidgetSetting?: { __typename?: 'WidgetSettingResult', widgetSetting?: { __typename?: 'WidgetSetting', id: string, shape: Types.WidgetShape, size: Types.WidgetSize, allowHiding: boolean, borderColor?: string | null, bottomMargin?: number | null, caption: string, displayOnDesktop: boolean, displayOnMobile: boolean, leftAligned: boolean, showBorder: boolean, showTextBubble: boolean, sideMargin?: number | null, textBubbleBgColor?: string | null, textBubbleTextColor?: string | null, textBubbleMessage?: string | null, useVideoPreview: boolean, useFullScreen: boolean, initialPlaybackMode: Types.InitialPlaybackMode, widgetAlignment: Types.WidgetAlignment, widgetBorderRadius: number, widgetBorderThickness: number, widgetItemsPerRow: number, widgetItemWidth: number, widgetItemHeight: number, widgetItemGap: number, widgetIsResponsive: boolean } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const UpdateWidgetSettingDocument = gql`
    mutation updateWidgetSetting($id: ID!, $input: UpdateWidgetSettingInput!) {
  updateWidgetSetting(id: $id, input: $input) {
    widgetSetting {
      id
      shape
      size
      allowHiding
      borderColor
      bottomMargin
      caption
      displayOnDesktop
      displayOnMobile
      leftAligned
      showBorder
      showTextBubble
      sideMargin
      textBubbleBgColor
      textBubbleTextColor
      textBubbleMessage
      useVideoPreview
      useFullScreen
      initialPlaybackMode
      widgetAlignment
      widgetBorderRadius
      widgetBorderThickness
      widgetItemsPerRow
      widgetItemWidth
      widgetItemHeight
      widgetItemGap
      widgetIsResponsive
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateWidgetSettingMutationFn = Apollo.MutationFunction<UpdateWidgetSettingMutation, UpdateWidgetSettingMutationVariables>;

/**
 * __useUpdateWidgetSettingMutation__
 *
 * To run a mutation, you first call `useUpdateWidgetSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWidgetSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWidgetSettingMutation, { data, loading, error }] = useUpdateWidgetSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWidgetSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWidgetSettingMutation, UpdateWidgetSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWidgetSettingMutation, UpdateWidgetSettingMutationVariables>(UpdateWidgetSettingDocument, options);
      }
export type UpdateWidgetSettingMutationHookResult = ReturnType<typeof useUpdateWidgetSettingMutation>;
export type UpdateWidgetSettingMutationResult = Apollo.MutationResult<UpdateWidgetSettingMutation>;
export type UpdateWidgetSettingMutationOptions = Apollo.BaseMutationOptions<UpdateWidgetSettingMutation, UpdateWidgetSettingMutationVariables>;