interface Props {
  children: React.ReactNode;
}

export const SquareGridBackground = ({ children }: Props) => {
  return (
    <span
      className="bg-gray-100 absolute z-0 top-0 left-0"
      style={{
        backgroundImage:
          'repeating-linear-gradient(45deg, #ddd 25%, transparent 25%, transparent 75%, #ddd 75%, #ddd), repeating-linear-gradient(45deg, #ddd 25%, #fff 25%, #fff 75%, #ddd 75%, #ddd)',
        backgroundPosition: '0 0, 6px 6px',
        backgroundSize: '12px 12px',
        height: '100%',
        width: '100%',
      }}
    >
      {children}
    </span>
  );
};
