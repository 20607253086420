import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteInitiationItemMutationVariables = Types.Exact<{
  input: Types.DeleteInitiationItemInput;
}>;


export type DeleteInitiationItemMutation = { __typename?: 'RootMutationType', deleteInitiationItem?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const DeleteInitiationItemDocument = gql`
    mutation deleteInitiationItem($input: DeleteInitiationItemInput!) {
  deleteInitiationItem(input: $input) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteInitiationItemMutationFn = Apollo.MutationFunction<DeleteInitiationItemMutation, DeleteInitiationItemMutationVariables>;

/**
 * __useDeleteInitiationItemMutation__
 *
 * To run a mutation, you first call `useDeleteInitiationItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInitiationItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInitiationItemMutation, { data, loading, error }] = useDeleteInitiationItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInitiationItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInitiationItemMutation, DeleteInitiationItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInitiationItemMutation, DeleteInitiationItemMutationVariables>(DeleteInitiationItemDocument, options);
      }
export type DeleteInitiationItemMutationHookResult = ReturnType<typeof useDeleteInitiationItemMutation>;
export type DeleteInitiationItemMutationResult = Apollo.MutationResult<DeleteInitiationItemMutation>;
export type DeleteInitiationItemMutationOptions = Apollo.BaseMutationOptions<DeleteInitiationItemMutation, DeleteInitiationItemMutationVariables>;