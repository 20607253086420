import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TextResponsesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.TextResponseFilter>;
}>;


export type TextResponsesQuery = { __typename?: 'RootQueryType', textResponses?: { __typename?: 'TextResponseConnection', totalCount: number, edges?: Array<{ __typename?: 'TextResponseEdge', node?: { __typename?: 'TextResponse', id: string, question: string, text: string, insertedAt: string, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const TextResponsesDocument = gql`
    query textResponses($after: String, $before: String, $first: Int, $filter: TextResponseFilter) {
  textResponses(after: $after, before: $before, first: $first, filter: $filter) {
    edges {
      node {
        id
        question
        text
        insertedAt
        contact {
          id
          email
          firstName
          lastName
        }
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useTextResponsesQuery__
 *
 * To run a query within a React component, call `useTextResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextResponsesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTextResponsesQuery(baseOptions?: Apollo.QueryHookOptions<TextResponsesQuery, TextResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TextResponsesQuery, TextResponsesQueryVariables>(TextResponsesDocument, options);
      }
export function useTextResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TextResponsesQuery, TextResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TextResponsesQuery, TextResponsesQueryVariables>(TextResponsesDocument, options);
        }
export type TextResponsesQueryHookResult = ReturnType<typeof useTextResponsesQuery>;
export type TextResponsesLazyQueryHookResult = ReturnType<typeof useTextResponsesLazyQuery>;
export type TextResponsesQueryResult = Apollo.QueryResult<TextResponsesQuery, TextResponsesQueryVariables>;
export function refetchTextResponsesQuery(variables?: TextResponsesQueryVariables) {
      return { query: TextResponsesDocument, variables: variables }
    }