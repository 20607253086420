import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteChoiceLogicMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteChoiceLogicMutation = { __typename?: 'RootMutationType', deleteChoiceLogic?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const DeleteChoiceLogicDocument = gql`
    mutation deleteChoiceLogic($id: ID!) {
  deleteChoiceLogic(id: $id) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteChoiceLogicMutationFn = Apollo.MutationFunction<DeleteChoiceLogicMutation, DeleteChoiceLogicMutationVariables>;

/**
 * __useDeleteChoiceLogicMutation__
 *
 * To run a mutation, you first call `useDeleteChoiceLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChoiceLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChoiceLogicMutation, { data, loading, error }] = useDeleteChoiceLogicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChoiceLogicMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChoiceLogicMutation, DeleteChoiceLogicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChoiceLogicMutation, DeleteChoiceLogicMutationVariables>(DeleteChoiceLogicDocument, options);
      }
export type DeleteChoiceLogicMutationHookResult = ReturnType<typeof useDeleteChoiceLogicMutation>;
export type DeleteChoiceLogicMutationResult = Apollo.MutationResult<DeleteChoiceLogicMutation>;
export type DeleteChoiceLogicMutationOptions = Apollo.BaseMutationOptions<DeleteChoiceLogicMutation, DeleteChoiceLogicMutationVariables>;