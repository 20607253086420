import { NextRouter } from 'next/router';

import { GuestOnlyRoutes } from './route-constraints';

export const isLoginNeededRoute = (passedRouter: NextRouter) => {
  const shareRouteRe = /\/share/gi;
  if (
    !GuestOnlyRoutes.includes(passedRouter.pathname) &&
    !shareRouteRe.test(passedRouter.asPath)
  ) {
    return true;
  }
};
