import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { ContactFragmentOnContactFeedFragmentDoc } from '../../../crm/contacts/__generated__/contact-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateContentMutationVariables = Types.Exact<{
  input: Types.CreateContentInput;
}>;


export type CreateContentMutation = { __typename?: 'RootMutationType', createContent?: { __typename?: 'ContentResult', content?: { __typename?: 'Content', id: string, type: Types.ContentType, groupType: Types.ContentGroupType, body?: any | null, label?: string | null, plainDescription?: string | null, embedLink?: string | null, providerName?: string | null, videoContain?: boolean | null, oembedResult?: any | null, file?: { __typename?: 'FileUpload', id: string, name?: string | null, size?: number | null, pageCount?: number | null, mimeType?: string | null, fileUrl?: string | null, insertedAt?: any | null } | null, video?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, alternativeText?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, insertedAt?: any | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null } | null, narration?: { __typename?: 'ContentNarration', title?: string | null, type?: Types.NarrationType | null, video?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, insertedAt?: any | null } | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateContentDocument = gql`
    mutation createContent($input: CreateContentInput!) {
  createContent(input: $input) {
    content {
      id
      type
      groupType
      body
      label
      plainDescription
      embedLink
      providerName
      videoContain
      oembedResult
      file {
        id
        name
        size
        pageCount
        mimeType
        fileUrl
        insertedAt
      }
      video {
        id
        rawUrl
        alternativeText
        transcodedUrl
        previewUrl
        streamUrl
        thumbUrl
        gifUrl
        gifWithIconUrl
        insertedAt
        contact {
          ...ContactFragmentOnContactFeed
        }
      }
      narration {
        title
        type
        video {
          id
          rawUrl
          transcodedUrl
          previewUrl
          streamUrl
          gifUrl
          gifWithIconUrl
          insertedAt
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}
    ${ContactFragmentOnContactFeedFragmentDoc}`;
export type CreateContentMutationFn = Apollo.MutationFunction<CreateContentMutation, CreateContentMutationVariables>;

/**
 * __useCreateContentMutation__
 *
 * To run a mutation, you first call `useCreateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentMutation, { data, loading, error }] = useCreateContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateContentMutation, CreateContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContentMutation, CreateContentMutationVariables>(CreateContentDocument, options);
      }
export type CreateContentMutationHookResult = ReturnType<typeof useCreateContentMutation>;
export type CreateContentMutationResult = Apollo.MutationResult<CreateContentMutation>;
export type CreateContentMutationOptions = Apollo.BaseMutationOptions<CreateContentMutation, CreateContentMutationVariables>;