import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryPagesStatsQueryVariables = Types.Exact<{
  filter: Types.StoryPagesStatsFilter;
}>;


export type StoryPagesStatsQuery = { __typename?: 'RootQueryType', storyPagesStats?: { __typename?: 'StoryPagesStatsResult', storyPagesStats: Array<{ __typename?: 'StoryPageStats', storyPage: { __typename?: 'InitiationItem', id: string, databaseId: any, title: string, order: number, type: Types.InitiationItemType, content?: { __typename?: 'Content', id: string, type: Types.ContentType, groupType: Types.ContentGroupType } | null }, stats: { __typename?: 'BasicStoryPageStats', pageviews: number, visitDuration: number, choiceClicks: number, ctaClicks: number, videoCollected: number, textCollected: number, productLinkClicks: number } }> } | null };


export const StoryPagesStatsDocument = gql`
    query storyPagesStats($filter: StoryPagesStatsFilter!) {
  storyPagesStats(filter: $filter) {
    storyPagesStats {
      storyPage {
        id
        databaseId
        title
        order
        type
        content {
          id
          type
          groupType
        }
      }
      stats {
        pageviews
        visitDuration
        choiceClicks
        ctaClicks
        videoCollected
        textCollected
        productLinkClicks
      }
    }
  }
}
    `;

/**
 * __useStoryPagesStatsQuery__
 *
 * To run a query within a React component, call `useStoryPagesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryPagesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryPagesStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStoryPagesStatsQuery(baseOptions: Apollo.QueryHookOptions<StoryPagesStatsQuery, StoryPagesStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryPagesStatsQuery, StoryPagesStatsQueryVariables>(StoryPagesStatsDocument, options);
      }
export function useStoryPagesStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryPagesStatsQuery, StoryPagesStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryPagesStatsQuery, StoryPagesStatsQueryVariables>(StoryPagesStatsDocument, options);
        }
export type StoryPagesStatsQueryHookResult = ReturnType<typeof useStoryPagesStatsQuery>;
export type StoryPagesStatsLazyQueryHookResult = ReturnType<typeof useStoryPagesStatsLazyQuery>;
export type StoryPagesStatsQueryResult = Apollo.QueryResult<StoryPagesStatsQuery, StoryPagesStatsQueryVariables>;
export function refetchStoryPagesStatsQuery(variables: StoryPagesStatsQueryVariables) {
      return { query: StoryPagesStatsDocument, variables: variables }
    }