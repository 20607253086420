import '../styles/globals.css';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/700.css';
import '@fontsource/plus-jakarta-sans/800.css';

import { ApolloProvider } from '@apollo/client';
import { GoogleAnalytics } from '@next/third-parties/google';
import { AuthProvider, useAuth } from '@sharelo-lib/auth';
import { apolloClient } from '@sharelo-lib/data-access';
import { GeoProvider } from '@sharelo-lib/feature/location';
import { SEO } from '@sharelo-lib/seo';
import { AppLoading } from '@sharelo-lib/ui';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { appWithTranslation, useTranslation } from 'next-i18next';
import PlausibleProvider from 'next-plausible';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { hotjar } from 'react-hotjar';
import { ToastProvider } from 'react-toast-notifications';
import { register } from 'swiper/element/bundle';

import nextI18NextConfig from '../../../../next-i18next.config';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(calendar);

register();

const Wrap = ({ Component, pageProps }: AppProps) => {
  const { t } = useTranslation();

  const { authLoading, loaded } = useAuth();

  useEffect(() => {
    if (window && process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
      hotjar.initialize(3273792, 6, false);
    }
  }, []);

  let headPart = (
    <SEO title={t('seo.title.default')} desc={t('seo.description.default')} />
  );
  if (pageProps.hasOwnProperty('seoProps')) {
    headPart = <SEO {...pageProps.seoProps} />;
  }
  if (!loaded || authLoading) {
    return (
      <>
        {headPart}
        <AppLoading />
      </>
    );
  }
  return (
    <>
      {headPart}
      <Script
        id="iframely"
        src="//cdn.iframe.ly/embed.js"
        strategy="lazyOnload"
      />
      {process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? (
        <>
          <Script
            type="text/javascript"
            defer
            data-plerdy_code="1"
            id="plerdy"
            dangerouslySetInnerHTML={{
              __html: `
            var _protocol="https:"==document.location.protocol?" https://":" http://";
            _site_hash_code = "fc75a833799fef1981ecc6c84f7f72e0",_suid=41112, plerdyScript=document.createElement("script");
            plerdyScript.setAttribute("defer",""),plerdyScript.dataset.plerdymainscript="plerdymainscript",
            plerdyScript.src="https://d.plerdy.com/public/js/click/main.js?v="+Math.random();
            var plerdymainscript=document.querySelector("[data-plerdymainscript='plerdymainscript']");
            plerdymainscript&&plerdymainscript.parentNode.removeChild(plerdymainscript);
            try{document.head.appendChild(plerdyScript)}catch(t){console.log(t,"unable add script tag")}
                  `,
            }}
          />
        </>
      ) : null}
      <Component {...pageProps} />
    </>
  );
};

const MyApp = (props: AppProps) => {
  if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
    return (
      <CookiesProvider>
        <Script id="dashly-script">
          {`
            !function(){function t(t,e){return function(){window.dashlyasync.push(t,arguments)}}if("undefined"==typeof dashly){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://cdn.dashly.app/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.dashly={},window.dashlyasync=[],dashly.settings={};for(var n=["connect","track","identify","auth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)dashly[n[a]]=t(n[a])}}(),dashly.connect("5981-e585084bf61d404a0bcad11f5a6");
          `}
        </Script>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <GeoProvider>
              <PlausibleProvider domain="app.shareloapp.com">
                <ToastProvider>
                  <Wrap {...props} />
                  <GoogleAnalytics gaId="G-FE3RWTDBTR" />
                </ToastProvider>
              </PlausibleProvider>
            </GeoProvider>
          </AuthProvider>
        </ApolloProvider>
      </CookiesProvider>
    );
  }
  return (
    <CookiesProvider>
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <GeoProvider>
            <PlausibleProvider domain="app.shareloapp.com">
              <ToastProvider>
                <Wrap {...props} />
              </ToastProvider>
            </PlausibleProvider>
          </GeoProvider>
        </AuthProvider>
      </ApolloProvider>
    </CookiesProvider>
  );
};

export default appWithTranslation(MyApp, nextI18NextConfig);
