import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMemberAsAdminMutationVariables = Types.Exact<{
  input: Types.UpdateMemberAsAdminInput;
}>;


export type UpdateMemberAsAdminMutation = { __typename?: 'RootMutationType', updateMemberAsAdmin?: { __typename?: 'MemberResult', member?: { __typename?: 'Member', id: string, email: string, displayName?: string | null, avatarUrl?: string | null, joinedAt?: any | null, role: string } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateMemberAsAdminDocument = gql`
    mutation updateMemberAsAdmin($input: UpdateMemberAsAdminInput!) {
  updateMemberAsAdmin(input: $input) {
    member {
      id
      email
      displayName
      avatarUrl
      joinedAt
      role
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateMemberAsAdminMutationFn = Apollo.MutationFunction<UpdateMemberAsAdminMutation, UpdateMemberAsAdminMutationVariables>;

/**
 * __useUpdateMemberAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateMemberAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberAsAdminMutation, { data, loading, error }] = useUpdateMemberAsAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberAsAdminMutation, UpdateMemberAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberAsAdminMutation, UpdateMemberAsAdminMutationVariables>(UpdateMemberAsAdminDocument, options);
      }
export type UpdateMemberAsAdminMutationHookResult = ReturnType<typeof useUpdateMemberAsAdminMutation>;
export type UpdateMemberAsAdminMutationResult = Apollo.MutationResult<UpdateMemberAsAdminMutation>;
export type UpdateMemberAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateMemberAsAdminMutation, UpdateMemberAsAdminMutationVariables>;