import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { StoryNotificationReceiverOnStoryEditorFragmentDoc } from './story-notification-receiver-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateStoryNotificationReceiverMutationVariables = Types.Exact<{
  input: Types.CreateStoryNotificationReceiverInput;
}>;


export type CreateStoryNotificationReceiverMutation = { __typename?: 'RootMutationType', createStoryNotificationReceiver: { __typename?: 'StoryNotificationReceiverResult', storyNotificationReceiver?: { __typename?: 'StoryNotificationReceiver', id: string, member: { __typename?: 'Member', id: string, displayName?: string | null, avatarUrl?: string | null } } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } };


export const CreateStoryNotificationReceiverDocument = gql`
    mutation createStoryNotificationReceiver($input: CreateStoryNotificationReceiverInput!) {
  createStoryNotificationReceiver(input: $input) {
    storyNotificationReceiver {
      ...StoryNotificationReceiverOnStoryEditor
    }
    userErrors {
      field
      message
    }
  }
}
    ${StoryNotificationReceiverOnStoryEditorFragmentDoc}`;
export type CreateStoryNotificationReceiverMutationFn = Apollo.MutationFunction<CreateStoryNotificationReceiverMutation, CreateStoryNotificationReceiverMutationVariables>;

/**
 * __useCreateStoryNotificationReceiverMutation__
 *
 * To run a mutation, you first call `useCreateStoryNotificationReceiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryNotificationReceiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryNotificationReceiverMutation, { data, loading, error }] = useCreateStoryNotificationReceiverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStoryNotificationReceiverMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoryNotificationReceiverMutation, CreateStoryNotificationReceiverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoryNotificationReceiverMutation, CreateStoryNotificationReceiverMutationVariables>(CreateStoryNotificationReceiverDocument, options);
      }
export type CreateStoryNotificationReceiverMutationHookResult = ReturnType<typeof useCreateStoryNotificationReceiverMutation>;
export type CreateStoryNotificationReceiverMutationResult = Apollo.MutationResult<CreateStoryNotificationReceiverMutation>;
export type CreateStoryNotificationReceiverMutationOptions = Apollo.BaseMutationOptions<CreateStoryNotificationReceiverMutation, CreateStoryNotificationReceiverMutationVariables>;