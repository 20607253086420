import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteStoryNotificationReceiverMutationVariables = Types.Exact<{
  input: Types.DeleteStoryNotificationReceiverInput;
}>;


export type DeleteStoryNotificationReceiverMutation = { __typename?: 'RootMutationType', deleteStoryNotificationReceiver: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } };


export const DeleteStoryNotificationReceiverDocument = gql`
    mutation deleteStoryNotificationReceiver($input: DeleteStoryNotificationReceiverInput!) {
  deleteStoryNotificationReceiver(input: $input) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteStoryNotificationReceiverMutationFn = Apollo.MutationFunction<DeleteStoryNotificationReceiverMutation, DeleteStoryNotificationReceiverMutationVariables>;

/**
 * __useDeleteStoryNotificationReceiverMutation__
 *
 * To run a mutation, you first call `useDeleteStoryNotificationReceiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryNotificationReceiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryNotificationReceiverMutation, { data, loading, error }] = useDeleteStoryNotificationReceiverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStoryNotificationReceiverMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryNotificationReceiverMutation, DeleteStoryNotificationReceiverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoryNotificationReceiverMutation, DeleteStoryNotificationReceiverMutationVariables>(DeleteStoryNotificationReceiverDocument, options);
      }
export type DeleteStoryNotificationReceiverMutationHookResult = ReturnType<typeof useDeleteStoryNotificationReceiverMutation>;
export type DeleteStoryNotificationReceiverMutationResult = Apollo.MutationResult<DeleteStoryNotificationReceiverMutation>;
export type DeleteStoryNotificationReceiverMutationOptions = Apollo.BaseMutationOptions<DeleteStoryNotificationReceiverMutation, DeleteStoryNotificationReceiverMutationVariables>;