import { useCallback, useEffect } from 'react';

export const useOutsideClickHandler = (
  ref: any,
  isOpen: any,
  callback?: any,
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target) && isOpen) {
        callback?.();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, isOpen, ref]);
};

export const useOutsideClickHandlerForRefs = (
  refs: any,
  isOpen: any,
  callback?: any,
) => {
  const handleClickOutside = useCallback(
    (event: any) => {
      let shouldFire = true;
      for (const ref of refs) {
        if (ref.current && ref.current.contains(event.target)) {
          shouldFire = false;
        }
      }
      if (shouldFire && isOpen && typeof callback === 'function') {
        callback?.();
      }
    },
    [callback, isOpen, refs],
  );

  const inactivate = () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const reactivate = () => {
    document.addEventListener('mousedown', handleClickOutside);
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, handleClickOutside, isOpen, refs]);

  return {
    inactivate,
    reactivate,
  };
};
