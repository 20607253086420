import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyEmailMutationVariables = Types.Exact<{
  input: Types.VerifyEmailInput;
}>;


export type VerifyEmailMutation = { __typename?: 'RootMutationType', verifyEmail?: { __typename?: 'AuthResult', accessToken?: string | null, refreshToken?: string | null, viewer?: { __typename?: 'User', id: string, databaseId: any, email?: string | null, emailVerified?: boolean | null, displayName?: string | null, onboardingStatus: Types.OnboardingStatus, role: string, tenantCount: number, activeTenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, features: Array<Types.PlanFeature> } | null, member?: { __typename?: 'Member', id: string, avatarUrl?: string | null, role: string, displayName?: string | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const VerifyEmailDocument = gql`
    mutation verifyEmail($input: VerifyEmailInput!) {
  verifyEmail(input: $input) {
    viewer {
      id
      databaseId
      email
      emailVerified
      displayName
      onboardingStatus
      role
      tenantCount
      activeTenant {
        id
        databaseId
        commerceActivated
        storyCount
        name
        features
      }
      member {
        id
        avatarUrl
        role
        displayName
      }
    }
    userErrors {
      field
      message
    }
    accessToken
    refreshToken
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;