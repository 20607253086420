import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantsForDashboardSidebarQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TenantsForDashboardSidebarQuery = { __typename?: 'RootQueryType', tenants?: Array<{ __typename?: 'Tenant', id: string, iconUrl?: string | null, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, isPersonal?: boolean | null, isActive?: boolean | null, exceededMaxStorage?: boolean | null, canUseTrial?: boolean | null, canUseCoupon?: boolean | null, storageUsage?: number | null, memberCount?: number | null, monthlyPlays?: number | null, monthlyImpressions?: number | null, maxSeats?: number | null, maxStorage?: number | null, maxMonthlyPlays?: number | null, maxMonthlyImpressions?: number | null, maxLifetimePlays?: number | null, features: Array<Types.PlanFeature>, plan?: { __typename?: 'Plan', id?: string | null, isTrial?: boolean | null, isActive?: boolean | null, slug?: string | null, name?: string | null, nameI18nKey?: string | null } | null } | null> | null };


export const TenantsForDashboardSidebarDocument = gql`
    query tenantsForDashboardSidebar {
  tenants {
    id
    iconUrl
    databaseId
    commerceActivated
    storyCount
    name
    isPersonal
    isActive
    exceededMaxStorage
    canUseTrial
    canUseCoupon
    storageUsage
    memberCount
    monthlyPlays
    monthlyImpressions
    maxSeats
    maxStorage
    maxMonthlyPlays
    maxMonthlyImpressions
    maxLifetimePlays
    plan {
      id
      isTrial
      isActive
      slug
      name
      nameI18nKey
    }
    features
  }
}
    `;

/**
 * __useTenantsForDashboardSidebarQuery__
 *
 * To run a query within a React component, call `useTenantsForDashboardSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantsForDashboardSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantsForDashboardSidebarQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantsForDashboardSidebarQuery(baseOptions?: Apollo.QueryHookOptions<TenantsForDashboardSidebarQuery, TenantsForDashboardSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantsForDashboardSidebarQuery, TenantsForDashboardSidebarQueryVariables>(TenantsForDashboardSidebarDocument, options);
      }
export function useTenantsForDashboardSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantsForDashboardSidebarQuery, TenantsForDashboardSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantsForDashboardSidebarQuery, TenantsForDashboardSidebarQueryVariables>(TenantsForDashboardSidebarDocument, options);
        }
export type TenantsForDashboardSidebarQueryHookResult = ReturnType<typeof useTenantsForDashboardSidebarQuery>;
export type TenantsForDashboardSidebarLazyQueryHookResult = ReturnType<typeof useTenantsForDashboardSidebarLazyQuery>;
export type TenantsForDashboardSidebarQueryResult = Apollo.QueryResult<TenantsForDashboardSidebarQuery, TenantsForDashboardSidebarQueryVariables>;
export function refetchTenantsForDashboardSidebarQuery(variables?: TenantsForDashboardSidebarQueryVariables) {
      return { query: TenantsForDashboardSidebarDocument, variables: variables }
    }