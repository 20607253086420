import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Facebook from './Facebook';
import Twitter from './Twitter';

const ogImg = '/images/sharelo_og_v1.png';

interface Props {
  title: string;
  desc: string;
  banner?: string;
  article?: boolean;
}

const getSiteUrl = (locale: string) => {
  switch (locale) {
    case 'en':
      return process.env.NEXT_PUBLIC_APP_URL;
    case 'ja':
      return `${process.env.NEXT_PUBLIC_APP_URL}/ja`;
    default:
      return process.env.NEXT_PUBLIC_APP_URL;
  }
};

const getOgLanguage = (language: string) => {
  switch (language) {
    case 'en':
      return 'en_US';
    case 'ja':
      return 'ja_JP';
    default:
      return 'en_US';
  }
};

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

export const SEO = (props: Props) => {
  const { t } = useTranslation();
  const { title = null, desc = null, banner = null, article = false } = props;
  const defaultTitle = t('seo.title.default');
  const defaultDescription = t('seo.description.default');
  const headline = t('headline');
  const router = useRouter();
  const language = router.locale;
  const path = router.asPath;
  const siteUrl = getSiteUrl(language as string);
  const ogLanguage = getOgLanguage(language as string);
  const defaultBanner = `${siteUrl}${ogImg}`;

  const seo = {
    title: title || defaultTitle,
    headline,
    description: desc || defaultDescription,
    image: banner || defaultBanner,
    url: `${siteUrl}${path || ''}`,
  };

  return (
    <>
      <Head>
        <meta
          key="meta-viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          key="meta-compatible"
          httpEquiv="X-UA-Compatible"
          content="IE=edge"
        />
        <meta
          key="content-type-text"
          httpEquiv="Content-Type"
          content="text/html; charset=utf-8"
        ></meta>
        <title>{seo.title}</title>
        <link rel="canonical" href={seo.url} />
        <meta key="meta-desc" name="description" content={seo.description} />
        <meta key="meta-image" name="image" content={seo.image} />
      </Head>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} />
    </>
  );
};
