import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MainGraphQueryVariables = Types.Exact<{
  filter: Types.MainGraphFilter;
}>;


export type MainGraphQuery = { __typename?: 'RootQueryType', mainGraph?: { __typename?: 'MainGraphResult', plot?: Array<number> | null, labels?: Array<string> | null, presentIndex?: number | null, interval: Types.AnalyticsIntervalType } | null };


export const MainGraphDocument = gql`
    query mainGraph($filter: MainGraphFilter!) {
  mainGraph(filter: $filter) {
    plot
    labels
    presentIndex
    interval
  }
}
    `;

/**
 * __useMainGraphQuery__
 *
 * To run a query within a React component, call `useMainGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainGraphQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMainGraphQuery(baseOptions: Apollo.QueryHookOptions<MainGraphQuery, MainGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MainGraphQuery, MainGraphQueryVariables>(MainGraphDocument, options);
      }
export function useMainGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MainGraphQuery, MainGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MainGraphQuery, MainGraphQueryVariables>(MainGraphDocument, options);
        }
export type MainGraphQueryHookResult = ReturnType<typeof useMainGraphQuery>;
export type MainGraphLazyQueryHookResult = ReturnType<typeof useMainGraphLazyQuery>;
export type MainGraphQueryResult = Apollo.QueryResult<MainGraphQuery, MainGraphQueryVariables>;
export function refetchMainGraphQuery(variables: MainGraphQueryVariables) {
      return { query: MainGraphDocument, variables: variables }
    }