import { createStore, StoreApi, useStore } from 'zustand';

type SelectedHotspotStateBase = {
  hotspotId: string | null;
};

type SelectedHotspotStoreActions = {
  selectHotspot: (hotspotId: string | null) => void;
  clearSelectedHotspot: () => void;
};

export type SelectedHotspotStoreState = SelectedHotspotStateBase &
  SelectedHotspotStoreActions;

export const selectedHotspotStore = createStore<SelectedHotspotStoreState>(
  (set) => ({
    hotspotId: null,
    selectHotspot(hotspotId) {
      set({ hotspotId });
    },
    clearSelectedHotspot() {
      set({ hotspotId: null });
    },
  }),
);

const createBoundedUseStore = ((store) => (selector) =>
  useStore(store, selector)) as <S extends StoreApi<unknown>>(
  store: S,
) => {
  (): ExtractState<S>;
  <T>(selector: (state: ExtractState<S>) => T): T;
};

type ExtractState<S> = S extends { getState: () => infer X } ? X : never;

export const useSelectedHotspotStore =
  createBoundedUseStore(selectedHotspotStore);
