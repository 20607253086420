import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkAllStoryTimelinesAsReadMutationVariables = Types.Exact<{
  storyId: Types.Scalars['ID']['input'];
}>;


export type MarkAllStoryTimelinesAsReadMutation = { __typename?: 'RootMutationType', markAllStoryTimelinesAsRead: { __typename?: 'MarkAllStoryTimelinesAsReadResult', message?: string | null, completed: boolean, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } };


export const MarkAllStoryTimelinesAsReadDocument = gql`
    mutation markAllStoryTimelinesAsRead($storyId: ID!) {
  markAllStoryTimelinesAsRead(storyId: $storyId) {
    message
    completed
    userErrors {
      field
      message
    }
  }
}
    `;
export type MarkAllStoryTimelinesAsReadMutationFn = Apollo.MutationFunction<MarkAllStoryTimelinesAsReadMutation, MarkAllStoryTimelinesAsReadMutationVariables>;

/**
 * __useMarkAllStoryTimelinesAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllStoryTimelinesAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllStoryTimelinesAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllStoryTimelinesAsReadMutation, { data, loading, error }] = useMarkAllStoryTimelinesAsReadMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useMarkAllStoryTimelinesAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllStoryTimelinesAsReadMutation, MarkAllStoryTimelinesAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllStoryTimelinesAsReadMutation, MarkAllStoryTimelinesAsReadMutationVariables>(MarkAllStoryTimelinesAsReadDocument, options);
      }
export type MarkAllStoryTimelinesAsReadMutationHookResult = ReturnType<typeof useMarkAllStoryTimelinesAsReadMutation>;
export type MarkAllStoryTimelinesAsReadMutationResult = Apollo.MutationResult<MarkAllStoryTimelinesAsReadMutation>;
export type MarkAllStoryTimelinesAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAllStoryTimelinesAsReadMutation, MarkAllStoryTimelinesAsReadMutationVariables>;