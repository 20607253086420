import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileUploadsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.FileUploadFilter>;
}>;


export type FileUploadsQuery = { __typename?: 'RootQueryType', fileUploads?: { __typename?: 'FileUploadConnection', totalCount: number, edges?: Array<{ __typename?: 'FileUploadEdge', node?: { __typename?: 'FileUpload', id: string, fileUrl?: string | null, name?: string | null, alternativeText?: string | null, pageCount?: number | null, size?: number | null, insertedAt?: any | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const FileUploadsDocument = gql`
    query fileUploads($after: String, $before: String, $first: Int, $filter: FileUploadFilter) {
  fileUploads(after: $after, before: $before, first: $first, filter: $filter) {
    edges {
      node {
        id
        fileUrl
        name
        alternativeText
        pageCount
        size
        insertedAt
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useFileUploadsQuery__
 *
 * To run a query within a React component, call `useFileUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileUploadsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFileUploadsQuery(baseOptions?: Apollo.QueryHookOptions<FileUploadsQuery, FileUploadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileUploadsQuery, FileUploadsQueryVariables>(FileUploadsDocument, options);
      }
export function useFileUploadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileUploadsQuery, FileUploadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileUploadsQuery, FileUploadsQueryVariables>(FileUploadsDocument, options);
        }
export type FileUploadsQueryHookResult = ReturnType<typeof useFileUploadsQuery>;
export type FileUploadsLazyQueryHookResult = ReturnType<typeof useFileUploadsLazyQuery>;
export type FileUploadsQueryResult = Apollo.QueryResult<FileUploadsQuery, FileUploadsQueryVariables>;
export function refetchFileUploadsQuery(variables?: FileUploadsQueryVariables) {
      return { query: FileUploadsDocument, variables: variables }
    }