import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateStripeCustomerPortalSessionMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateStripeCustomerPortalSessionMutation = { __typename?: 'RootMutationType', createStripeCustomerPortalSession?: { __typename?: 'CreateStripeCustomerPortalSessionResult', session?: { __typename?: 'StripeCustomerPortalSession', url: string } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateStripeCustomerPortalSessionDocument = gql`
    mutation createStripeCustomerPortalSession {
  createStripeCustomerPortalSession {
    session {
      url
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CreateStripeCustomerPortalSessionMutationFn = Apollo.MutationFunction<CreateStripeCustomerPortalSessionMutation, CreateStripeCustomerPortalSessionMutationVariables>;

/**
 * __useCreateStripeCustomerPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCustomerPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCustomerPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCustomerPortalSessionMutation, { data, loading, error }] = useCreateStripeCustomerPortalSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeCustomerPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCustomerPortalSessionMutation, CreateStripeCustomerPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCustomerPortalSessionMutation, CreateStripeCustomerPortalSessionMutationVariables>(CreateStripeCustomerPortalSessionDocument, options);
      }
export type CreateStripeCustomerPortalSessionMutationHookResult = ReturnType<typeof useCreateStripeCustomerPortalSessionMutation>;
export type CreateStripeCustomerPortalSessionMutationResult = Apollo.MutationResult<CreateStripeCustomerPortalSessionMutation>;
export type CreateStripeCustomerPortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCustomerPortalSessionMutation, CreateStripeCustomerPortalSessionMutationVariables>;