import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnboardBasicsMutationVariables = Types.Exact<{
  input: Types.OnboardBasicsInput;
}>;


export type OnboardBasicsMutation = { __typename?: 'RootMutationType', onboardBasics?: { __typename?: 'ViewerResult', viewer?: { __typename?: 'User', id: string, email?: string | null, emailVerified?: boolean | null, onboardingStatus: Types.OnboardingStatus, role: string, activeTenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, features: Array<Types.PlanFeature> } | null, member?: { __typename?: 'Member', id: string, role: string, displayName?: string | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const OnboardBasicsDocument = gql`
    mutation onboardBasics($input: OnboardBasicsInput!) {
  onboardBasics(input: $input) {
    viewer {
      id
      email
      emailVerified
      onboardingStatus
      role
      activeTenant {
        id
        databaseId
        commerceActivated
        storyCount
        name
        features
      }
      member {
        id
        role
        displayName
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type OnboardBasicsMutationFn = Apollo.MutationFunction<OnboardBasicsMutation, OnboardBasicsMutationVariables>;

/**
 * __useOnboardBasicsMutation__
 *
 * To run a mutation, you first call `useOnboardBasicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardBasicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardBasicsMutation, { data, loading, error }] = useOnboardBasicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardBasicsMutation(baseOptions?: Apollo.MutationHookOptions<OnboardBasicsMutation, OnboardBasicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardBasicsMutation, OnboardBasicsMutationVariables>(OnboardBasicsDocument, options);
      }
export type OnboardBasicsMutationHookResult = ReturnType<typeof useOnboardBasicsMutation>;
export type OnboardBasicsMutationResult = Apollo.MutationResult<OnboardBasicsMutation>;
export type OnboardBasicsMutationOptions = Apollo.BaseMutationOptions<OnboardBasicsMutation, OnboardBasicsMutationVariables>;