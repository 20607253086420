import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryLinksQueryVariables = Types.Exact<{
  storyId: Types.Scalars['ID']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first: Types.Scalars['Int']['input'];
  filter: Types.StoryLinkFilter;
}>;


export type StoryLinksQuery = { __typename?: 'RootQueryType', storyLinks?: { __typename?: 'StoryLinkConnection', totalCount: number, edges?: Array<{ __typename?: 'StoryLinkEdge', node?: { __typename?: 'StoryLink', id: string, sid: string, isLive: boolean, customTitle?: string | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const StoryLinksDocument = gql`
    query storyLinks($storyId: ID!, $after: String, $before: String, $first: Int!, $filter: StoryLinkFilter!) {
  storyLinks(
    storyId: $storyId
    after: $after
    before: $before
    first: $first
    filter: $filter
  ) {
    edges {
      node {
        id
        sid
        isLive
        customTitle
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useStoryLinksQuery__
 *
 * To run a query within a React component, call `useStoryLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryLinksQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStoryLinksQuery(baseOptions: Apollo.QueryHookOptions<StoryLinksQuery, StoryLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryLinksQuery, StoryLinksQueryVariables>(StoryLinksDocument, options);
      }
export function useStoryLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryLinksQuery, StoryLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryLinksQuery, StoryLinksQueryVariables>(StoryLinksDocument, options);
        }
export type StoryLinksQueryHookResult = ReturnType<typeof useStoryLinksQuery>;
export type StoryLinksLazyQueryHookResult = ReturnType<typeof useStoryLinksLazyQuery>;
export type StoryLinksQueryResult = Apollo.QueryResult<StoryLinksQuery, StoryLinksQueryVariables>;
export function refetchStoryLinksQuery(variables: StoryLinksQueryVariables) {
      return { query: StoryLinksDocument, variables: variables }
    }