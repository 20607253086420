import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMemberInvitationMutationVariables = Types.Exact<{
  input: Types.UpdateMemberInvitationInput;
}>;


export type UpdateMemberInvitationMutation = { __typename?: 'RootMutationType', updateMemberInvitation?: { __typename?: 'MemberInvitationResult', memberInvitation?: { __typename?: 'MemberInvitation', id: string, email?: string | null, invitedAt?: any | null, isExpired?: boolean | null, role?: string | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateMemberInvitationDocument = gql`
    mutation updateMemberInvitation($input: UpdateMemberInvitationInput!) {
  updateMemberInvitation(input: $input) {
    memberInvitation {
      id
      email
      invitedAt
      isExpired
      role
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateMemberInvitationMutationFn = Apollo.MutationFunction<UpdateMemberInvitationMutation, UpdateMemberInvitationMutationVariables>;

/**
 * __useUpdateMemberInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateMemberInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberInvitationMutation, { data, loading, error }] = useUpdateMemberInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberInvitationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberInvitationMutation, UpdateMemberInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberInvitationMutation, UpdateMemberInvitationMutationVariables>(UpdateMemberInvitationDocument, options);
      }
export type UpdateMemberInvitationMutationHookResult = ReturnType<typeof useUpdateMemberInvitationMutation>;
export type UpdateMemberInvitationMutationResult = Apollo.MutationResult<UpdateMemberInvitationMutation>;
export type UpdateMemberInvitationMutationOptions = Apollo.BaseMutationOptions<UpdateMemberInvitationMutation, UpdateMemberInvitationMutationVariables>;