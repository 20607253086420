import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { ContactFragmentOnContactFeedFragmentDoc } from '../../../crm/contacts/__generated__/contact-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateStoryTimelineMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateStoryTimelineInput;
}>;


export type UpdateStoryTimelineMutation = { __typename?: 'RootMutationType', updateStoryTimeline?: { __typename?: 'StoryTimelineResult', storyTimeline?: { __typename?: 'StoryTimeline', id: string, url?: string | null, email?: string | null, isRead?: boolean | null, isSpam?: boolean | null, isArchived?: boolean | null, archivedAt?: any | null, markedAsSpamAt?: any | null, lastEventAt?: any | null, insertedAt?: any | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null, responses?: Array<{ __typename?: 'StoryResponse', id: string, type: Types.StoryResponseType, answerType?: Types.StoryResponseAnswerType | null, pageChoiceText?: string | null, pageQuestionText?: string | null, pageTitle?: string | null, pageNumber?: number | null, questionTitle?: string | null, stringValue?: string | null, value?: number | null, timestamp?: any | null } | null> | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateStoryTimelineDocument = gql`
    mutation updateStoryTimeline($id: ID!, $input: UpdateStoryTimelineInput!) {
  updateStoryTimeline(id: $id, input: $input) {
    storyTimeline {
      id
      url
      email
      isRead
      isSpam
      isArchived
      archivedAt
      markedAsSpamAt
      lastEventAt
      insertedAt
      contact {
        ...ContactFragmentOnContactFeed
      }
      responses {
        id
        type
        answerType
        pageChoiceText
        pageQuestionText
        pageTitle
        pageNumber
        questionTitle
        stringValue
        value
        timestamp
      }
    }
    userErrors {
      field
      message
    }
  }
}
    ${ContactFragmentOnContactFeedFragmentDoc}`;
export type UpdateStoryTimelineMutationFn = Apollo.MutationFunction<UpdateStoryTimelineMutation, UpdateStoryTimelineMutationVariables>;

/**
 * __useUpdateStoryTimelineMutation__
 *
 * To run a mutation, you first call `useUpdateStoryTimelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryTimelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryTimelineMutation, { data, loading, error }] = useUpdateStoryTimelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStoryTimelineMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryTimelineMutation, UpdateStoryTimelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryTimelineMutation, UpdateStoryTimelineMutationVariables>(UpdateStoryTimelineDocument, options);
      }
export type UpdateStoryTimelineMutationHookResult = ReturnType<typeof useUpdateStoryTimelineMutation>;
export type UpdateStoryTimelineMutationResult = Apollo.MutationResult<UpdateStoryTimelineMutation>;
export type UpdateStoryTimelineMutationOptions = Apollo.BaseMutationOptions<UpdateStoryTimelineMutation, UpdateStoryTimelineMutationVariables>;