import React from 'react';

import { AuthContext } from '../auth-context';

interface Props {
  children: React.ReactNode;
  isLoggedIn: boolean;
  currentUser: any;
  loaded: boolean;
  authLoading: boolean;
  currentRole: string | null;
}

export const MockAuthProvider = ({ children, ...rest }: Props) => {
  const afterLoggedIn = () => false;
  const handleLogout = () => false;

  return (
    <AuthContext.Provider
      value={{
        ...rest,
        afterLoggedIn,
        logout: handleLogout,
        refetchCurrentUser: () => false,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
