import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageQuestionsQueryVariables = Types.Exact<{
  storyId: Types.Scalars['ID']['input'];
}>;


export type PageQuestionsQuery = { __typename?: 'RootQueryType', pageQuestions?: Array<{ __typename?: 'PageQuestion', id: string, title?: string | null, titleColor: string, storyPage?: { __typename?: 'InitiationItem', id: string, order: number, title: string } | null }> | null };


export const PageQuestionsDocument = gql`
    query pageQuestions($storyId: ID!) {
  pageQuestions(storyId: $storyId) {
    id
    title
    titleColor
    storyPage {
      id
      order
      title
    }
  }
}
    `;

/**
 * __usePageQuestionsQuery__
 *
 * To run a query within a React component, call `usePageQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuestionsQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function usePageQuestionsQuery(baseOptions: Apollo.QueryHookOptions<PageQuestionsQuery, PageQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageQuestionsQuery, PageQuestionsQueryVariables>(PageQuestionsDocument, options);
      }
export function usePageQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuestionsQuery, PageQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageQuestionsQuery, PageQuestionsQueryVariables>(PageQuestionsDocument, options);
        }
export type PageQuestionsQueryHookResult = ReturnType<typeof usePageQuestionsQuery>;
export type PageQuestionsLazyQueryHookResult = ReturnType<typeof usePageQuestionsLazyQuery>;
export type PageQuestionsQueryResult = Apollo.QueryResult<PageQuestionsQuery, PageQuestionsQueryVariables>;
export function refetchPageQuestionsQuery(variables: PageQuestionsQueryVariables) {
      return { query: PageQuestionsDocument, variables: variables }
    }