import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveProductWithShopifyUrlMutationVariables = Types.Exact<{
  input: Types.SaveProductWithShopifyUrlInput;
}>;


export type SaveProductWithShopifyUrlMutation = { __typename?: 'RootMutationType', saveProductWithShopifyUrl?: { __typename?: 'ProductMutationResult', product?: { __typename?: 'Product', id: string, title: string, sourceId?: string | null, sourceType?: string | null, onlineStoreUrl: string, featuredImage?: { __typename?: 'ProductImage', id: string, sourceId?: string | null, sourceType?: Types.ProductSourceType | null, src?: string | null } | null, variants?: Array<{ __typename?: 'ProductVariant', id: string, originalPrice?: number | null, price?: number | null }> | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const SaveProductWithShopifyUrlDocument = gql`
    mutation SaveProductWithShopifyUrl($input: SaveProductWithShopifyUrlInput!) {
  saveProductWithShopifyUrl(input: $input) {
    product {
      id
      title
      sourceId
      sourceType
      onlineStoreUrl
      featuredImage {
        id
        sourceId
        sourceType
        src
      }
      variants {
        id
        originalPrice
        price
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type SaveProductWithShopifyUrlMutationFn = Apollo.MutationFunction<SaveProductWithShopifyUrlMutation, SaveProductWithShopifyUrlMutationVariables>;

/**
 * __useSaveProductWithShopifyUrlMutation__
 *
 * To run a mutation, you first call `useSaveProductWithShopifyUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProductWithShopifyUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProductWithShopifyUrlMutation, { data, loading, error }] = useSaveProductWithShopifyUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveProductWithShopifyUrlMutation(baseOptions?: Apollo.MutationHookOptions<SaveProductWithShopifyUrlMutation, SaveProductWithShopifyUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveProductWithShopifyUrlMutation, SaveProductWithShopifyUrlMutationVariables>(SaveProductWithShopifyUrlDocument, options);
      }
export type SaveProductWithShopifyUrlMutationHookResult = ReturnType<typeof useSaveProductWithShopifyUrlMutation>;
export type SaveProductWithShopifyUrlMutationResult = Apollo.MutationResult<SaveProductWithShopifyUrlMutation>;
export type SaveProductWithShopifyUrlMutationOptions = Apollo.BaseMutationOptions<SaveProductWithShopifyUrlMutation, SaveProductWithShopifyUrlMutationVariables>;