import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { VideoUploadOnFeedFragmentDoc } from './video-uploads-query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateVideoUploadMutationVariables = Types.Exact<{
  input: Types.UpdateVideoUploadInput;
}>;


export type UpdateVideoUploadMutation = { __typename?: 'RootMutationType', updateVideoUpload?: { __typename?: 'VideoUploadResult', videoUpload?: { __typename?: 'VideoUpload', id: string, name?: string | null, rawUrl?: string | null, alternativeText?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, duration?: number | null, size?: number | null, insertedAt?: any | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateVideoUploadDocument = gql`
    mutation updateVideoUpload($input: UpdateVideoUploadInput!) {
  updateVideoUpload(input: $input) {
    videoUpload {
      ...VideoUploadOnFeed
    }
    userErrors {
      field
      message
    }
  }
}
    ${VideoUploadOnFeedFragmentDoc}`;
export type UpdateVideoUploadMutationFn = Apollo.MutationFunction<UpdateVideoUploadMutation, UpdateVideoUploadMutationVariables>;

/**
 * __useUpdateVideoUploadMutation__
 *
 * To run a mutation, you first call `useUpdateVideoUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoUploadMutation, { data, loading, error }] = useUpdateVideoUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVideoUploadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVideoUploadMutation, UpdateVideoUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVideoUploadMutation, UpdateVideoUploadMutationVariables>(UpdateVideoUploadDocument, options);
      }
export type UpdateVideoUploadMutationHookResult = ReturnType<typeof useUpdateVideoUploadMutation>;
export type UpdateVideoUploadMutationResult = Apollo.MutationResult<UpdateVideoUploadMutation>;
export type UpdateVideoUploadMutationOptions = Apollo.BaseMutationOptions<UpdateVideoUploadMutation, UpdateVideoUploadMutationVariables>;