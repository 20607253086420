import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReorderShoppableProductsMutationVariables = Types.Exact<{
  input: Types.ReorderShoppableProductsInput;
}>;


export type ReorderShoppableProductsMutation = { __typename?: 'RootMutationType', reorderShoppableProducts?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const ReorderShoppableProductsDocument = gql`
    mutation reorderShoppableProducts($input: ReorderShoppableProductsInput!) {
  reorderShoppableProducts(input: $input) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type ReorderShoppableProductsMutationFn = Apollo.MutationFunction<ReorderShoppableProductsMutation, ReorderShoppableProductsMutationVariables>;

/**
 * __useReorderShoppableProductsMutation__
 *
 * To run a mutation, you first call `useReorderShoppableProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderShoppableProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderShoppableProductsMutation, { data, loading, error }] = useReorderShoppableProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderShoppableProductsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderShoppableProductsMutation, ReorderShoppableProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderShoppableProductsMutation, ReorderShoppableProductsMutationVariables>(ReorderShoppableProductsDocument, options);
      }
export type ReorderShoppableProductsMutationHookResult = ReturnType<typeof useReorderShoppableProductsMutation>;
export type ReorderShoppableProductsMutationResult = Apollo.MutationResult<ReorderShoppableProductsMutation>;
export type ReorderShoppableProductsMutationOptions = Apollo.BaseMutationOptions<ReorderShoppableProductsMutation, ReorderShoppableProductsMutationVariables>;