import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TopStatsQueryVariables = Types.Exact<{
  filter: Types.TopStatsFilter;
}>;


export type TopStatsQuery = { __typename?: 'RootQueryType', topStats?: { __typename?: 'TopStatsResult', interval: Types.AnalyticsIntervalType, topStats: { __typename?: 'TopStats', impressions: { __typename?: 'TopStatInt', metric?: string | null, value?: number | null, change?: number | null }, plays: { __typename?: 'TopStatInt', metric?: string | null, value?: number | null, change?: number | null }, pageviews: { __typename?: 'TopStatInt', metric?: string | null, value?: number | null, change?: number | null }, avgDuration: { __typename?: 'TopStatInt', metric?: string | null, value?: number | null, change?: number | null } } } | null };


export const TopStatsDocument = gql`
    query topStats($filter: TopStatsFilter!) {
  topStats(filter: $filter) {
    topStats {
      impressions {
        metric
        value
        change
      }
      plays {
        metric
        value
        change
      }
      pageviews {
        metric
        value
        change
      }
      avgDuration {
        metric
        value
        change
      }
    }
    interval
  }
}
    `;

/**
 * __useTopStatsQuery__
 *
 * To run a query within a React component, call `useTopStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTopStatsQuery(baseOptions: Apollo.QueryHookOptions<TopStatsQuery, TopStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopStatsQuery, TopStatsQueryVariables>(TopStatsDocument, options);
      }
export function useTopStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopStatsQuery, TopStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopStatsQuery, TopStatsQueryVariables>(TopStatsDocument, options);
        }
export type TopStatsQueryHookResult = ReturnType<typeof useTopStatsQuery>;
export type TopStatsLazyQueryHookResult = ReturnType<typeof useTopStatsLazyQuery>;
export type TopStatsQueryResult = Apollo.QueryResult<TopStatsQuery, TopStatsQueryVariables>;
export function refetchTopStatsQuery(variables: TopStatsQueryVariables) {
      return { query: TopStatsDocument, variables: variables }
    }