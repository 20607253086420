import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { ContactFragmentOnContactFeedFragmentDoc } from '../../../crm/contacts/__generated__/contact-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VideoResponsesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.VideoResponseFilter>;
}>;


export type VideoResponsesQuery = { __typename?: 'RootQueryType', videoResponses?: { __typename?: 'VideoResponseConnection', totalCount: number, edges?: Array<{ __typename?: 'VideoResponseEdge', node?: { __typename?: 'VideoResponse', id: string, question: string, videoUpload?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, alternativeText?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, duration?: number | null, size?: number | null, insertedAt?: any | null } | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const VideoResponsesDocument = gql`
    query videoResponses($after: String, $before: String, $first: Int, $filter: VideoResponseFilter) {
  videoResponses(after: $after, before: $before, first: $first, filter: $filter) {
    edges {
      node {
        id
        question
        videoUpload {
          id
          rawUrl
          alternativeText
          transcodedUrl
          previewUrl
          streamUrl
          thumbUrl
          gifUrl
          gifWithIconUrl
          duration
          size
          insertedAt
        }
        contact {
          ...ContactFragmentOnContactFeed
        }
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    ${ContactFragmentOnContactFeedFragmentDoc}`;

/**
 * __useVideoResponsesQuery__
 *
 * To run a query within a React component, call `useVideoResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoResponsesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVideoResponsesQuery(baseOptions?: Apollo.QueryHookOptions<VideoResponsesQuery, VideoResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoResponsesQuery, VideoResponsesQueryVariables>(VideoResponsesDocument, options);
      }
export function useVideoResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoResponsesQuery, VideoResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoResponsesQuery, VideoResponsesQueryVariables>(VideoResponsesDocument, options);
        }
export type VideoResponsesQueryHookResult = ReturnType<typeof useVideoResponsesQuery>;
export type VideoResponsesLazyQueryHookResult = ReturnType<typeof useVideoResponsesLazyQuery>;
export type VideoResponsesQueryResult = Apollo.QueryResult<VideoResponsesQuery, VideoResponsesQueryVariables>;
export function refetchVideoResponsesQuery(variables?: VideoResponsesQueryVariables) {
      return { query: VideoResponsesDocument, variables: variables }
    }