import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptMemberInvitationMutationVariables = Types.Exact<{
  input: Types.AcceptMemberInvitationInput;
}>;


export type AcceptMemberInvitationMutation = { __typename?: 'RootMutationType', acceptMemberInvitation?: { __typename?: 'AuthResult', accessToken?: string | null, refreshToken?: string | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null, viewer?: { __typename?: 'User', id: string, avatarUrl?: string | null, displayName?: string | null, email?: string | null, emailVerified?: boolean | null, onboardingStatus: Types.OnboardingStatus, role: string, tenantCount: number, activeTenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, features: Array<Types.PlanFeature> } | null, member?: { __typename?: 'Member', id: string, role: string, displayName?: string | null } | null } | null } | null };


export const AcceptMemberInvitationDocument = gql`
    mutation acceptMemberInvitation($input: AcceptMemberInvitationInput!) {
  acceptMemberInvitation(input: $input) {
    userErrors {
      field
      message
    }
    viewer {
      id
      avatarUrl
      displayName
      email
      emailVerified
      onboardingStatus
      role
      tenantCount
      activeTenant {
        id
        databaseId
        commerceActivated
        storyCount
        name
        features
      }
      member {
        id
        role
        displayName
      }
    }
    accessToken
    refreshToken
  }
}
    `;
export type AcceptMemberInvitationMutationFn = Apollo.MutationFunction<AcceptMemberInvitationMutation, AcceptMemberInvitationMutationVariables>;

/**
 * __useAcceptMemberInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptMemberInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMemberInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMemberInvitationMutation, { data, loading, error }] = useAcceptMemberInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptMemberInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptMemberInvitationMutation, AcceptMemberInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptMemberInvitationMutation, AcceptMemberInvitationMutationVariables>(AcceptMemberInvitationDocument, options);
      }
export type AcceptMemberInvitationMutationHookResult = ReturnType<typeof useAcceptMemberInvitationMutation>;
export type AcceptMemberInvitationMutationResult = Apollo.MutationResult<AcceptMemberInvitationMutation>;
export type AcceptMemberInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptMemberInvitationMutation, AcceptMemberInvitationMutationVariables>;