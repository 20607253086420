import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateInitiationInteractivenessMutationVariables = Types.Exact<{
  input: Types.UpdateInitiationInteractivenessInput;
}>;


export type UpdateInitiationInteractivenessMutation = { __typename?: 'RootMutationType', updateInitiationInteractiveness?: { __typename?: 'InitiationSettingResult', initiationSetting?: { __typename?: 'InitiationSetting', id: string, isInteractive: boolean } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const UpdateInitiationInteractivenessDocument = gql`
    mutation updateInitiationInteractiveness($input: UpdateInitiationInteractivenessInput!) {
  updateInitiationInteractiveness(input: $input) {
    initiationSetting {
      id
      isInteractive
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateInitiationInteractivenessMutationFn = Apollo.MutationFunction<UpdateInitiationInteractivenessMutation, UpdateInitiationInteractivenessMutationVariables>;

/**
 * __useUpdateInitiationInteractivenessMutation__
 *
 * To run a mutation, you first call `useUpdateInitiationInteractivenessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInitiationInteractivenessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInitiationInteractivenessMutation, { data, loading, error }] = useUpdateInitiationInteractivenessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInitiationInteractivenessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInitiationInteractivenessMutation, UpdateInitiationInteractivenessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInitiationInteractivenessMutation, UpdateInitiationInteractivenessMutationVariables>(UpdateInitiationInteractivenessDocument, options);
      }
export type UpdateInitiationInteractivenessMutationHookResult = ReturnType<typeof useUpdateInitiationInteractivenessMutation>;
export type UpdateInitiationInteractivenessMutationResult = Apollo.MutationResult<UpdateInitiationInteractivenessMutation>;
export type UpdateInitiationInteractivenessMutationOptions = Apollo.BaseMutationOptions<UpdateInitiationInteractivenessMutation, UpdateInitiationInteractivenessMutationVariables>;