import Head from 'next/head';
import React from 'react';

interface Props {
  type?: string;
  title: string;
  desc: string;
  image: string;
}

const Twitter = ({
  type = 'summary_large_image',
  title,
  desc,
  image,
}: Props) => (
  <Head>
    <meta key="twitter-card" name="twitter:card" content={type} />
    <meta key="twitter-title" name="twitter:title" content={title} />
    <meta key="twitter-description" name="twitter:description" content={desc} />
    <meta key="twitter-image" name="twitter:image" content={image} />
    <meta key="taitter-image-alt" name="twitter:image:alt" content={desc} />
  </Head>
);

export default Twitter;
