interface Props {
  fill: string;
  className?: string;
}

export const UntitledUICursorClick01Icon = ({
  className,
  fill = '#4b5563',
}: Props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <path d="M10 3.5V2c0-.56-.45-1-1-1 -.56 0-1 .44-1 1v1.5c0 .55.44 1 1 1 .55 0 1-.45 1-1Zm-4.24.85L4.69 3.28c-.4-.4-1.03-.4-1.42 0 -.4.39-.4 1.02 0 1.41l1.06 1.06c.39.39 1.02.39 1.41 0 .39-.4.39-1.03 0-1.42Zm-1.42 7.93l-1.07 1.06c-.4.39-.4 1.02 0 1.41 .39.39 1.02.39 1.41-.01l1.06-1.07c.39-.4.39-1.03-.01-1.42 -.4-.4-1.03-.4-1.42 0Zm9.35-6.53l1.06-1.07c.39-.4.39-1.03 0-1.42 -.4-.4-1.03-.4-1.42-.01l-1.07 1.06c-.4.39-.4 1.02-.01 1.41 .39.39 1.02.39 1.41 0ZM3.48 7.98h-1.5c-.56 0-1 .44-1 1 0 .55.44 1 1 1h1.5c.55 0 1-.45 1-1 0-.56-.45-1-1-1Zm11.48 7.71l-2.5 4.62c-.18.33-.25.44-.3.52 0-.02.05-.07.21-.13 .14-.06.31-.05.45.04 .12.08.18.15.19.19 -.04-.08-.08-.21-.2-.61L9.35 9.09c-.09-.29-.12-.39-.14-.46 0 .02 0 .1-.05.23 -.06.13-.16.24-.3.29 -.14.04-.22.04-.24.04 .07.01.17.04.45.13l11.22 3.45c.39.12.52.16.6.2 -.04-.02-.11-.07-.2-.2 -.09-.14-.11-.31-.05-.46 .02-.08.06-.14.1-.19 .02-.04-.13.05-.51.26l-4.63 2.49c-.12.06-.15.08-.22.12 -.05.02-.05.02-.09.06 -.09.07-.18.15-.25.24 -.04.04-.04.04-.07.08 -.05.06-.07.09-.13.21Zm1.76.94c.02-.05.03-.06.02-.05 -.02.01-.02.01-.03.03 -.03.02-.06.05-.09.08 -.02.01-.02.01-.04.02 -.02 0-.01 0 .04-.03l4.62-2.5c.74-.41.88-.49 1.1-.77 .08-.11.15-.23.2-.36 .17-.45.12-.95-.14-1.36 -.17-.26-.39-.42-.67-.55 -.2-.09-.35-.14-.84-.29L9.66 7.39c-.91-.28-1.05-.31-1.51-.15 -.42.15-.74.47-.9.89 -.17.45-.14.59.14 1.5l3.45 11.22c.15.49.2.64.28.83 .12.28.28.5.54.67 .4.25.9.3 1.35.13 .49-.2.57-.31 1.11-1.32l2.49-4.63Z" />
    </svg>
  );
};
