import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiKeysQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
}>;


export type ApiKeysQuery = { __typename?: 'RootQueryType', apiKeys?: { __typename?: 'ApiKeyConnection', totalCount: number, edges?: Array<{ __typename?: 'ApiKeyEdge', node?: { __typename?: 'ApiKey', id: string, name: string, status: Types.ApiKeyStatus, lastUsedAt?: any | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const ApiKeysDocument = gql`
    query apiKeys($first: Int!) {
  apiKeys(first: $first) {
    edges {
      node {
        id
        name
        status
        lastUsedAt
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useApiKeysQuery__
 *
 * To run a query within a React component, call `useApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useApiKeysQuery(baseOptions: Apollo.QueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
      }
export function useApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
        }
export type ApiKeysQueryHookResult = ReturnType<typeof useApiKeysQuery>;
export type ApiKeysLazyQueryHookResult = ReturnType<typeof useApiKeysLazyQuery>;
export type ApiKeysQueryResult = Apollo.QueryResult<ApiKeysQuery, ApiKeysQueryVariables>;
export function refetchApiKeysQuery(variables: ApiKeysQueryVariables) {
      return { query: ApiKeysDocument, variables: variables }
    }