import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryFirstVideoQueryVariables = Types.Exact<{
  initiationId: Types.Scalars['ID']['input'];
}>;


export type StoryFirstVideoQuery = { __typename?: 'RootQueryType', storyFirstVideo?: { __typename?: 'VideoUpload', id: string, gifWithIconUrl?: string | null, currentGifRequest?: { __typename?: 'VideoProcessingRequest', id: string, status?: Types.VideoProcessingStatus | null } | null } | null };


export const StoryFirstVideoDocument = gql`
    query storyFirstVideo($initiationId: ID!) {
  storyFirstVideo(initiationId: $initiationId) {
    id
    gifWithIconUrl
    currentGifRequest {
      id
      status
    }
  }
}
    `;

/**
 * __useStoryFirstVideoQuery__
 *
 * To run a query within a React component, call `useStoryFirstVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryFirstVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryFirstVideoQuery({
 *   variables: {
 *      initiationId: // value for 'initiationId'
 *   },
 * });
 */
export function useStoryFirstVideoQuery(baseOptions: Apollo.QueryHookOptions<StoryFirstVideoQuery, StoryFirstVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryFirstVideoQuery, StoryFirstVideoQueryVariables>(StoryFirstVideoDocument, options);
      }
export function useStoryFirstVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryFirstVideoQuery, StoryFirstVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryFirstVideoQuery, StoryFirstVideoQueryVariables>(StoryFirstVideoDocument, options);
        }
export type StoryFirstVideoQueryHookResult = ReturnType<typeof useStoryFirstVideoQuery>;
export type StoryFirstVideoLazyQueryHookResult = ReturnType<typeof useStoryFirstVideoLazyQuery>;
export type StoryFirstVideoQueryResult = Apollo.QueryResult<StoryFirstVideoQuery, StoryFirstVideoQueryVariables>;
export function refetchStoryFirstVideoQuery(variables: StoryFirstVideoQueryVariables) {
      return { query: StoryFirstVideoDocument, variables: variables }
    }