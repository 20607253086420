import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import { OpenGraph } from 'next-seo/lib/types';
import React from 'react';

interface Props {
  title?: string;
  description?: string;
  noindex?: boolean;
  openGraph?: OpenGraph;
}

export const ShareloSeo = (props: Props) => {
  const router = useRouter();
  const { asPath, locale } = router;
  const url = `${process.env.NEXT_PUBLIC_APP_URL}/${
    locale === 'ja' ? 'ja' : ''
  }${asPath}`;

  const { title, description, noindex = false, openGraph = {} } = props;
  const { t } = useTranslation();
  const sampleThumb = '/images/sharelo_og_v1.png';
  const computedTitle = title || t('seo.title.default');
  const computedDesc = description || t('seo.description.default');
  const computedOg = {
    url,
    title: computedTitle,
    description: computedDesc,
    site_name: 'Sharelo',
    images: [
      {
        url: sampleThumb,
        width: 1280,
        height: 720,
        alt: 'Sharelo',
        type: 'image/png',
      },
    ],
    ...openGraph,
    twitter: {
      handle: '@shareloapp',
      cardType: 'summary_large_image',
    },
  };

  return (
    <NextSeo
      title={computedTitle as string}
      description={computedDesc as string}
      noindex={noindex}
      openGraph={computedOg as OpenGraph}
    />
  );
};
