import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkStoryTimelineAsReadMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MarkStoryTimelineAsReadMutation = { __typename?: 'RootMutationType', markStoryTimelineAsRead?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const MarkStoryTimelineAsReadDocument = gql`
    mutation markStoryTimelineAsRead($id: ID!) {
  markStoryTimelineAsRead(id: $id) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type MarkStoryTimelineAsReadMutationFn = Apollo.MutationFunction<MarkStoryTimelineAsReadMutation, MarkStoryTimelineAsReadMutationVariables>;

/**
 * __useMarkStoryTimelineAsReadMutation__
 *
 * To run a mutation, you first call `useMarkStoryTimelineAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkStoryTimelineAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markStoryTimelineAsReadMutation, { data, loading, error }] = useMarkStoryTimelineAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkStoryTimelineAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkStoryTimelineAsReadMutation, MarkStoryTimelineAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkStoryTimelineAsReadMutation, MarkStoryTimelineAsReadMutationVariables>(MarkStoryTimelineAsReadDocument, options);
      }
export type MarkStoryTimelineAsReadMutationHookResult = ReturnType<typeof useMarkStoryTimelineAsReadMutation>;
export type MarkStoryTimelineAsReadMutationResult = Apollo.MutationResult<MarkStoryTimelineAsReadMutation>;
export type MarkStoryTimelineAsReadMutationOptions = Apollo.BaseMutationOptions<MarkStoryTimelineAsReadMutation, MarkStoryTimelineAsReadMutationVariables>;