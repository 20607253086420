import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteStoryLinkMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteStoryLinkMutation = { __typename?: 'RootMutationType', deleteStoryLink?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const DeleteStoryLinkDocument = gql`
    mutation deleteStoryLink($id: ID!) {
  deleteStoryLink(id: $id) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteStoryLinkMutationFn = Apollo.MutationFunction<DeleteStoryLinkMutation, DeleteStoryLinkMutationVariables>;

/**
 * __useDeleteStoryLinkMutation__
 *
 * To run a mutation, you first call `useDeleteStoryLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryLinkMutation, { data, loading, error }] = useDeleteStoryLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStoryLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryLinkMutation, DeleteStoryLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoryLinkMutation, DeleteStoryLinkMutationVariables>(DeleteStoryLinkDocument, options);
      }
export type DeleteStoryLinkMutationHookResult = ReturnType<typeof useDeleteStoryLinkMutation>;
export type DeleteStoryLinkMutationResult = Apollo.MutationResult<DeleteStoryLinkMutation>;
export type DeleteStoryLinkMutationOptions = Apollo.BaseMutationOptions<DeleteStoryLinkMutation, DeleteStoryLinkMutationVariables>;