import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkStoryTimelineAsUnreadMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MarkStoryTimelineAsUnreadMutation = { __typename?: 'RootMutationType', markStoryTimelineAsUnread?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const MarkStoryTimelineAsUnreadDocument = gql`
    mutation markStoryTimelineAsUnread($id: ID!) {
  markStoryTimelineAsUnread(id: $id) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type MarkStoryTimelineAsUnreadMutationFn = Apollo.MutationFunction<MarkStoryTimelineAsUnreadMutation, MarkStoryTimelineAsUnreadMutationVariables>;

/**
 * __useMarkStoryTimelineAsUnreadMutation__
 *
 * To run a mutation, you first call `useMarkStoryTimelineAsUnreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkStoryTimelineAsUnreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markStoryTimelineAsUnreadMutation, { data, loading, error }] = useMarkStoryTimelineAsUnreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkStoryTimelineAsUnreadMutation(baseOptions?: Apollo.MutationHookOptions<MarkStoryTimelineAsUnreadMutation, MarkStoryTimelineAsUnreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkStoryTimelineAsUnreadMutation, MarkStoryTimelineAsUnreadMutationVariables>(MarkStoryTimelineAsUnreadDocument, options);
      }
export type MarkStoryTimelineAsUnreadMutationHookResult = ReturnType<typeof useMarkStoryTimelineAsUnreadMutation>;
export type MarkStoryTimelineAsUnreadMutationResult = Apollo.MutationResult<MarkStoryTimelineAsUnreadMutation>;
export type MarkStoryTimelineAsUnreadMutationOptions = Apollo.BaseMutationOptions<MarkStoryTimelineAsUnreadMutation, MarkStoryTimelineAsUnreadMutationVariables>;