import { SVGProps } from 'react';

export const UntitledUIAsterisk02Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M11 4v16c0 .55.44 1 1 1 .55 0 1-.45 1-1V4c0-.56-.45-1-1-1-.56 0-1 .44-1 1Zm6.29 1.29-12 12c-.4.39-.4 1.02 0 1.41.39.39 1.02.39 1.41 0l12-12c.39-.4.39-1.03 0-1.42a.99.99 0 0 0-1.42 0Zm2.7 5.7h-16c-.56 0-1 .44-1 1 0 .55.44 1 1 1h16c.55 0 1-.45 1-1 0-.56-.45-1-1-1Zm-1.3 6.29-12-12a.99.99 0 0 0-1.42 0c-.4.39-.4 1.02 0 1.41l12 12c.39.39 1.02.39 1.41 0 .39-.4.39-1.03 0-1.42Z" />
  </svg>
);
