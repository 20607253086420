import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrepareUploadMutationVariables = Types.Exact<{
  input: Types.PrepareUploadInput;
}>;


export type PrepareUploadMutation = { __typename?: 'RootMutationType', prepareUpload?: { __typename?: 'UploadState', uploadId: string, tempId: string, uploadPath: string, uploaded: boolean } | null };


export const PrepareUploadDocument = gql`
    mutation prepareUpload($input: PrepareUploadInput!) {
  prepareUpload(input: $input) {
    uploadId
    tempId
    uploadPath
    uploaded
  }
}
    `;
export type PrepareUploadMutationFn = Apollo.MutationFunction<PrepareUploadMutation, PrepareUploadMutationVariables>;

/**
 * __usePrepareUploadMutation__
 *
 * To run a mutation, you first call `usePrepareUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareUploadMutation, { data, loading, error }] = usePrepareUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareUploadMutation(baseOptions?: Apollo.MutationHookOptions<PrepareUploadMutation, PrepareUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrepareUploadMutation, PrepareUploadMutationVariables>(PrepareUploadDocument, options);
      }
export type PrepareUploadMutationHookResult = ReturnType<typeof usePrepareUploadMutation>;
export type PrepareUploadMutationResult = Apollo.MutationResult<PrepareUploadMutation>;
export type PrepareUploadMutationOptions = Apollo.BaseMutationOptions<PrepareUploadMutation, PrepareUploadMutationVariables>;