import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { createContext, useContext, useEffect, useState } from 'react';

dayjs.extend(utc);
dayjs.extend(timezone);

export const GeoContext = createContext<{
  timezone: string | null;
  countryCode: string | null;
  country: string | null;
  fetched: boolean;
}>({
  timezone: null,
  countryCode: null,
  country: null,
  fetched: false,
});

export function GeoProvider({ children }: any) {
  const [timezone, setTimezone] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const [fetched, setFetched] = useState<boolean>(false);
  const [country, setCountry] = useState<string | null>(null);

  useEffect(() => {
    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then((res) => {
        setFetched(true);
        setTimezone(res.data.timezone);
        setCountryCode(res.data.country_code);
        setCountry(res.data.country);
      })
      .catch(() => {
        setFetched(true);
        setTimezone(dayjs.tz.guess());
      });
  }, []);

  return (
    <GeoContext.Provider
      value={{
        countryCode,
        country,
        timezone,
        fetched,
      }}
    >
      {children}
    </GeoContext.Provider>
  );
}

export const useGeo = () => {
  return useContext(GeoContext);
};
