import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePasswordOnPasswordSettingsMutationVariables = Types.Exact<{
  input: Types.UpdatePasswordInput;
}>;


export type UpdatePasswordOnPasswordSettingsMutation = { __typename?: 'RootMutationType', updatePassword?: { __typename?: 'ViewerResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdatePasswordOnPasswordSettingsDocument = gql`
    mutation updatePasswordOnPasswordSettings($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdatePasswordOnPasswordSettingsMutationFn = Apollo.MutationFunction<UpdatePasswordOnPasswordSettingsMutation, UpdatePasswordOnPasswordSettingsMutationVariables>;

/**
 * __useUpdatePasswordOnPasswordSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordOnPasswordSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordOnPasswordSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordOnPasswordSettingsMutation, { data, loading, error }] = useUpdatePasswordOnPasswordSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordOnPasswordSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordOnPasswordSettingsMutation, UpdatePasswordOnPasswordSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordOnPasswordSettingsMutation, UpdatePasswordOnPasswordSettingsMutationVariables>(UpdatePasswordOnPasswordSettingsDocument, options);
      }
export type UpdatePasswordOnPasswordSettingsMutationHookResult = ReturnType<typeof useUpdatePasswordOnPasswordSettingsMutation>;
export type UpdatePasswordOnPasswordSettingsMutationResult = Apollo.MutationResult<UpdatePasswordOnPasswordSettingsMutation>;
export type UpdatePasswordOnPasswordSettingsMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordOnPasswordSettingsMutation, UpdatePasswordOnPasswordSettingsMutationVariables>;