import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginMutationVariables = Types.Exact<{
  input: Types.LoginInput;
}>;


export type LoginMutation = { __typename?: 'RootMutationType', login?: { __typename?: 'AuthResult', accessToken?: string | null, refreshToken?: string | null, viewer?: { __typename?: 'User', id: string, databaseId: any, email?: string | null, emailVerified?: boolean | null, displayName?: string | null, onboardingStatus: Types.OnboardingStatus, role: string, tenantCount: number, activeTenant?: { __typename?: 'Tenant', id: string, commerceActivated: boolean, storyCount?: number | null, databaseId: any, name?: string | null, features: Array<Types.PlanFeature>, canUseTrial?: boolean | null, canUseCoupon?: boolean | null, storageUsage?: number | null, memberCount?: number | null, monthlyPlays?: number | null, monthlyImpressions?: number | null, maxSeats?: number | null, maxStorage?: number | null, maxMonthlyPlays?: number | null, maxMonthlyImpressions?: number | null, maxLifetimePlays?: number | null, plan?: { __typename?: 'Plan', id?: string | null, isTrial?: boolean | null, isActive?: boolean | null, slug?: string | null, name?: string | null, nameI18nKey?: string | null } | null } | null, member?: { __typename?: 'Member', id: string, avatarUrl?: string | null, role: string, displayName?: string | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    viewer {
      id
      databaseId
      email
      emailVerified
      displayName
      onboardingStatus
      role
      tenantCount
      activeTenant {
        id
        commerceActivated
        storyCount
        databaseId
        name
        features
        canUseTrial
        canUseCoupon
        storageUsage
        memberCount
        monthlyPlays
        monthlyImpressions
        maxSeats
        maxStorage
        maxMonthlyPlays
        maxMonthlyImpressions
        maxLifetimePlays
        plan {
          id
          isTrial
          isActive
          slug
          name
          nameI18nKey
        }
      }
      member {
        id
        avatarUrl
        role
        displayName
      }
    }
    userErrors {
      field
      message
    }
    accessToken
    refreshToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;