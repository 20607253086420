import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { VideoUploadOnFeedFragmentDoc } from './video-uploads-query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteUploadMutationVariables = Types.Exact<{
  input: Types.CompleteUploadInput;
}>;


export type CompleteUploadMutation = { __typename?: 'RootMutationType', completeUpload?: { __typename?: 'InitiationItemResult', initiationItem?: { __typename?: 'InitiationItem', id: string, databaseId: any, title: string, type: Types.InitiationItemType, order: number, content?: { __typename?: 'Content', id: string, type: Types.ContentType, groupType: Types.ContentGroupType, body?: any | null, label?: string | null, plainDescription?: string | null, embedLink?: string | null, providerName?: string | null, videoContain?: boolean | null, video?: { __typename?: 'VideoUpload', id: string, name?: string | null, rawUrl?: string | null, alternativeText?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, thumbUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null, duration?: number | null, size?: number | null, insertedAt?: any | null, contact?: { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null } | null } | null, narration?: { __typename?: 'ContentNarration', id: string, title?: string | null, type?: Types.NarrationType | null, video?: { __typename?: 'VideoUpload', id: string, rawUrl?: string | null, transcodedUrl?: string | null, previewUrl?: string | null, streamUrl?: string | null, gifUrl?: string | null, gifWithIconUrl?: string | null } | null } | null } | null } | null } | null };


export const CompleteUploadDocument = gql`
    mutation completeUpload($input: CompleteUploadInput!) {
  completeUpload(input: $input) {
    initiationItem {
      id
      databaseId
      title
      type
      order
      content {
        id
        type
        groupType
        body
        label
        plainDescription
        embedLink
        providerName
        videoContain
        video {
          ...VideoUploadOnFeed
        }
        narration {
          id
          title
          type
          video {
            id
            rawUrl
            transcodedUrl
            previewUrl
            streamUrl
            gifUrl
            gifWithIconUrl
          }
        }
      }
    }
  }
}
    ${VideoUploadOnFeedFragmentDoc}`;
export type CompleteUploadMutationFn = Apollo.MutationFunction<CompleteUploadMutation, CompleteUploadMutationVariables>;

/**
 * __useCompleteUploadMutation__
 *
 * To run a mutation, you first call `useCompleteUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUploadMutation, { data, loading, error }] = useCompleteUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteUploadMutation(baseOptions?: Apollo.MutationHookOptions<CompleteUploadMutation, CompleteUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteUploadMutation, CompleteUploadMutationVariables>(CompleteUploadDocument, options);
      }
export type CompleteUploadMutationHookResult = ReturnType<typeof useCompleteUploadMutation>;
export type CompleteUploadMutationResult = Apollo.MutationResult<CompleteUploadMutation>;
export type CompleteUploadMutationOptions = Apollo.BaseMutationOptions<CompleteUploadMutation, CompleteUploadMutationVariables>;