import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
export type DestinationOnHotspotFragment = { __typename?: 'Hotspot', destinationType?: Types.NextInteractionType | null, value?: string | null, middleInteraction: { __typename?: 'MiddleInteraction', id: string, type: Types.MiddleInteractionType, question?: string | null, embed?: { __typename?: 'Embed', id: string, linkUrl: string, oembedResult?: any | null, status: Types.EmbedStatus, reason?: Types.EmbedFailureReason | null } | null }, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null };

export const DestinationOnHotspotFragmentDoc = gql`
    fragment DestinationOnHotspot on Hotspot {
  destinationType
  value
  middleInteraction {
    id
    type
    question
    embed {
      id
      linkUrl
      oembedResult
      status
      reason
    }
  }
  nextPage {
    id
    title
    type
    order
  }
}
    `;