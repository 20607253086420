import { createStore, StoreApi, useStore } from 'zustand';

type AuthTokenStoreStateBase = {
  accessToken: string | null;
  refreshToken: string | null;
};

type AuthTokenStoreActions = {
  setTokens: (tokens: { accessToken: string; refreshToken: string }) => void;
  clearTokens: () => void;
};

export type AuthTokenStoreState = AuthTokenStoreStateBase &
  AuthTokenStoreActions;

export const authTokenStore = createStore<AuthTokenStoreState>((set) => ({
  accessToken: null,
  refreshToken: null,
  setTokens({ accessToken, refreshToken }) {
    set({ accessToken, refreshToken });
  },
  clearTokens() {
    set({ accessToken: null, refreshToken: null });
  },
}));

const createBoundedUseStore = ((store) => (selector) =>
  useStore(store, selector)) as <S extends StoreApi<unknown>>(
  store: S,
) => {
  (): ExtractState<S>;
  <T>(selector: (state: ExtractState<S>) => T): T;
};

type ExtractState<S> = S extends { getState: () => infer X } ? X : never;

export const useAuthTokenStore = createBoundedUseStore(authTokenStore);
