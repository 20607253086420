interface Props {
  fill: string;
  className?: string;
}

export const UntitledUIZapIcon = ({ className, fill = '#4b5563' }: Props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <path d="M12.23 1.35L3.32 12.03c-.67.8-.75.91-.76 1.42 -.01.46.19.89.55 1.18 .39.32.53.33 1.58.33h7.29l-1-1.13 -1 8c-.13.99 1.12 1.53 1.76.76l8.9-10.69c.66-.81.74-.92.75-1.43 0-.47-.2-.9-.56-1.19 -.4-.33-.54-.34-1.59-.34h-7.3l.99 1.12 1-8c.12-1-1.13-1.54-1.77-.77Zm1.53 1.28l-1.77-.77 -1 8c-.08.59.39 1.12.99 1.12h7.29c.33 0 .45 0 .53 0 -.03-.01-.1-.03-.22-.13s-.19-.25-.19-.4c0-.16.03-.23.04-.25 -.05.07-.13.16-.34.42L10.18 21.3l1.76.76 1-8c.07-.6-.4-1.13-1-1.13h-7.3c-.34 0-.46-.01-.54-.01 .02 0 .09.02.21.12 .11.09.18.24.18.39 -.01.15-.04.22-.05.24 .04-.08.12-.17.33-.43l8.9-10.69Z" />
    </svg>
  );
};
