import { User } from '@sharelo-lib/graphql-types';
import { createContext } from 'react';

export const AuthContext = createContext<{
  isLoggedIn: boolean;
  currentUser: User | null;
  loaded: boolean;
  authLoading: boolean;
  currentRole: string | null;
  afterLoggedIn: (viewer?: User) => void;
  logout: (viewer?: User) => void;
  refetchCurrentUser: () => void;
}>({
  isLoggedIn: false,
  currentUser: null,
  currentRole: null,
  authLoading: false,
  loaded: false,
  afterLoggedIn: () => false,
  logout: () => false,
  refetchCurrentUser: () => false,
});
