import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateStoryLinkMutationVariables = Types.Exact<{
  input: Types.CreateStoryLinkInput;
}>;


export type CreateStoryLinkMutation = { __typename?: 'RootMutationType', createStoryLink?: { __typename?: 'StoryLinkMutationResult', storyLink?: { __typename?: 'StoryLink', id: string, sid: string, customTitle?: string | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateStoryLinkDocument = gql`
    mutation createStoryLink($input: CreateStoryLinkInput!) {
  createStoryLink(input: $input) {
    storyLink {
      id
      sid
      customTitle
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CreateStoryLinkMutationFn = Apollo.MutationFunction<CreateStoryLinkMutation, CreateStoryLinkMutationVariables>;

/**
 * __useCreateStoryLinkMutation__
 *
 * To run a mutation, you first call `useCreateStoryLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryLinkMutation, { data, loading, error }] = useCreateStoryLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStoryLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoryLinkMutation, CreateStoryLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoryLinkMutation, CreateStoryLinkMutationVariables>(CreateStoryLinkDocument, options);
      }
export type CreateStoryLinkMutationHookResult = ReturnType<typeof useCreateStoryLinkMutation>;
export type CreateStoryLinkMutationResult = Apollo.MutationResult<CreateStoryLinkMutation>;
export type CreateStoryLinkMutationOptions = Apollo.BaseMutationOptions<CreateStoryLinkMutation, CreateStoryLinkMutationVariables>;