import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MembersOnWorkspaceSettingsMembersPageQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  filter: Types.MemberFilter;
}>;


export type MembersOnWorkspaceSettingsMembersPageQuery = { __typename?: 'RootQueryType', members?: { __typename?: 'MemberConnection', totalCount: number, edges?: Array<{ __typename?: 'MemberEdge', node?: { __typename?: 'Member', id: string, email: string, displayName?: string | null, avatarUrl?: string | null, joinedAt?: any | null, role: string, inactivatedAt?: any | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type MembersOnStoryNotificationReceiverQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  filter: Types.MemberFilter;
}>;


export type MembersOnStoryNotificationReceiverQuery = { __typename?: 'RootQueryType', members?: { __typename?: 'MemberConnection', totalCount: number, edges?: Array<{ __typename?: 'MemberEdge', node?: { __typename?: 'Member', id: string, displayName?: string | null, avatarUrl?: string | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const MembersOnWorkspaceSettingsMembersPageDocument = gql`
    query membersOnWorkspaceSettingsMembersPage($first: Int!, $filter: MemberFilter!) {
  members(first: $first, filter: $filter) {
    edges {
      node {
        id
        email
        displayName
        avatarUrl
        joinedAt
        role
        inactivatedAt
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useMembersOnWorkspaceSettingsMembersPageQuery__
 *
 * To run a query within a React component, call `useMembersOnWorkspaceSettingsMembersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersOnWorkspaceSettingsMembersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersOnWorkspaceSettingsMembersPageQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembersOnWorkspaceSettingsMembersPageQuery(baseOptions: Apollo.QueryHookOptions<MembersOnWorkspaceSettingsMembersPageQuery, MembersOnWorkspaceSettingsMembersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersOnWorkspaceSettingsMembersPageQuery, MembersOnWorkspaceSettingsMembersPageQueryVariables>(MembersOnWorkspaceSettingsMembersPageDocument, options);
      }
export function useMembersOnWorkspaceSettingsMembersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersOnWorkspaceSettingsMembersPageQuery, MembersOnWorkspaceSettingsMembersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersOnWorkspaceSettingsMembersPageQuery, MembersOnWorkspaceSettingsMembersPageQueryVariables>(MembersOnWorkspaceSettingsMembersPageDocument, options);
        }
export type MembersOnWorkspaceSettingsMembersPageQueryHookResult = ReturnType<typeof useMembersOnWorkspaceSettingsMembersPageQuery>;
export type MembersOnWorkspaceSettingsMembersPageLazyQueryHookResult = ReturnType<typeof useMembersOnWorkspaceSettingsMembersPageLazyQuery>;
export type MembersOnWorkspaceSettingsMembersPageQueryResult = Apollo.QueryResult<MembersOnWorkspaceSettingsMembersPageQuery, MembersOnWorkspaceSettingsMembersPageQueryVariables>;
export function refetchMembersOnWorkspaceSettingsMembersPageQuery(variables: MembersOnWorkspaceSettingsMembersPageQueryVariables) {
      return { query: MembersOnWorkspaceSettingsMembersPageDocument, variables: variables }
    }
export const MembersOnStoryNotificationReceiverDocument = gql`
    query membersOnStoryNotificationReceiver($first: Int!, $filter: MemberFilter!) {
  members(first: $first, filter: $filter) {
    edges {
      node {
        id
        displayName
        avatarUrl
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useMembersOnStoryNotificationReceiverQuery__
 *
 * To run a query within a React component, call `useMembersOnStoryNotificationReceiverQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersOnStoryNotificationReceiverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersOnStoryNotificationReceiverQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembersOnStoryNotificationReceiverQuery(baseOptions: Apollo.QueryHookOptions<MembersOnStoryNotificationReceiverQuery, MembersOnStoryNotificationReceiverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersOnStoryNotificationReceiverQuery, MembersOnStoryNotificationReceiverQueryVariables>(MembersOnStoryNotificationReceiverDocument, options);
      }
export function useMembersOnStoryNotificationReceiverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersOnStoryNotificationReceiverQuery, MembersOnStoryNotificationReceiverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersOnStoryNotificationReceiverQuery, MembersOnStoryNotificationReceiverQueryVariables>(MembersOnStoryNotificationReceiverDocument, options);
        }
export type MembersOnStoryNotificationReceiverQueryHookResult = ReturnType<typeof useMembersOnStoryNotificationReceiverQuery>;
export type MembersOnStoryNotificationReceiverLazyQueryHookResult = ReturnType<typeof useMembersOnStoryNotificationReceiverLazyQuery>;
export type MembersOnStoryNotificationReceiverQueryResult = Apollo.QueryResult<MembersOnStoryNotificationReceiverQuery, MembersOnStoryNotificationReceiverQueryVariables>;
export function refetchMembersOnStoryNotificationReceiverQuery(variables: MembersOnStoryNotificationReceiverQueryVariables) {
      return { query: MembersOnStoryNotificationReceiverDocument, variables: variables }
    }