import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDefaultStoryNotificationSettingMutationVariables = Types.Exact<{
  input: Types.CreateStoryNotificationSettingInput;
}>;


export type CreateDefaultStoryNotificationSettingMutation = { __typename?: 'RootMutationType', createStoryNotificationSetting?: { __typename?: 'StoryNotificationSettingResult', storyNotificationSetting?: { __typename?: 'StoryNotificationSetting', id: string, isDefault: boolean, includeAnonymous: boolean, playEmail: boolean, collectLeadEmail: boolean, reachEndEmail: boolean, responseEmail: boolean } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };

export type CreateStoryNotificationSettingMutationVariables = Types.Exact<{
  input: Types.CreateStoryNotificationSettingInput;
}>;


export type CreateStoryNotificationSettingMutation = { __typename?: 'RootMutationType', createStoryNotificationSetting?: { __typename?: 'StoryNotificationSettingResult', storyNotificationSetting?: { __typename?: 'StoryNotificationSetting', id: string, isDefault: boolean, includeAnonymous: boolean, playEmail: boolean, collectLeadEmail: boolean, reachEndEmail: boolean, responseEmail: boolean } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateDefaultStoryNotificationSettingDocument = gql`
    mutation createDefaultStoryNotificationSetting($input: CreateStoryNotificationSettingInput!) {
  createStoryNotificationSetting(input: $input) {
    storyNotificationSetting {
      id
      isDefault
      includeAnonymous
      playEmail
      collectLeadEmail
      reachEndEmail
      responseEmail
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CreateDefaultStoryNotificationSettingMutationFn = Apollo.MutationFunction<CreateDefaultStoryNotificationSettingMutation, CreateDefaultStoryNotificationSettingMutationVariables>;

/**
 * __useCreateDefaultStoryNotificationSettingMutation__
 *
 * To run a mutation, you first call `useCreateDefaultStoryNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefaultStoryNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefaultStoryNotificationSettingMutation, { data, loading, error }] = useCreateDefaultStoryNotificationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDefaultStoryNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<CreateDefaultStoryNotificationSettingMutation, CreateDefaultStoryNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDefaultStoryNotificationSettingMutation, CreateDefaultStoryNotificationSettingMutationVariables>(CreateDefaultStoryNotificationSettingDocument, options);
      }
export type CreateDefaultStoryNotificationSettingMutationHookResult = ReturnType<typeof useCreateDefaultStoryNotificationSettingMutation>;
export type CreateDefaultStoryNotificationSettingMutationResult = Apollo.MutationResult<CreateDefaultStoryNotificationSettingMutation>;
export type CreateDefaultStoryNotificationSettingMutationOptions = Apollo.BaseMutationOptions<CreateDefaultStoryNotificationSettingMutation, CreateDefaultStoryNotificationSettingMutationVariables>;
export const CreateStoryNotificationSettingDocument = gql`
    mutation createStoryNotificationSetting($input: CreateStoryNotificationSettingInput!) {
  createStoryNotificationSetting(input: $input) {
    storyNotificationSetting {
      id
      isDefault
      includeAnonymous
      playEmail
      collectLeadEmail
      reachEndEmail
      responseEmail
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CreateStoryNotificationSettingMutationFn = Apollo.MutationFunction<CreateStoryNotificationSettingMutation, CreateStoryNotificationSettingMutationVariables>;

/**
 * __useCreateStoryNotificationSettingMutation__
 *
 * To run a mutation, you first call `useCreateStoryNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryNotificationSettingMutation, { data, loading, error }] = useCreateStoryNotificationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStoryNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoryNotificationSettingMutation, CreateStoryNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoryNotificationSettingMutation, CreateStoryNotificationSettingMutationVariables>(CreateStoryNotificationSettingDocument, options);
      }
export type CreateStoryNotificationSettingMutationHookResult = ReturnType<typeof useCreateStoryNotificationSettingMutation>;
export type CreateStoryNotificationSettingMutationResult = Apollo.MutationResult<CreateStoryNotificationSettingMutation>;
export type CreateStoryNotificationSettingMutationOptions = Apollo.BaseMutationOptions<CreateStoryNotificationSettingMutation, CreateStoryNotificationSettingMutationVariables>;