import { NextInteractionType, PageChoice } from '@sharelo-lib/graphql-types';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';

import { PageChoiceType } from '../types';

interface Props {
  choice: PageChoice;
  type: PageChoiceType;
  viewType: 'Desktop' | 'Mobile';
}

export const ChoiceItemMain = ({ choice, type, viewType }: Props) => {
  const { t } = useTranslation();
  const isRegular = type === 'regular';
  const baseWrapClass = classnames(
    'cursor-pointer shadow-xl bg-black bg-opacity-80 border border-gray-200 border-opacity-50 rounded-full text-white flex items-center z-60 hover:border-opacity-90 hover:bg-opacity-60 ease-in-out duration-300 min-h-12',
  );
  const baseIconClass =
    'flex items-center justify-center bg-indigo-600 rounded-full text-white font-bold text-xs';
  const baseTextClass = 'font-medium text-white tracking-wide';
  const wrapClass = classnames(
    baseWrapClass,
    { 'mx-2.5 my-1 px-3 py-0.5 w-64': isRegular && viewType === 'Desktop' },
    {
      'mx-2 my-1 px-3 py-0.5 lg:py-0.5 min-w-48 max-w-72':
        !isRegular && viewType === 'Desktop',
    },
    { 'mx-1.5 my-1 px-2 py-0.5 min-w-48 max-w-72': viewType === 'Mobile' },
  );
  const iconClass = classnames(
    baseIconClass,
    { 'w-5 h-5 p-1.5': isRegular },
    { 'w-4 h-4 lg:w-5 h-5 p-1': !isRegular || viewType === 'Mobile' },
  );
  const textClass = classnames(
    baseTextClass,
    { 'mx-2.5 text-sm leading-tight': isRegular },
    {
      'mx-2.5 text-xs lg:text-sm leading-tight':
        !isRegular || viewType === 'Mobile',
    },
  );
  let text = choice.text;
  if (
    choice.type === NextInteractionType.Page &&
    choice.nextPage &&
    (!choice.text || choice.text.length === 0)
  ) {
    text = t('buttons.continue');
  }

  const convertNumToAlphabet = (num: number) => {
    return (num + 9).toString(36).toUpperCase();
  };

  return (
    <div className={wrapClass}>
      <div className={iconClass}>{convertNumToAlphabet(choice.order)}</div>
      <div className={textClass}>{text}</div>
    </div>
  );
};
