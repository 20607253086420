import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiationsOnChannelPageQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first: Types.Scalars['Int']['input'];
  filter: Types.InitiationFilter;
}>;


export type InitiationsOnChannelPageQuery = { __typename?: 'RootQueryType', initiations?: { __typename?: 'InitiationConnection', totalCount: number, edges?: Array<{ __typename?: 'InitiationEdge', node?: { __typename?: 'Initiation', id: string, sid: string, title: string, thumbUrl?: string | null, updatedAt: any, setting: { __typename?: 'InitiationSetting', id: string, locale: Types.StoryLocale, isInteractive: boolean }, channel?: { __typename?: 'Channel', id: string, title: string } | null, owner?: { __typename?: 'User', id: string, displayName?: string | null, avatarUrl?: string | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type InitiationsOnSearchQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first: Types.Scalars['Int']['input'];
  filter: Types.InitiationFilter;
}>;


export type InitiationsOnSearchQuery = { __typename?: 'RootQueryType', initiations?: { __typename?: 'InitiationConnection', totalCount: number, edges?: Array<{ __typename?: 'InitiationEdge', node?: { __typename?: 'Initiation', id: string, sid: string, title: string, thumbUrl?: string | null, updatedAt: any, setting: { __typename?: 'InitiationSetting', id: string, locale: Types.StoryLocale, isInteractive: boolean }, channel?: { __typename?: 'Channel', id: string, title: string } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const InitiationsOnChannelPageDocument = gql`
    query initiationsOnChannelPage($after: String, $before: String, $first: Int!, $filter: InitiationFilter!) {
  initiations(after: $after, before: $before, first: $first, filter: $filter) {
    edges {
      node {
        id
        sid
        title
        thumbUrl
        setting {
          id
          locale
          isInteractive
        }
        channel {
          id
          title
        }
        owner {
          id
          displayName
          avatarUrl
        }
        updatedAt
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useInitiationsOnChannelPageQuery__
 *
 * To run a query within a React component, call `useInitiationsOnChannelPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiationsOnChannelPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiationsOnChannelPageQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInitiationsOnChannelPageQuery(baseOptions: Apollo.QueryHookOptions<InitiationsOnChannelPageQuery, InitiationsOnChannelPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitiationsOnChannelPageQuery, InitiationsOnChannelPageQueryVariables>(InitiationsOnChannelPageDocument, options);
      }
export function useInitiationsOnChannelPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitiationsOnChannelPageQuery, InitiationsOnChannelPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitiationsOnChannelPageQuery, InitiationsOnChannelPageQueryVariables>(InitiationsOnChannelPageDocument, options);
        }
export type InitiationsOnChannelPageQueryHookResult = ReturnType<typeof useInitiationsOnChannelPageQuery>;
export type InitiationsOnChannelPageLazyQueryHookResult = ReturnType<typeof useInitiationsOnChannelPageLazyQuery>;
export type InitiationsOnChannelPageQueryResult = Apollo.QueryResult<InitiationsOnChannelPageQuery, InitiationsOnChannelPageQueryVariables>;
export function refetchInitiationsOnChannelPageQuery(variables: InitiationsOnChannelPageQueryVariables) {
      return { query: InitiationsOnChannelPageDocument, variables: variables }
    }
export const InitiationsOnSearchDocument = gql`
    query initiationsOnSearch($after: String, $before: String, $first: Int!, $filter: InitiationFilter!) {
  initiations(after: $after, before: $before, first: $first, filter: $filter) {
    edges {
      node {
        id
        sid
        title
        thumbUrl
        setting {
          id
          locale
          isInteractive
        }
        channel {
          id
          title
        }
        updatedAt
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useInitiationsOnSearchQuery__
 *
 * To run a query within a React component, call `useInitiationsOnSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiationsOnSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiationsOnSearchQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInitiationsOnSearchQuery(baseOptions: Apollo.QueryHookOptions<InitiationsOnSearchQuery, InitiationsOnSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitiationsOnSearchQuery, InitiationsOnSearchQueryVariables>(InitiationsOnSearchDocument, options);
      }
export function useInitiationsOnSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitiationsOnSearchQuery, InitiationsOnSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitiationsOnSearchQuery, InitiationsOnSearchQueryVariables>(InitiationsOnSearchDocument, options);
        }
export type InitiationsOnSearchQueryHookResult = ReturnType<typeof useInitiationsOnSearchQuery>;
export type InitiationsOnSearchLazyQueryHookResult = ReturnType<typeof useInitiationsOnSearchLazyQuery>;
export type InitiationsOnSearchQueryResult = Apollo.QueryResult<InitiationsOnSearchQuery, InitiationsOnSearchQueryVariables>;
export function refetchInitiationsOnSearchQuery(variables: InitiationsOnSearchQueryVariables) {
      return { query: InitiationsOnSearchDocument, variables: variables }
    }