import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFileUploadMutationVariables = Types.Exact<{
  input: Types.CreateFileUploadInput;
}>;


export type CreateFileUploadMutation = { __typename?: 'RootMutationType', createFileUpload?: { __typename?: 'FileUploadResult', fileUpload?: { __typename?: 'FileUpload', id: string, name?: string | null, size?: number | null, pageCount?: number | null, fileUrl?: string | null, mimeType?: string | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateFileUploadDocument = gql`
    mutation createFileUpload($input: CreateFileUploadInput!) {
  createFileUpload(input: $input) {
    fileUpload {
      id
      name
      size
      pageCount
      fileUrl
      mimeType
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CreateFileUploadMutationFn = Apollo.MutationFunction<CreateFileUploadMutation, CreateFileUploadMutationVariables>;

/**
 * __useCreateFileUploadMutation__
 *
 * To run a mutation, you first call `useCreateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileUploadMutation, { data, loading, error }] = useCreateFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileUploadMutation, CreateFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileUploadMutation, CreateFileUploadMutationVariables>(CreateFileUploadDocument, options);
      }
export type CreateFileUploadMutationHookResult = ReturnType<typeof useCreateFileUploadMutation>;
export type CreateFileUploadMutationResult = Apollo.MutationResult<CreateFileUploadMutation>;
export type CreateFileUploadMutationOptions = Apollo.BaseMutationOptions<CreateFileUploadMutation, CreateFileUploadMutationVariables>;