import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { CompanyForSelectOnCompanyFeedFragmentDoc } from '../../companies/__generated__/companies-query.generated';
export type ContactFragmentOnContactFeedFragment = { __typename?: 'Contact', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, title?: string | null, avatarThumbUrl?: string | null, insertedAt?: any | null, company?: { __typename?: 'Company', id: string, name: string, iconUrl?: string | null, thumbUrl?: string | null } | null };

export const ContactFragmentOnContactFeedFragmentDoc = gql`
    fragment ContactFragmentOnContactFeed on Contact {
  id
  email
  firstName
  lastName
  title
  avatarThumbUrl
  insertedAt
  company {
    ...CompanyForSelectOnCompanyFeed
  }
}
    ${CompanyForSelectOnCompanyFeedFragmentDoc}`;