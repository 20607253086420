interface Props {
  className?: string;
}

export const UntitledUIDataflow02Icon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M11 4v11.2c0 2.3.04 2.84.43 3.61 .38.75.99 1.36 1.74 1.74 .76.39 1.31.43 3.61.43h.2c.55 0 1-.45 1-1 0-.56-.45-1-1-1h-.2c-1.9 0-2.36-.04-2.71-.22 -.38-.2-.69-.5-.88-.88 -.19-.36-.22-.81-.22-2.71V3.97c0-.56-.45-1-1-1 -.56 0-1 .44-1 1Zm5 16c0 1.65 1.34 3 3 3 1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3 -1.66 0-3 1.34-3 3Zm2 0c0-.56.44-1 1-1 .55 0 1 .44 1 1 0 .55-.45 1-1 1 -.56 0-1-.45-1-1ZM7 5h10c.55 0 1-.45 1-1 0-.56-.45-1-1-1H7c-.56 0-1 .44-1 1 0 .55.44 1 1 1ZM6 4c0 .55-.45 1-1 1 -.56 0-1-.45-1-1 0-.56.44-1 1-1 .55 0 1 .44 1 1Zm2 0c0-1.66-1.35-3-3-3C3.34 1 2 2.34 2 4c0 1.65 1.34 3 3 3 1.65 0 3-1.35 3-3Zm8 0c0 1.65 1.34 3 3 3 1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3 -1.66 0-3 1.34-3 3Zm2 0c0-.56.44-1 1-1 .55 0 1 .44 1 1 0 .55-.45 1-1 1 -.56 0-1-.45-1-1Zm-6 9h5c.55 0 1-.45 1-1 0-.56-.45-1-1-1h-5c-.56 0-1 .44-1 1 0 .55.44 1 1 1Zm4-1c0 1.65 1.34 3 3 3 1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3 -1.66 0-3 1.34-3 3Zm2 0c0-.56.44-1 1-1 .55 0 1 .44 1 1 0 .55-.45 1-1 1 -.56 0-1-.45-1-1Z"></path>
    </svg>
  );
};
