import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
export type StoryNotificationReceiverMemberFragment = { __typename?: 'Member', id: string, displayName?: string | null, avatarUrl?: string | null };

export type StoryNotificationReceiverOnStoryEditorFragment = { __typename?: 'StoryNotificationReceiver', id: string, member: { __typename?: 'Member', id: string, displayName?: string | null, avatarUrl?: string | null } };

export const StoryNotificationReceiverMemberFragmentDoc = gql`
    fragment StoryNotificationReceiverMember on Member {
  id
  displayName
  avatarUrl
}
    `;
export const StoryNotificationReceiverOnStoryEditorFragmentDoc = gql`
    fragment StoryNotificationReceiverOnStoryEditor on StoryNotificationReceiver {
  id
  member {
    ...StoryNotificationReceiverMember
  }
}
    ${StoryNotificationReceiverMemberFragmentDoc}`;