import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserOnAccountSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdateUserOnAccountSettingsMutation = { __typename?: 'RootMutationType', updateUser?: { __typename?: 'ViewerResult', viewer?: { __typename?: 'User', id: string, databaseId: any, displayName?: string | null, email?: string | null, emailVerified?: boolean | null, onboardingStatus: Types.OnboardingStatus, role: string, locale: Types.Locale, tenantCount: number, activeTenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null } | null, member?: { __typename?: 'Member', id: string, role: string, displayName?: string | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateUserOnAccountSettingsDocument = gql`
    mutation updateUserOnAccountSettings($input: UpdateUserInput!) {
  updateUser(input: $input) {
    viewer {
      id
      databaseId
      displayName
      email
      emailVerified
      onboardingStatus
      role
      locale
      tenantCount
      activeTenant {
        id
        databaseId
        commerceActivated
        storyCount
        name
      }
      member {
        id
        role
        displayName
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UpdateUserOnAccountSettingsMutationFn = Apollo.MutationFunction<UpdateUserOnAccountSettingsMutation, UpdateUserOnAccountSettingsMutationVariables>;

/**
 * __useUpdateUserOnAccountSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserOnAccountSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOnAccountSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOnAccountSettingsMutation, { data, loading, error }] = useUpdateUserOnAccountSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserOnAccountSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserOnAccountSettingsMutation, UpdateUserOnAccountSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserOnAccountSettingsMutation, UpdateUserOnAccountSettingsMutationVariables>(UpdateUserOnAccountSettingsDocument, options);
      }
export type UpdateUserOnAccountSettingsMutationHookResult = ReturnType<typeof useUpdateUserOnAccountSettingsMutation>;
export type UpdateUserOnAccountSettingsMutationResult = Apollo.MutationResult<UpdateUserOnAccountSettingsMutation>;
export type UpdateUserOnAccountSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserOnAccountSettingsMutation, UpdateUserOnAccountSettingsMutationVariables>;