import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { HotspotBaseFragmentDoc } from './hotspot-base-fragment.generated';
import { DestinationOnHotspotFragmentDoc } from './hotspot-destination-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateHotspotMutationVariables = Types.Exact<{
  hotspotInteractionId: Types.Scalars['ID']['input'];
  input: Types.CreateHotspotInput;
}>;


export type CreateHotspotMutation = { __typename?: 'RootMutationType', createHotspot?: { __typename?: 'HotspotMutationResult', hotspot?: { __typename?: 'Hotspot', id: string, x: number, y: number, text: string, textColor: string, bgColor: string, alwaysShowText: boolean, destinationType?: Types.NextInteractionType | null, value?: string | null, middleInteraction: { __typename?: 'MiddleInteraction', id: string, type: Types.MiddleInteractionType, question?: string | null, embed?: { __typename?: 'Embed', id: string, linkUrl: string, oembedResult?: any | null, status: Types.EmbedStatus, reason?: Types.EmbedFailureReason | null } | null }, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const CreateHotspotDocument = gql`
    mutation createHotspot($hotspotInteractionId: ID!, $input: CreateHotspotInput!) {
  createHotspot(hotspotInteractionId: $hotspotInteractionId, input: $input) {
    hotspot {
      ...HotspotBase
      ...DestinationOnHotspot
    }
    userErrors {
      field
      message
    }
  }
}
    ${HotspotBaseFragmentDoc}
${DestinationOnHotspotFragmentDoc}`;
export type CreateHotspotMutationFn = Apollo.MutationFunction<CreateHotspotMutation, CreateHotspotMutationVariables>;

/**
 * __useCreateHotspotMutation__
 *
 * To run a mutation, you first call `useCreateHotspotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHotspotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHotspotMutation, { data, loading, error }] = useCreateHotspotMutation({
 *   variables: {
 *      hotspotInteractionId: // value for 'hotspotInteractionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHotspotMutation(baseOptions?: Apollo.MutationHookOptions<CreateHotspotMutation, CreateHotspotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHotspotMutation, CreateHotspotMutationVariables>(CreateHotspotDocument, options);
      }
export type CreateHotspotMutationHookResult = ReturnType<typeof useCreateHotspotMutation>;
export type CreateHotspotMutationResult = Apollo.MutationResult<CreateHotspotMutation>;
export type CreateHotspotMutationOptions = Apollo.BaseMutationOptions<CreateHotspotMutation, CreateHotspotMutationVariables>;