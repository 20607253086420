import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiationInvitesQueryVariables = Types.Exact<{
  initiationId: Types.Scalars['ID']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type InitiationInvitesQuery = { __typename?: 'RootQueryType', initiationInvites?: { __typename?: 'InitiationInviteConnection', totalCount: number, edges?: Array<{ __typename?: 'InitiationInviteEdge', node?: { __typename?: 'InitiationInvite', id: string, invitedAt: any, role: Types.InitiationMemberRole, email?: string | null, member?: { __typename?: 'Member', id: string, displayName?: string | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const InitiationInvitesDocument = gql`
    query initiationInvites($initiationId: ID!, $first: Int) {
  initiationInvites(initiationId: $initiationId, first: $first) {
    edges {
      node {
        id
        invitedAt
        role
        email
        member {
          id
          displayName
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
  }
}
    `;

/**
 * __useInitiationInvitesQuery__
 *
 * To run a query within a React component, call `useInitiationInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiationInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiationInvitesQuery({
 *   variables: {
 *      initiationId: // value for 'initiationId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useInitiationInvitesQuery(baseOptions: Apollo.QueryHookOptions<InitiationInvitesQuery, InitiationInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitiationInvitesQuery, InitiationInvitesQueryVariables>(InitiationInvitesDocument, options);
      }
export function useInitiationInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitiationInvitesQuery, InitiationInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitiationInvitesQuery, InitiationInvitesQueryVariables>(InitiationInvitesDocument, options);
        }
export type InitiationInvitesQueryHookResult = ReturnType<typeof useInitiationInvitesQuery>;
export type InitiationInvitesLazyQueryHookResult = ReturnType<typeof useInitiationInvitesLazyQuery>;
export type InitiationInvitesQueryResult = Apollo.QueryResult<InitiationInvitesQuery, InitiationInvitesQueryVariables>;
export function refetchInitiationInvitesQuery(variables: InitiationInvitesQueryVariables) {
      return { query: InitiationInvitesDocument, variables: variables }
    }