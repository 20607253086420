import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SwitchTenantMutationVariables = Types.Exact<{
  tenantId: Types.Scalars['ID']['input'];
}>;


export type SwitchTenantMutation = { __typename?: 'RootMutationType', switchTenant?: { __typename?: 'ViewerResult', viewer?: { __typename?: 'User', id: string, databaseId: any, email?: string | null, emailVerified?: boolean | null, onboardingStatus: Types.OnboardingStatus, role: string, tenantCount: number, activeTenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, features: Array<Types.PlanFeature> } | null, member?: { __typename?: 'Member', id: string, role: string, displayName?: string | null } | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const SwitchTenantDocument = gql`
    mutation switchTenant($tenantId: ID!) {
  switchTenant(tenantId: $tenantId) {
    viewer {
      id
      databaseId
      email
      emailVerified
      onboardingStatus
      role
      tenantCount
      activeTenant {
        id
        databaseId
        commerceActivated
        storyCount
        name
        features
      }
      member {
        id
        role
        displayName
      }
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type SwitchTenantMutationFn = Apollo.MutationFunction<SwitchTenantMutation, SwitchTenantMutationVariables>;

/**
 * __useSwitchTenantMutation__
 *
 * To run a mutation, you first call `useSwitchTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchTenantMutation, { data, loading, error }] = useSwitchTenantMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useSwitchTenantMutation(baseOptions?: Apollo.MutationHookOptions<SwitchTenantMutation, SwitchTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchTenantMutation, SwitchTenantMutationVariables>(SwitchTenantDocument, options);
      }
export type SwitchTenantMutationHookResult = ReturnType<typeof useSwitchTenantMutation>;
export type SwitchTenantMutationResult = Apollo.MutationResult<SwitchTenantMutation>;
export type SwitchTenantMutationOptions = Apollo.BaseMutationOptions<SwitchTenantMutation, SwitchTenantMutationVariables>;