import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewerOnAuthQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ViewerOnAuthQuery = { __typename?: 'RootQueryType', viewer?: { __typename?: 'User', id: string, databaseId: any, locale: Types.Locale, avatarUrl?: string | null, displayName?: string | null, email?: string | null, emailVerified?: boolean | null, onboardingStatus: Types.OnboardingStatus, role: string, tenantCount: number, activeTenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, maxStorage?: number | null, exceededMaxStorage?: boolean | null, canUseTrial?: boolean | null, canUseCoupon?: boolean | null, storageUsage?: number | null, memberCount?: number | null, monthlyPlays?: number | null, monthlyImpressions?: number | null, maxSeats?: number | null, maxMonthlyPlays?: number | null, maxMonthlyImpressions?: number | null, maxLifetimePlays?: number | null, features: Array<Types.PlanFeature>, plan?: { __typename?: 'Plan', id?: string | null, isTrial?: boolean | null, isActive?: boolean | null, slug?: string | null, name?: string | null, nameI18nKey?: string | null } | null } | null, member?: { __typename?: 'Member', id: string, role: string, avatarUrl?: string | null, displayName?: string | null } | null } | null };


export const ViewerOnAuthDocument = gql`
    query viewerOnAuth {
  viewer {
    id
    databaseId
    locale
    avatarUrl
    displayName
    email
    emailVerified
    onboardingStatus
    role
    tenantCount
    activeTenant {
      id
      databaseId
      commerceActivated
      storyCount
      name
      maxStorage
      exceededMaxStorage
      canUseTrial
      canUseCoupon
      storageUsage
      memberCount
      monthlyPlays
      monthlyImpressions
      maxSeats
      maxStorage
      maxMonthlyPlays
      maxMonthlyImpressions
      maxLifetimePlays
      plan {
        id
        isTrial
        isActive
        slug
        name
        nameI18nKey
      }
      features
    }
    member {
      id
      role
      avatarUrl
      displayName
    }
  }
}
    `;

/**
 * __useViewerOnAuthQuery__
 *
 * To run a query within a React component, call `useViewerOnAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerOnAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerOnAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerOnAuthQuery(baseOptions?: Apollo.QueryHookOptions<ViewerOnAuthQuery, ViewerOnAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerOnAuthQuery, ViewerOnAuthQueryVariables>(ViewerOnAuthDocument, options);
      }
export function useViewerOnAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerOnAuthQuery, ViewerOnAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerOnAuthQuery, ViewerOnAuthQueryVariables>(ViewerOnAuthDocument, options);
        }
export type ViewerOnAuthQueryHookResult = ReturnType<typeof useViewerOnAuthQuery>;
export type ViewerOnAuthLazyQueryHookResult = ReturnType<typeof useViewerOnAuthLazyQuery>;
export type ViewerOnAuthQueryResult = Apollo.QueryResult<ViewerOnAuthQuery, ViewerOnAuthQueryVariables>;
export function refetchViewerOnAuthQuery(variables?: ViewerOnAuthQueryVariables) {
      return { query: ViewerOnAuthDocument, variables: variables }
    }