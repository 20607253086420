import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteNarrationChapterMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteNarrationChapterMutation = { __typename?: 'RootMutationType', deleteNarrationChapter?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const DeleteNarrationChapterDocument = gql`
    mutation deleteNarrationChapter($id: ID!) {
  deleteNarrationChapter(id: $id) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteNarrationChapterMutationFn = Apollo.MutationFunction<DeleteNarrationChapterMutation, DeleteNarrationChapterMutationVariables>;

/**
 * __useDeleteNarrationChapterMutation__
 *
 * To run a mutation, you first call `useDeleteNarrationChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNarrationChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNarrationChapterMutation, { data, loading, error }] = useDeleteNarrationChapterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNarrationChapterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNarrationChapterMutation, DeleteNarrationChapterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNarrationChapterMutation, DeleteNarrationChapterMutationVariables>(DeleteNarrationChapterDocument, options);
      }
export type DeleteNarrationChapterMutationHookResult = ReturnType<typeof useDeleteNarrationChapterMutation>;
export type DeleteNarrationChapterMutationResult = Apollo.MutationResult<DeleteNarrationChapterMutation>;
export type DeleteNarrationChapterMutationOptions = Apollo.BaseMutationOptions<DeleteNarrationChapterMutation, DeleteNarrationChapterMutationVariables>;