import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
export type CompanyFragmentOnCompanyFeedFragment = { __typename?: 'Company', id: string, name: string, url?: string | null, iconUrl?: string | null, thumbUrl?: string | null, insertedAt?: any | null };

export const CompanyFragmentOnCompanyFeedFragmentDoc = gql`
    fragment CompanyFragmentOnCompanyFeed on Company {
  id
  name
  url
  iconUrl
  thumbUrl
  insertedAt
}
    `;