import { makeVar } from '@apollo/client';

import { StoryPlayerState } from '../models/story-player';

const storyPlayerInitialState: StoryPlayerState = {
  history: [],
  showMenu: false,
  showQuestion: false,
  showVideoPlayerControl: false,
};

export const storyPlayerState = makeVar<StoryPlayerState>(
  storyPlayerInitialState,
);
