import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewerOnAccountSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ViewerOnAccountSettingsQuery = { __typename?: 'RootQueryType', viewer?: { __typename?: 'User', id: string, databaseId: any, avatarUrl?: string | null, displayName?: string | null, email?: string | null, emailVerified?: boolean | null, onboardingStatus: Types.OnboardingStatus, role: string, locale: Types.Locale, tenantCount: number, activeTenant?: { __typename?: 'Tenant', id: string, databaseId: any, commerceActivated: boolean, storyCount?: number | null, name?: string | null, features: Array<Types.PlanFeature> } | null, member?: { __typename?: 'Member', id: string, avatarUrl?: string | null, role: string, displayName?: string | null } | null } | null };


export const ViewerOnAccountSettingsDocument = gql`
    query viewerOnAccountSettings {
  viewer {
    id
    databaseId
    avatarUrl
    displayName
    email
    emailVerified
    onboardingStatus
    role
    locale
    tenantCount
    activeTenant {
      id
      databaseId
      commerceActivated
      storyCount
      name
      features
    }
    member {
      id
      avatarUrl
      role
      displayName
    }
  }
}
    `;

/**
 * __useViewerOnAccountSettingsQuery__
 *
 * To run a query within a React component, call `useViewerOnAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerOnAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerOnAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerOnAccountSettingsQuery(baseOptions?: Apollo.QueryHookOptions<ViewerOnAccountSettingsQuery, ViewerOnAccountSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerOnAccountSettingsQuery, ViewerOnAccountSettingsQueryVariables>(ViewerOnAccountSettingsDocument, options);
      }
export function useViewerOnAccountSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerOnAccountSettingsQuery, ViewerOnAccountSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerOnAccountSettingsQuery, ViewerOnAccountSettingsQueryVariables>(ViewerOnAccountSettingsDocument, options);
        }
export type ViewerOnAccountSettingsQueryHookResult = ReturnType<typeof useViewerOnAccountSettingsQuery>;
export type ViewerOnAccountSettingsLazyQueryHookResult = ReturnType<typeof useViewerOnAccountSettingsLazyQuery>;
export type ViewerOnAccountSettingsQueryResult = Apollo.QueryResult<ViewerOnAccountSettingsQuery, ViewerOnAccountSettingsQueryVariables>;
export function refetchViewerOnAccountSettingsQuery(variables?: ViewerOnAccountSettingsQueryVariables) {
      return { query: ViewerOnAccountSettingsDocument, variables: variables }
    }