import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteInitiationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteInitiationMutation = { __typename?: 'RootMutationType', deleteInitiation?: { __typename?: 'ActionResult', userErrors?: Array<{ __typename?: 'UserError', field: string, message: string }> | null } | null };


export const DeleteInitiationDocument = gql`
    mutation deleteInitiation($id: ID!) {
  deleteInitiation(id: $id) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type DeleteInitiationMutationFn = Apollo.MutationFunction<DeleteInitiationMutation, DeleteInitiationMutationVariables>;

/**
 * __useDeleteInitiationMutation__
 *
 * To run a mutation, you first call `useDeleteInitiationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInitiationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInitiationMutation, { data, loading, error }] = useDeleteInitiationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInitiationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInitiationMutation, DeleteInitiationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInitiationMutation, DeleteInitiationMutationVariables>(DeleteInitiationDocument, options);
      }
export type DeleteInitiationMutationHookResult = ReturnType<typeof useDeleteInitiationMutation>;
export type DeleteInitiationMutationResult = Apollo.MutationResult<DeleteInitiationMutation>;
export type DeleteInitiationMutationOptions = Apollo.BaseMutationOptions<DeleteInitiationMutation, DeleteInitiationMutationVariables>;