import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import { CustomLogicFieldsFragmentDoc } from './custom-logic-fields-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateChoiceLogicMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateChoiceLogicInput;
}>;


export type UpdateChoiceLogicMutation = { __typename?: 'RootMutationType', updateChoiceLogic?: { __typename?: 'ChoiceLogicMutationResult', choiceLogic?: { __typename?: 'ChoiceLogic', id: string, operator: Types.LogicOperatorType, conditionals?: Array<{ __typename?: 'ChoiceConditional', id: string, comparison: Types.ChoiceConditionalComparisonType, question?: { __typename?: 'PageQuestion', id: string, title?: string | null, titleColor: string, storyPage?: { __typename?: 'InitiationItem', id: string, title: string, order: number } | null } | null, choice?: { __typename?: 'PageChoice', id: string, order: number, text?: string | null } | null } | null> | null, actions?: Array<{ __typename?: 'ChoiceAction', id: string, type?: Types.ChoiceActionType | null, result?: Types.ChoiceActionResultType | null, value?: number | null, strValue?: string | null, nextPage?: { __typename?: 'InitiationItem', id: string, title: string, type: Types.InitiationItemType, order: number } | null } | null> | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UpdateChoiceLogicDocument = gql`
    mutation updateChoiceLogic($id: ID!, $input: UpdateChoiceLogicInput!) {
  updateChoiceLogic(id: $id, input: $input) {
    choiceLogic {
      ...CustomLogicFields
    }
    userErrors {
      field
      message
    }
  }
}
    ${CustomLogicFieldsFragmentDoc}`;
export type UpdateChoiceLogicMutationFn = Apollo.MutationFunction<UpdateChoiceLogicMutation, UpdateChoiceLogicMutationVariables>;

/**
 * __useUpdateChoiceLogicMutation__
 *
 * To run a mutation, you first call `useUpdateChoiceLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChoiceLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChoiceLogicMutation, { data, loading, error }] = useUpdateChoiceLogicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChoiceLogicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChoiceLogicMutation, UpdateChoiceLogicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChoiceLogicMutation, UpdateChoiceLogicMutationVariables>(UpdateChoiceLogicDocument, options);
      }
export type UpdateChoiceLogicMutationHookResult = ReturnType<typeof useUpdateChoiceLogicMutation>;
export type UpdateChoiceLogicMutationResult = Apollo.MutationResult<UpdateChoiceLogicMutation>;
export type UpdateChoiceLogicMutationOptions = Apollo.BaseMutationOptions<UpdateChoiceLogicMutation, UpdateChoiceLogicMutationVariables>;