import * as Types from '../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadBrandSymbolMutationVariables = Types.Exact<{
  input: Types.UploadBrandSymbolInput;
}>;


export type UploadBrandSymbolMutation = { __typename?: 'RootMutationType', uploadBrandSymbol?: { __typename?: 'BrandSettingResult', brandSetting?: { __typename?: 'BrandSetting', id: string, primaryColor?: string | null, logoUrl?: string | null, logoForDarkBgUrl?: string | null, symbolUrl?: string | null, symbolForDarkBgUrl?: string | null } | null, userErrors?: Array<{ __typename?: 'UserError', field: string, message: string } | null> | null } | null };


export const UploadBrandSymbolDocument = gql`
    mutation uploadBrandSymbol($input: UploadBrandSymbolInput!) {
  uploadBrandSymbol(input: $input) {
    brandSetting {
      id
      primaryColor
      logoUrl
      logoForDarkBgUrl
      symbolUrl
      symbolForDarkBgUrl
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type UploadBrandSymbolMutationFn = Apollo.MutationFunction<UploadBrandSymbolMutation, UploadBrandSymbolMutationVariables>;

/**
 * __useUploadBrandSymbolMutation__
 *
 * To run a mutation, you first call `useUploadBrandSymbolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBrandSymbolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBrandSymbolMutation, { data, loading, error }] = useUploadBrandSymbolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBrandSymbolMutation(baseOptions?: Apollo.MutationHookOptions<UploadBrandSymbolMutation, UploadBrandSymbolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBrandSymbolMutation, UploadBrandSymbolMutationVariables>(UploadBrandSymbolDocument, options);
      }
export type UploadBrandSymbolMutationHookResult = ReturnType<typeof useUploadBrandSymbolMutation>;
export type UploadBrandSymbolMutationResult = Apollo.MutationResult<UploadBrandSymbolMutation>;
export type UploadBrandSymbolMutationOptions = Apollo.BaseMutationOptions<UploadBrandSymbolMutation, UploadBrandSymbolMutationVariables>;