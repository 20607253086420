import * as Types from '../../../__generated__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryLinkQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type StoryLinkQuery = { __typename?: 'RootQueryType', storyLink?: { __typename?: 'StoryLink', id: string, sid: string, isLive: boolean, customTitle?: string | null, expiresAt?: any | null } | null };


export const StoryLinkDocument = gql`
    query storyLink($id: ID!) {
  storyLink(id: $id) {
    id
    sid
    isLive
    customTitle
    expiresAt
  }
}
    `;

/**
 * __useStoryLinkQuery__
 *
 * To run a query within a React component, call `useStoryLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoryLinkQuery(baseOptions: Apollo.QueryHookOptions<StoryLinkQuery, StoryLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryLinkQuery, StoryLinkQueryVariables>(StoryLinkDocument, options);
      }
export function useStoryLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryLinkQuery, StoryLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryLinkQuery, StoryLinkQueryVariables>(StoryLinkDocument, options);
        }
export type StoryLinkQueryHookResult = ReturnType<typeof useStoryLinkQuery>;
export type StoryLinkLazyQueryHookResult = ReturnType<typeof useStoryLinkLazyQuery>;
export type StoryLinkQueryResult = Apollo.QueryResult<StoryLinkQuery, StoryLinkQueryVariables>;
export function refetchStoryLinkQuery(variables: StoryLinkQueryVariables) {
      return { query: StoryLinkDocument, variables: variables }
    }